import {Injectable, ChangeDetectorRef} from '@angular/core';
import {RawMaterialProductsService} from './raw-material-products.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {RawMaterialProductInterface} from '../../interfaces/lot/raw-material-product.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {RawMaterialProductModel} from '../../models/raw-material-product.model';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class RawMaterialProductFormService {
  rawMaterialProduct: RawMaterialProductModel = new RawMaterialProductModel({});
  selectedId: string;

  constructor(
    private rawMaterialProductsService: RawMaterialProductsService,
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    public clipboardService: ClipboardService,
    private http: HttpClient,
    private translate: TranslateService
  ) {


    $(document).on('click', '.edit-raw-material-product-btn', (e) => {
      const id = $(e.currentTarget).attr('raw-material-product-id');
      console.log(id, 'id');
      this.loadRawMaterialProduct(id);
    });

    $(document).on('click', '.del-raw-material-product-btn', (e) => {
      const id = $(e.currentTarget).attr('raw-material-product-id');
      this.delRawMaterialProduct(id);
    });
  }

  saveRawMaterialProduct(close: boolean = true) {
    console.log(this.rawMaterialProduct, 'this.rawMaterialProduct')
    this.rawMaterialProductsService.saveRawMaterialProduct(this.rawMaterialProduct, this.selectedId);
    if (close) {
      $('#new-raw-material-product-modal').modal('hide');
    }
  }

  loadRawMaterialProduct(id) {
    // this.product = new RawMaterialProductModel();
    console.log(id, 'id')
    this.rawMaterialProductsService.getRawMaterialProduct(id)
      .pipe(
        first()
      )
      .subscribe((rawMaterialProduct: RawMaterialProductInterface) => {

        console.log(rawMaterialProduct, 'product1');
        this.selectedId = rawMaterialProduct.id;
        this.rawMaterialProduct = new RawMaterialProductModel(rawMaterialProduct);

      });

  }

  delRawMaterialProduct(rawMaterialProductId) {
    console.log(rawMaterialProductId, 'delRawMaterialProduct')
    if (rawMaterialProductId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'generic.deleteAlert.title',
        'generic.deleteAlert.text',
        'generic.deletedAlert.title',
        'generic.deletedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['generic.deleteAlert.title'],
          text: trans['generic.deleteAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.db.object(`rawMaterialProducts/${rawMaterialProductId}`).remove()
              .then(() => {
                Swal.fire(
                  trans['generic.deletedAlert.title'],
                  trans['generic.deletedAlert.text'],
                  'success'
                );
              });
          }
        });
      });
    }
  }

  newRawMaterialProduct() {
    this.rawMaterialProduct = new RawMaterialProductModel({});
    this.selectedId = null;
  }

}
