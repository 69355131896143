import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {ChartsService} from '../../../../core/services/charts.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-documents-chart',
    templateUrl: './documents-chart.component.html',
    styleUrls: ['./documents-chart.component.css']
})
export class DocumentsChartComponent implements OnInit {
    public barChartOptions: ChartOptions;
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;

    public barChartData: ChartDataSets[];

    constructor(
        public chartsService: ChartsService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.loadChart();
        this.chartsService.interval$.subscribe(() => {
            this.loadChart();
        });
    }

    loadChart() {
        this.translate.get('documents.documents').subscribe((documents: any) => {
            this.barChartData = [
                {data: [], label: documents}
            ];
        });
        this.chartsService.documentQty.subscribe((documentQty) => {
            const data = [];
            const labels = [];

            for (const quantity of documentQty) {
                labels.push(quantity.date);
                data.push(quantity.value);
            }

            this.barChartOptions = {
                responsive: true,
                scales: {
                    xAxes: [{}], yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            };

            this.barChartData[0].data = data;

            this.barChartLabels = labels;
        });
    }

}
