import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {DatatableLangService} from '../datatable-lang.service';
// import {rawMaterialProductInterface} from '../../interfaces/raw-material-product/raw-material-product';
import {IdentityService} from '../identity.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class RawMaterialProductsService {
  dataTable: any;
  public rawMaterialProducts: any = new Observable();
  public rawMaterialProduct: any;


  constructor(
    private db: AngularFireDatabase,
    private datatableLang: DatatableLangService,
    private identityService: IdentityService
  ) {

      this.rawMaterialProducts = this.db.list('rawMaterialProducts')
        .snapshotChanges()
        .pipe(
          map(rawMaterialProducts => {
            return rawMaterialProducts.reduce((total, current, i) => {
              total[i] = current.payload.val();
              total[i].id = current.key;
              return total;
            }, []);
          })
        );
  }

  renderTable() {
    this.identityService.identity.subscribe((identity: any) => {


      this.rawMaterialProducts.subscribe(rawMaterialProducts => {
        const table: any = $('#raw-material-products-table');
        this.dataTable = table.DataTable({
          destroy: true,
          scrollX: true,
          language: this.datatableLang.lang,
          lengthMenu: [5, 10, 25, 50],
          pageLength: 10,
          order: [[2, 'asc']],
          data: rawMaterialProducts,
          columns: [
            {data: 'id', width: '50px'},
            {data: 'name', defaultContent: '', type: 'string'},
            {data: 'description', defaultContent: ''},
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'id',
              title: 'Actions',
              orderable: false,
              render: (id) => {
                // language=HTML
                return `
                            <button raw-material-product-id="${id}" type="button" class="edit-raw-material-product-btn btn btn-primary"
                                data-toggle="modal" data-target="#new-raw-material-product-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            ${identity.type === 'ADMIN' ? `<button raw-material-product-id="${id}" type="button" class="del-raw-material-product-btn btn btn-danger">
                                <i class="fas fa-times"></i>
                            </button>` : ''}
                            `;
              },
            }
          ],
          drawCallback: () => {
            $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
          }
        });
      });


    });

  }

  getRawMaterialProduct(id) {
    return this.rawMaterialProduct = this.db.list('rawMaterialProducts/' + id).snapshotChanges()
      .pipe(map(items => {
          return items.reduce((total, current) => {
            const value = current.payload.val();
            const key = current.payload.key;
            console.log(value, 'value')
            console.log(key, 'key')
            total[key] = value;
            total['id'] = id;
            return total;
          }, {});
        })
      );
  }

  async saveRawMaterialProduct(rawMaterialProduct, oldId = null) {
    if (oldId) {
      this.db.object('rawMaterialProducts/' + rawMaterialProduct.id).update(JSON.parse(JSON.stringify(rawMaterialProduct)));
    } else {
      this.db.list('rawMaterialProducts').push(JSON.parse(JSON.stringify(rawMaterialProduct)));
    }
  }


}
