import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OptionInterface} from '../../../core/interfaces/lot/product.interface';
import {FieldModel} from '../../../core/models/field.model';

@Component({
  selector: 'app-field-modal',
  templateUrl: './field-modal.component.html',
  styleUrls: ['./field-modal.component.css']
})
export class FieldModalComponent implements OnInit {
  field: FieldModel = new FieldModel({});
  newOption: OptionInterface = {
    option: null,
    value: null
  };

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  addNewOption() {
    this.field.options.push(this.newOption);
    this.newOption = {
      option: null,
      value: null
    };
  }

  delOption(optionIndex: number) {
    this.field.options.splice(optionIndex, 1);
  }

  save() {
    this.activeModal.close(this.field);
  }

}
