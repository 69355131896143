import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {first, map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {DatatableLangService} from '../datatable-lang.service';
// import {lotInterface} from '../../interfaces/lot/lot';
import {IdentityService} from '../identity.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class RawMaterialsService {
  dataTable: any;
  public rawMaterials: any = new Observable();
  public rawMaterial: any;


  constructor(
    private db: AngularFireDatabase,
    private datatableLang: DatatableLangService,
    private identityService: IdentityService
  ) {

    this.rawMaterials = this.db.list('rawMaterials')
      .snapshotChanges()
      .pipe(
        map(rawMaterials => {
          return rawMaterials.reduce((total, current, i) => {
            total[i] = current.payload.val();
            total[i].id = current.key;
            return total;
          }, []);
        })
      );
  }

  renderTable() {
    this.identityService.identity.subscribe((identity: any) => {

      this.rawMaterials.subscribe(rawMaterials => {
        const table: any = $('#raw-materials-table');
        this.dataTable = table.DataTable({
          destroy: true,
          scrollX: true,
          language: this.datatableLang.lang,
          lengthMenu: [5, 10, 25, 50],
          pageLength: 10,
          order: [[2, 'asc']],
          data: rawMaterials,
          columns: [
            {data: 'id', width: '50px'},
            {data: 'id', defaultContent: '', type: 'string'},
            {data: 'date', defaultContent: ''},
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'id',
              title: 'Actions',
              orderable: false,
              render: (id) => {
                // language=HTML
                return `
                            <button raw-material-id="${id}" type="button" class="edit-raw-material-btn btn btn-primary"
                                data-toggle="modal" data-target="#new-raw-material-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            ${identity.type === 'ADMIN' ? `<button raw-material-id="${id}" type="button" class="del-raw-material-btn btn btn-danger">
                                <i class="fas fa-times"></i>
                            </button>` : ''}

                            `;
              },
            },
            {
              targets: 2,
              data: 'date',
              render: (datetime) => {
                // language=HTML
                return datetime ? `
                                <i class="far fa-calendar-alt"></i> ${moment(datetime, 'x').format('DD/MM/YYYY')}
                                <i class="far fa-clock"></i> ${moment(datetime, 'x').format('HH:mm')}
                            ` : '';
              },
            }
          ],
          drawCallback: () => {
            $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
          }
        });
      });


    });

  }

  getRawMaterial(id) {
    return this.rawMaterial = this.db.list('rawMaterials/' + id).snapshotChanges()
      .pipe(map(items => {
          return items.reduce((total, current) => {
            const value = current.payload.val();
            const key = current.payload.key;
            total[key] = value;
            return total;
          }, {});
        })
      );
  }

  async saveRawMaterial(rawMaterial) {
    if (!rawMaterial.id) {
      rawMaterial.id = await this.getNewRawMaterialId();
    }
    this.db.object('rawMaterials/' + rawMaterial.id).update(JSON.parse(JSON.stringify(rawMaterial)));
  }

  getNewRawMaterialId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db.object('counters/rawMaterials').valueChanges().pipe(first()).subscribe((rawMaterialCounter: string) => {
        const newRawMaterialCounter = parseInt(rawMaterialCounter || '0', 10) + 1;
        this.db.object('counters/rawMaterials').set(newRawMaterialCounter);
        resolve(newRawMaterialCounter.toString());
      }, error => {
        reject(error)
      })
    })

  }


}
