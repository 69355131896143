import { Component, OnInit } from '@angular/core';
import {FarmInterface} from '../../../../core/interfaces/identity/farm.interface';
import {IdentityService} from '../../../../core/services/identity.service';
import {FarmModel} from '../../../../core/models/identity/farm.model';

@Component({
  selector: 'app-farm-form',
  templateUrl: './farm-form.component.html',
  styleUrls: ['./farm-form.component.css']
})
export class FarmFormComponent implements OnInit {
  farm: FarmInterface = new FarmModel();

  constructor(
      private identityService: IdentityService
  ) {
  }

  ngOnInit() {
    this.identityService.identity.subscribe((farm: FarmInterface) => {
      this.farm = new FarmModel(farm);
    });
  }

  saveIdentity() {
    this.identityService.saveIdentity(this.farm);
  }

  delProvider() {
    this.identityService.delProvider(this.farm.provider.walletAddress);
  }

}
