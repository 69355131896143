import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {AngularFireDatabase} from '@angular/fire/database';
import {ProviderPendingFarmInterface} from '../../interfaces/provider/provider-pending-farm';
import {ProviderPendingFarmModel} from '../../models/provider/provider-pending-farm.model';
import {first, map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import * as moment from '../documents/documents.service';
import {TranslateService} from '@ngx-translate/core';
import {DatatableLangService} from '../datatable-lang.service';
import {IdentityService} from '../identity.service';
import {Observable} from 'rxjs';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ProviderPendingFarmsService {
    dataTable: any;
    public pendingFarms: any;
    public pendingFarmList: any = new Observable();


    constructor(
        private db: AngularFireDatabase,
        private datatableLang: DatatableLangService,
        private translate: TranslateService,
        private identityService: IdentityService
    ) {
        // this.identityService.identity.subscribe((identity) => {
        //     console.log('identity');
        //     this.pendingFarmList = db.list('providerPendingFarms/' + identity.walletAddress)
        //         .snapshotChanges()
        //         .pipe(
        //             map(pendingTransaction => {
        //                 return pendingTransaction.reduce((total, current, i) => {
        //                     total[i] = current.payload.val();
        //                     total[i].key = current.key;
        //                     return total;
        //                 }, []);
        //             })
        //         );
        // });
    }

    renderTable() {
        this.identityService.identity.subscribe((identity) => {
            this.db.list('providerPendingFarms/' + identity.walletAddress)
                .snapshotChanges()
                .pipe(
                    map(pendingTransaction => {
                        return pendingTransaction.reduce((total, current, i) => {
                            total[i] = current.payload.val();
                            total[i].key = current.key;
                            return total;
                        }, []);
                    })
                ).subscribe((pendingFarms: any[]) => {
                console.log('pendingtable');
                const table: any = $('#pending-farms-table');
                this.dataTable = table.DataTable({
                    destroy: true,
                    scrollX: true,
                    language: this.datatableLang.lang,
                    lengthMenu: [5, 10, 25, 50],
                    pageLength: 10,
                    order: [[1, 'desc']],
                    data: pendingFarms,
                    columns: [
                        {data: 'key', width: '50px'},
                        {data: 'id', defaultContent: ''},
                        {data: 'fiscalName', defaultContent: ''}
                    ],
                    columnDefs: [
                        {
                            targets: 0,
                            data: 'key',
                            title: 'Actions',
                            orderable: false,
                            render: (key) => {
                                // language=HTML
                                return `
                            <button pending-farm-id="${key}" type="button" class="del-pending-farm-btn btn btn-danger px-3 mr-2">
                                <i class="fas fa-times"></i>
                            </button>
                            `;
                            },
                        }
                    ],
                    drawCallback: () => {
                        $('[data-toggle="tooltip"]').tooltip();
                    }
                });
            });
        });

        $(document).on('click', '.del-pending-farm-btn', (e) => {
            const key = $(e.currentTarget).attr('pending-farm-id');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteProviderPendingFarm.warning.title',
                'identity.deleteProviderPendingFarm.warning.text',
                'identity.deleteProviderPendingFarm.deleted.title',
                'identity.deleteProviderPendingFarm.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteProviderPendingFarm.warning.title'],
                    text: trans['identity.deleteProviderPendingFarm.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.identityService.identity.subscribe((identity) => {
                            this.db.object(`providerPendingFarms/${identity.walletAddress}/${key}`).remove()
                                .then(() => {
                                    Swal.fire(
                                        trans['identity.deleteProviderPendingFarm.deleted.title'],
                                        trans['identity.deleteProviderPendingFarm.deleted.text'],
                                        'success'
                                    );
                                });
                        });
                    }
                });
            });
        });

    }

    savePendingFarm(farmWalletAddress) {
        if (farmWalletAddress) {
            this.db.list('identity/' + farmWalletAddress).snapshotChanges()
                .pipe(map(items => {
                        return items.reduce((total, current) => {
                            total[current.payload.key] = current.payload.val();
                            return total;
                        }, {});
                    }),
                    first()
                ).subscribe((farm: any) => {
                this.identityService.identity.subscribe((identity) => {
                    this.db.object(`providerPendingFarms/${identity.walletAddress}/${farm.walletAddress}`).set(JSON.parse(JSON.stringify(farm)));
                    this.db.object(`farmPendingProviders/${farm.walletAddress}/${identity.walletAddress}`).set(JSON.parse(JSON.stringify(identity)));
                });
            });
        }

    }
}
