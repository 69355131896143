import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit, Output,
} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import Swal from 'sweetalert2';
import {FileInterface} from 'src/app/core/interfaces/file.interface';
import {FileModel} from '../../../core/models/file.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileManagerModalComponent} from './file-manager-modal/file-manager-modal.component';

declare var $: any;

@Component({
  selector: 'app-file-uploader-input',
  templateUrl: './file-uploader-input.component.html',
  styleUrls: ['./file-uploader-input.component.css']
})
export class FileUploaderInputComponent implements OnInit, OnChanges {
  @Input() img: FileInterface;
  @Input() type: null | 'img' | 'pdf';
  @Output() imgChange: EventEmitter<FileInterface> = new EventEmitter();
  @Input() folder: string;
  @Input() enableSelect: boolean;
  uploadPercent = null;

  validTypes = {
    img: [
      'image/gif',
      'image/jpeg',
      'image/png'
    ]
  };


  constructor(
    private storage: AngularFireStorage,
    public modalService: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.imgChange.emit(this.img);

  }

  uploadFile(event) {

    const file = event.target.files[0];
    console.log(file, 'file')
    if (!this.type || (this.type && this.validTypes[this.type].includes(file.type))) {
      this.uploadPercent = 0;
      const path = (this.folder.slice(-1) === '/' ? this.folder : this.folder + '/') + file.name;
      const ref = this.storage.ref(path);
      const task = ref.put(file);
      console.log(task, 'task')
      task.percentageChanges().subscribe((uploadPercent) => {
        this.uploadPercent = uploadPercent;
      })

      task.snapshotChanges().pipe(
        finalize(() => {

          console.log(ref, 'ref')
          ref.getDownloadURL().subscribe((url: string) => {
            const name = file.name;
            const size = file.size;
            const type = file.type;

            this.img = new FileModel({name, size, type, url, path});

            console.log(this.img, 'this.img')
            this.uploadPercent = null;
          })


        })
      )
        .subscribe()

    } else {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'You can only upload the following types of files: ' + this.validTypes[this.type].join(', '),
        timer: 2000
      });
    }

  }

  delete() {


    const ref = this.storage.ref(this.img.path);
    ref.delete().subscribe(() => {
      this.img = null;

      Swal.fire({
        icon: 'success',
        title: 'File deleted',
        text: this.img.name + ' successfully deleted',
        timer: 2000
      });
    })
  }

  getFile() {
    const modalRef = this.modalService.open(FileManagerModalComponent, {scrollable: true});
    modalRef.componentInstance.folder = this.folder;
    modalRef.componentInstance.enableSelect = this.enableSelect;
    modalRef.result.then((file) => {
      console.log(file, 'file return')
      this.img = new FileModel(file);
    });

  }
}
