import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {WalletService} from '../../../../core/services/wallet.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-new-wallet',
    templateUrl: './new-wallet.component.html',
    styleUrls: ['./new-wallet.component.css']
})
export class NewWalletComponent implements OnInit {
    step: string;
    wallet: any;
    password: string;
    repeatPassword: string;
    passwordErr: boolean;
    repeatPasswordErr: boolean;
    passwordErrText: string;

    constructor(
        private authService: AuthenticationService,
        private walletService: WalletService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
    }

    reset(): void {
        this.password = '';
        this.repeatPassword = '';
        this.step = 'newPassword';
    }

    createWallet() {
        if (this.verifyPassword()) {
            this.wallet = this.walletService.newWallet(this.password);
            this.step = 'walletInfo';
        }
    }


    verifyPassword(): boolean {
        this.passwordErr = false;
        this.repeatPasswordErr = false;
        if (this.password !== this.repeatPassword) {
            this.translate.get('signUp.errorRepeatPassword').subscribe((errorRepeatPassword: any) => {
                this.passwordErrText = errorRepeatPassword;
                this.passwordErr = true;
                this.repeatPasswordErr = true;
            });
            return false;
        }
        if (!this.password) {
            this.translate.get('signUp.errorEmptyPassword').subscribe((errorEmptyPassword: any) => {
                this.passwordErrText = errorEmptyPassword;
                this.passwordErr = true;
            });
            return false;
        }
        this.passwordErrText = '';
        return true;
    }

    login() {
        this.authService.login();
    }

}
