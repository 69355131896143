import {Component, OnInit} from '@angular/core';
import {DocumentFormService} from '../../../../../core/services/documents/document-form.service';

@Component({
    selector: 'app-slaughterhouse-section',
    templateUrl: './slaughterhouse-section.component.html',
    styleUrls: ['./slaughterhouse-section.component.css']
})
export class SlaughterhouseSectionComponent implements OnInit {
    confirmBtn = {
        type: 'outline-success btn-block',
        loading: false
    };

    constructor(
        public formService: DocumentFormService
    ) {
    }

    ngOnInit() {
    }

    sign() {
        this.formService.sign('SLAUGHTERHOUSE');
    }

    confirmDocument() {
        this.confirmBtn.loading = true;
        this.formService.confirmDocument().then(() => {
            this.confirmBtn.loading = false;
        }).catch(() => {
            this.confirmBtn.loading = false;
        });
    }
}
