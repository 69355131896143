import {Component, OnInit} from '@angular/core';
import {ProviderPendingFarmsService} from '../../../core/services/providers/provider-pending-farms.service';
import {FarmsService} from '../../../core/services/farms/farms.service';


@Component({
    selector: 'app-provider-pending-farms',
    templateUrl: './provider-pending-farms.component.html',
    styleUrls: ['./provider-pending-farms.component.css']
})
export class ProviderPendingFarmsComponent implements OnInit {
    pendingFarmWalletAddress: string;
    farms: any[];

    constructor(
        private providerPendingFarmsService: ProviderPendingFarmsService,
        public farmsService: FarmsService,
    ) {
        this.getFarms();
    }

    ngOnInit() {
        this.providerPendingFarmsService.renderTable();
    }

    getFarms() {
        this.farmsService.farms.subscribe((farms) => {
            this.farms = farms;
        });
    }

    savePendingFarm() {
        this.providerPendingFarmsService.savePendingFarm(this.pendingFarmWalletAddress);
        this.pendingFarmWalletAddress = '';
    }


}
