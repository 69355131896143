import {Component, OnInit} from '@angular/core';
import {ProcessFormService} from '../../../../core/services/processes/process-form.service';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import {ProcessInterface} from '../../../../core/interfaces/lot/process.interface';
import {ProcessModel} from '../../../../core/models/process.model';

@Component({
  selector: 'app-process-form',
  templateUrl: './process-form.component.html',
  styleUrls: ['./process-form.component.css']
})
export class ProcessFormComponent implements OnInit {


  constructor(
    public formService: ProcessFormService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {

  }

  saveProcess() {
    this.formService.saveProcess();

    this.translate.get([
      'generic.congratulations',
      'generic.successfullySaved',
    ]).subscribe((trans: any) => {
      Swal.fire({
        icon: 'success',
        title: trans['generic.congratulations'],
        text: trans['generic.successfullySaved'],
        timer: 2000
      });
    });


  }


}
