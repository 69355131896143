import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-importer',
  templateUrl: './qr-importer.component.html',
  styleUrls: ['./qr-importer.component.css']
})
export class QrImporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
