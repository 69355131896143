import {getVal} from '../../utils/getNestedObject';
import {AdminInterface} from '../../interfaces/identity/admin.interface';

export class AdminModel {
    id: string;
    fiscalName: string;
    commercialName: string;
    email: string;
    mobile: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
    country: string;
    token: string;
    type: 'ADMIN';
    walletAddress: string;
    delegatedGas: boolean;
    active: boolean;

    constructor(
        admin?: AdminInterface
    ) {
        this.id = getVal(admin, 'id') || '';
        this.fiscalName = getVal(admin, 'fiscalName') || '';
        this.fiscalName = getVal(admin, 'fiscalName') || '';
        this.commercialName = getVal(admin, 'commercialName') || '';
        this.email = getVal(admin, 'email') || '';
        this.mobile = getVal(admin, 'mobile') || '';
        this.phone = getVal(admin, 'phone') || '';
        this.address = getVal(admin, 'address') || '';
        this.city = getVal(admin, 'city') || '';
        this.state = getVal(admin, 'state') || '';
        this.province = getVal(admin, 'province') || '';
        this.postalCode = getVal(admin, 'postalCode') || '';
        this.country = getVal(admin, 'country') || '';
        this.token = getVal(admin, 'token') || '';
        this.type = getVal(admin, 'type') || '';
        this.walletAddress = getVal(admin, 'walletAddress') || '';
        this.delegatedGas = getVal(admin, 'delegatedGas') || true;
        this.active = getVal(admin, 'active') || true;
    }
}
