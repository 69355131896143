import {Component, OnInit} from '@angular/core';
import {PendingIdentitiesService} from 'src/app/core/services/identities/pending-identities.service';
import {PendingIdentityFormService} from '../../../core/services/identities/pending-identity-form.service';

@Component({
    selector: 'app-pending-identities',
    templateUrl: './pending-identities.component.html',
    styleUrls: ['./pending-identities.component.css']
})
export class PendingIdentitiesComponent implements OnInit {

    constructor(
        private pendingIdentitiesService: PendingIdentitiesService,
        public formService: PendingIdentityFormService
    ) {
    }

    ngOnInit() {
        this.pendingIdentitiesService.renderTable();
    }

    newPendingIdentity() {
        this.formService.newPendingIdentity();
    }
}
