import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireStorage} from '@angular/fire/storage';
import Swal from 'sweetalert2';
import {FileModel} from '../../../../core/models/file.model';
import {DragDropUploaderModalComponent} from '../../drag-drop-uploader-modal/drag-drop-uploader-modal.component';
import {finalize} from 'rxjs/operators';
import {FileSystemDirectoryEntry, FileSystemFileEntry} from 'ngx-file-drop';


@Component({
  selector: 'app-file-manager-modal',
  templateUrl: './file-manager-modal.component.html',
  styleUrls: ['./file-manager-modal.component.css']
})
export class FileManagerModalComponent implements OnInit {
  @Input() public folder;
  @Input() public enableSelect;
  files = [];

  constructor(
    public activeModal: NgbActiveModal,
    private storage: AngularFireStorage,
    public modalService: NgbModal,
  ) {


  }

  ngOnInit(): void {

    console.log(this.folder, 'this.folder')

    this.storage.ref(this.folder).listAll().subscribe((res) => {
      // console.log(res, 'refs')
      // res.prefixes.forEach((folderRef) => {
      //
      //   console.log(folderRef, 'folderRef')
      //   console.log(folderRef.name, 'folderRef.name')
      //   console.log(folderRef.getMetadata(), 'folderRef.getMetaData()')
      //
      //
      //   // All the prefixes under listRef.
      //   // You may call listAll() recursively on them.
      // });

      res.items.forEach((itemRef) => {

        itemRef.getMetadata().then((file) => {
          itemRef.getDownloadURL().then((url) => {
            const name = file.name;
            const path = file.fullPath;
            const size = file.size;
            const type = file.contentType;
            this.files.push(new FileModel({name, url, path, size, type}));

          })
        })

        // All the items under listRef.
      });

    });
  }


  delete(i: number) {

    const file = {...this.files[i]}
    const ref = this.storage.ref(file.path);
    ref.delete().subscribe(() => {

      this.files.splice(i, 1);

      Swal.fire({
        icon: 'success',
        title: 'File deleted',
        text: file.name + ' successfully deleted',
        timer: 2000
      });
    })
  }

  select(i: number) {
    console.log(this.files[i], 'this.files[i]')
    // console.log(this.files[i].getMegatadata(), 'this.files[i].getMegatadata()')
    this.activeModal.close({...this.files[i]})
  }

  addFiles() {
    const modalRef = this.modalService.open(DragDropUploaderModalComponent, {scrollable: true});
    modalRef.componentInstance.folder = this.folder;
    modalRef.result.then((files) => {
      console.log(files, 'files return');
      console.log(files.length, 'files return.length');


      for (const droppedFile of files) {
      // console.log(files, 'files')

      // this.activeModal.close(files);
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const path = (this.folder ? (this.folder.slice(-1) === '/' ? this.folder : this.folder + '/') : '') + file.name;
        const ref = this.storage.ref(path);
        const task = ref.put(file);
        console.log(task, 'task')

        task.snapshotChanges().pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe((url: string) => {
              const name = file.name;
              const size = file.size;
              const type = file.type;


              this.files.unshift(new FileModel({name, size, type, url, path}))
            })


          })
        )
          .subscribe()

          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);


        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }





      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log(file, 'fileee')

      }


      // this.img = new FileModel(file);
    });
  }
}
