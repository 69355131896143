import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {WalletService} from '../../../core/services/wallet.service';

declare var $: any;
declare var particlesJS: any;
declare var pJSDom: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  password: string;
  passwordErr: boolean;
  loadPartice: boolean;
  loginBtn = {
    type: 'theme btn-lg btn-block mb-2',
    loading: false
  };

  constructor(
    private walletService: WalletService,
    private authService: AuthenticationService) {
  }

  ngOnInit() {
    $('.particles-js').each(function (e) {
      $(this).attr('id', 'particle-' + e);
      particlesJS.load('particle-' + e, 'assets/vendors/particle/particles.json');
    });

    // TODO: DELETE THIS
    // this.password = 'a';
    // this.login();
  }

  clearWallet() {
    this.authService.clear();
  }

  login() {
    this.loginBtn.loading = true;
    try {
      this.walletService.importWalletFromStorage(this.password);
      if (this.walletService.wallet) {
        this.authService.wallet = this.walletService.wallet;
        this.authService.login().then(() => {
          this.loginBtn.loading = false;
        }).catch(() => {
          this.loginBtn.loading = false;
        });


      }
    } catch (e) {
      console.log('Err');
      this.passwordErr = true;
      // this.loginBtn.loading = false;
    }

  }

  ngOnDestroy(): void {
    if (pJSDom.length > 0) {
      for (const particles of pJSDom.length) {
        particles.pJS.fn.vendors.destroypJS();
      }
      pJSDom = [];
    }
  }
}
