import {Component, OnInit} from '@angular/core';
import {RawMaterialFormService} from '../../../../core/services/raw-materials/raw-material-form.service';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import {RawMaterialInterface} from '../../../../core/interfaces/lot/raw-material.interface';
import {RawMaterialModel} from '../../../../core/models/raw-material.model';
import {ProcessesService} from '../../../../core/services/processes/processes.service';
import {ProcessInterface} from '../../../../core/interfaces/lot/process.interface';
import {ProductInterface} from '../../../../core/interfaces/lot/product.interface';
import {ProductsService} from '../../../../core/services/products/products.service';
import {RawMaterialsService} from '../../../../core/services/raw-materials/raw-materials.service';
import {DocumentInterface} from '../../../../core/interfaces/document/document';
import {DocumentsService} from '../../../../core/services/documents/documents.service';
import {DocumentsHistoricService} from '../../../../core/services/documents/documents-historic.service';
import {RawMaterialProductsService} from '../../../../core/services/raw-material-products/raw-material-products.service';
import {RawMaterialProductInterface} from '../../../../core/interfaces/lot/raw-material-product.interface';

@Component({
  selector: 'app-raw-material-form',
  templateUrl: './raw-material-form.component.html',
  styleUrls: ['./raw-material-form.component.css']
})
export class RawMaterialFormComponent implements OnInit {
  dtOptions = {
    format: 'DD/MM/YYYY HH:mm',
    icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'far fa-calendar-check',
      clear: 'fas fa-trash',
      close: 'fas fa-times'
    },
    locale: 'ca'
  };
  processes: ProcessInterface[];
  rawMaterialProducts: RawMaterialProductInterface[];

  rawMaterials: RawMaterialInterface[];

  documents: DocumentInterface[];


  constructor(
    public formService: RawMaterialFormService,
    public processesService: ProcessesService,
    public rawMaterialProductsService: RawMaterialProductsService,
    public documentsService: DocumentsHistoricService,
    public rawMaterialsService: RawMaterialsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    console.log(this.formService.rawMaterial, 'NGINIT this.formService.rawMaterial')
    this.processesService.processes.subscribe((processes: ProcessInterface[]) => {
      console.log(processes, 'processes')
      console.log(typeof processes, 'typeof processes')
      console.log(typeof [{id: 1}, {id: 2}, {id: 3}], 'typeof processes2')
      console.log(processes[0], 'processes3a')
      console.log(typeof processes[0], 'typeof processes3')
      console.log(typeof {id: 1}, 'typeof processes4')
      this.processes = processes;
    });
    this.rawMaterialProductsService.rawMaterialProducts.subscribe((rawMaterialProducts: RawMaterialProductInterface[]) => this.rawMaterialProducts = rawMaterialProducts);
    this.documentsService.documents.subscribe((documents: DocumentInterface[]) => this.documents = documents);
    this.rawMaterialsService.rawMaterials.subscribe((rawMaterials: RawMaterialInterface[]) => this.rawMaterials = rawMaterials);
  }

  saveRawMaterial() {
    console.log(this.formService.rawMaterial, 'SAVE this.formService.rawMaterial')
    this.formService.saveRawMaterial();
    if (this.formService.rawMaterial.id) {
      this.translate.get([
        'generic.congratulations',
        'generic.successfullySaved',
      ]).subscribe((trans: any) => {
        Swal.fire({
          icon: 'success',
          title: trans['generic.congratulations'],
          text: trans['generic.successfullySaved'],
          timer: 2000
        });
      });
    }
  }

  ngChangeDt(e) {
    this.formService.rawMaterial.expiration = (e && typeof e.unix === 'function' ? e.unix() * 1000 : this.formService.rawMaterial.expiration);
  }

}
