import {getVal} from '../../core/utils/getNestedObject';
import {LotInterface} from '../interfaces/lot/lot.interface';
import {ProcessInterface} from '../interfaces/lot/process.interface';
import {DocumentInterface} from '../interfaces/document/document';
import {ProductInterface} from '../interfaces/lot/product.interface';
import {RawMaterialInterface} from '../interfaces/lot/raw-material.interface';
import {RawMaterialProductInterface} from '../interfaces/lot/raw-material-product.interface';


export class RawMaterialModel {
  id: string;
  externalId: string;
  date: number;
  provider: string;
  rawMaterialProduct: RawMaterialProductInterface;
  quantity: number;
  weight: number;
  weightUnit: string;
  expiration: number;
  geolocation: number;

  constructor(rawMaterial: RawMaterialInterface | any) {
    this.id = rawMaterial.id || '';
    this.externalId = rawMaterial.externalId || '';
    this.date = rawMaterial.date || +new Date();
    this.provider = rawMaterial.provider || '';
    this.rawMaterialProduct = rawMaterial.rawMaterialProduct;
    this.quantity = rawMaterial.quantity || 0;
    this.weight = rawMaterial.weight || 0;
    this.weightUnit = rawMaterial.weightUnit || 'kg';
    this.expiration = rawMaterial.expiration;
    this.geolocation = rawMaterial.geolocation || '';
  }

}



