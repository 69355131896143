import {Component, OnInit} from '@angular/core';
import {ExternalLinkInterface} from '../../../core/interfaces/external-link.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ExternalLinkModel} from '../../../core/models/external-link.model';

@Component({
  selector: 'app-external-link-modal',
  templateUrl: './external-link-modal.component.html',
  styleUrls: ['./external-link-modal.component.css']
})
export class ExternalLinkModalComponent implements OnInit {
  externalLink: ExternalLinkModel = new ExternalLinkModel({});


  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }


  save() {
    this.activeModal.close(this.externalLink);
  }

}
