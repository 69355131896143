import {Injectable, ChangeDetectorRef} from '@angular/core';
import {ProcessesService} from './processes.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {ProcessInterface} from '../../interfaces/lot/process.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ProcessModel} from '../../models/process.model';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ProcessFormService {
  process: ProcessModel = new ProcessModel({});
  selectedId: string;

  constructor(
    private processesService: ProcessesService,
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    public clipboardService: ClipboardService,
    private http: HttpClient,
    private translate: TranslateService
  ) {


    $(document).on('click', '.edit-process-btn', (e) => {
      const id = $(e.currentTarget).attr('process-id');
      console.log(id, 'id');
      this.loadProcess(id);
    });

    $(document).on('click', '.del-process-btn', (e) => {
      const id = $(e.currentTarget).attr('process-id');
      this.delProcess(id);
    });
  }

  saveProcess(close: boolean = true) {
    console.log(this.process, 'this.process')
    this.processesService.saveProcess(this.process, this.selectedId);
    if (close) {
      $('#new-process-modal').modal('hide');
    }
  }

  loadProcess(id) {
    // this.process = new ProcessModel();
    console.log(id, 'id')
    this.processesService.getProcess(id)
      .pipe(
        first()
      )
      .subscribe((process: ProcessInterface) => {

        console.log(process, 'process1');
        this.selectedId = process.id;
        this.process = new ProcessModel(process);

      });

  }

  delProcess(processId) {
    console.log(processId, 'delProcess')
    if (processId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'generic.deleteAlert.title',
        'generic.deleteAlert.text',
        'generic.deletedAlert.title',
        'generic.deletedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['generic.deleteAlert.title'],
          text: trans['generic.deleteAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.db.object(`processes/${processId}`).remove()
              .then(() => {
                Swal.fire(
                  trans['generic.deletedAlert.title'],
                  trans['generic.deletedAlert.text'],
                  'success'
                );
              });
          }
        });
      });
    }
  }

  newProcess() {
    this.process = new ProcessModel({});
    this.selectedId = null;
  }

}
