import {AdditionalFieldsInterface} from '../interfaces/lot/product.interface';
import {ExternalLinkInterface} from '../interfaces/external-link.interface';
import {LotTemplateInterface} from '../interfaces/lot/lot-template.interface';

export class LotTemplateModel {
  id: string;
  name: string;
  additionalFields: AdditionalFieldsInterface;



  constructor(lotTemplate: LotTemplateInterface | any) {
    this.id = lotTemplate.id || '';
    this.name = lotTemplate.name || '';
    this.additionalFields = lotTemplate.additionalFields || {sections: []};
  }

}



