import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-print-labels',
  templateUrl: './print-labels.component.html',
  styleUrls: ['./print-labels.component.css']
})
export class PrintLabelsComponent implements OnInit {
  labels: string[] = [];

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      let start = params.start <= params.end ? params.start : params.end;
      const end = params.end > params.start ? params.end : params.start;

      while (start <= end) {
        this.labels.push(start.toString());
        start++;
      }
    })
  }

  ngOnInit(): void {
    window.print();
  }

}
