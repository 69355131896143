import {Injectable, ChangeDetectorRef} from '@angular/core';
import {ProductsService} from './products.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {ProductInterface} from '../../interfaces/lot/product.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ProductModel} from '../../models/product.model';
// import * as randomstring from 'randomstring';
import { nanoid } from 'nanoid'
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ProductFormService {
  product: ProductModel = new ProductModel({});
  selectedId: string;

  constructor(
    private productsService: ProductsService,
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    public clipboardService: ClipboardService,
    private http: HttpClient,
    private translate: TranslateService
  ) {


    $(document).on('click', '.edit-product-btn', (e) => {
      const id = $(e.currentTarget).attr('product-id');
      console.log(id, 'id');
      this.loadProduct(id);
    });

    $(document).on('click', '.del-product-btn', (e) => {
      const id = $(e.currentTarget).attr('product-id');
      this.delProduct(id);
    });
  }

  saveProduct(close: boolean = true) {
    console.log(close, 'close')
    console.log(this.product, 'this.product')
     // console.log(randomstring, 'randomstring');
     // console.log(randomstring.generate(20), 'randomstring2');
    // return;
    for (const section of this.product.additionalFields.sections) {
      if(!section.id) {
        // console.log({randomstring});
        section.id = nanoid(20)
      }
    }
    console.log(this.product,'this.product2');
    this.productsService.saveProduct(this.product, this.selectedId);
    console.log('donee')
    if (close) {
      $('#new-product-modal').modal('hide');
    }
  }

  loadProduct(id) {
    // this.product = new ProductModel();
    console.log(id, 'id')
    this.productsService.getProduct(id)
      .pipe(
        first()
      )
      .subscribe((product: ProductInterface) => {

        console.log(product, 'product1');
        this.selectedId = product.id;
        this.product = new ProductModel(product);
console.log(this.product, 'product2');
      });

  }

  delProduct(productId) {
    console.log(productId, 'delProduct')
    if (productId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'generic.deleteAlert.title',
        'generic.deleteAlert.text',
        'generic.deletedAlert.title',
        'generic.deletedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['generic.deleteAlert.title'],
          text: trans['generic.deleteAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.db.object(`products/${productId}`).remove()
              .then(() => {
                Swal.fire(
                  trans['generic.deletedAlert.title'],
                  trans['generic.deletedAlert.text'],
                  'success'
                );
              });
          }
        });
      });
    }
  }

  newProduct() {
    this.product = new ProductModel({});
    this.selectedId = null;
  }

}
