import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChartsService {
    public documentQty: Observable<any>;
    public earnings: Observable<any>;
    public transactionsQty: Observable<any>;
    public transactionsDuration: Observable<any>;
    public interval$ = new Subject<string>();
    public dateFormat: string;


    constructor(
        private db: AngularFireDatabase
    ) {
        this.interval$.subscribe((interval) => {
            switch (interval) {
                case 'daily':
                    this.dateFormat = 'DD/MM/YYYY';
                    break;
                case 'monthly':
                    this.dateFormat = 'MMM YYYY';
                    break;
                case 'yearly':
                    this.dateFormat = 'YYYY';
                    break;
            }
            this.loadCharts(interval);
        });
        this.loadCharts('daily');

    }

    loadCharts(interval) {
        this.documentQty = this.db.list(`charts/documents/count/${interval}`)
            .snapshotChanges()
            .pipe(
                map(documentsQty => {
                    return documentsQty.reduce((total, current, i) => {
                        total[i] = {
                            value: current.payload.val(),
                            date: moment.unix(Number(current.key) / 1000).format(this.dateFormat)
                        };
                        return total;
                    }, []);
                })
            );

        this.earnings = this.db.list(`charts/documents/earnings/${interval}`)
            .snapshotChanges()
            .pipe(
                map(earnings => {
                    return earnings.reduce((total, current, i) => {
                        const data: any = current.payload.val();
                        total[i] = {
                            profit: data.profit || 0,
                            expenses: data.expenses || 0,
                            date: moment.unix(Number(current.key) / 1000).format(this.dateFormat)
                        };
                        return total;
                    }, []);
                })
            );

        this.transactionsQty = this.db.list(`charts/transactions/count/${interval}`)
            .snapshotChanges()
            .pipe(
                map(transactionsQty => {
                    return transactionsQty.reduce((total, current, i) => {
                        total[i] = {
                            value: current.payload.val(),
                            date: moment.unix(Number(current.key) / 1000).format(this.dateFormat)
                        };
                        return total;
                    }, []);
                })
            );

        this.transactionsDuration = this.db.list(`charts/transactions/duration/${interval}`)
            .snapshotChanges()
            .pipe(
                map(transactionsDurations => {
                    return transactionsDurations.reduce((total, current, i) => {
                        const transactionInfo: any = current.payload.val();
                        total[i] = {
                            value: Math.round((transactionInfo.duration / transactionInfo.count) * 100) / 100,
                            date: moment.unix(Number(current.key) / 1000).format(this.dateFormat)
                        };
                        return total;
                    }, []);
                })
            );
    }

}
