import {Component, OnInit} from '@angular/core';
import {ProcessesService} from '../../../core/services/processes/processes.service';
import {ProcessFormService} from '../../../core/services/processes/process-form.service';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.css']
})
export class ProcessesComponent implements OnInit {

  constructor(
    public processesService: ProcessesService,
    public processFormService: ProcessFormService,
  ) {
  }

  ngOnInit() {
    this.processesService.renderTable();
  }

  newProcess() {
    this.processFormService.newProcess();
  }


}
