import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {ChartsService} from '../../../../core/services/charts.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-transactions-duration-chart',
    templateUrl: './transactions-duration-chart.component.html',
    styleUrls: ['./transactions-duration-chart.component.css']
})
export class TransactionsDurationChartComponent implements OnInit {
    public lineChartOptions: ChartOptions;
    public lineChartLabels: Label[] = [];
    public lineChartType: ChartType = 'line';
    public lineChartLegend = true;

    public lineChartData: ChartDataSets[];

    constructor(
        public chartsService: ChartsService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.loadChart();
        this.chartsService.interval$.subscribe(() => {
            this.loadChart();
        });
    }

    loadChart() {
        this.translate.get('charts.minutes').subscribe((minutes: any) => {
            this.lineChartData = [
                {data: [], label: minutes}
            ];
        });
        this.chartsService.transactionsDuration.subscribe((transactionsDuration) => {
            const data = [];
            const labels = [];

            for (const transactionDuration of transactionsDuration) {
                labels.push(transactionDuration.date);
                data.push(transactionDuration.value);
            }

            this.lineChartOptions = {
                responsive: true,
                scales: {
                    xAxes: [{}], yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            };

            this.lineChartData[0].data = data;
            this.lineChartLabels = labels;
        });
    }
}
