import { Component, OnInit } from '@angular/core';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';
import {VetModel} from 'src/app/core/models/identity/vet.model';

@Component({
  selector: 'app-modify-vet-form',
  templateUrl: './modify-vet-form.component.html',
  styleUrls: ['./modify-vet-form.component.css']
})
export class ModifyVetFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new VetModel(this.modifyIdentityFormService.identity);
  }

}
