import {ConfigModel} from '../interfaces/config';

export interface AclInterface {
    permissions: any;
    currentUserRoles: any;
}

export class AclModel implements AclInterface, ConfigModel {
    public config: any;

    // default permissions
    public permissions: any = {
        ADMIN: ['canDoAnything'],
        FARM: ['canDoAnything'],
        VET: ['canDoAnything'],
        DRIVER: ['canDoAnything'],
        LOGISTIC: ['canDoAnything'],
        PROVIDER: ['canDoAnything'],
        SLAUGHTERHOUSE: ['canDoAnything'],
        USER: ['canLogin']
    };

    // store an object of current user roles
    public currentUserRoles: any = {};

    constructor() {
    }
}
