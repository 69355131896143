import {getVal} from '../../core/utils/getNestedObject';
import {DocumentInterface} from '../interfaces/document/document';
import {DocumentDriverInterface} from '../interfaces/document/document-driver-interface';
import {DocumentVetInterface} from '../interfaces/document/document-vet-interface';
import {DocumentSlaughterhouseInterface} from '../interfaces/document/document-slaughterhouse-interface';
import {DocumentDynamicDataInterface} from '../interfaces/document/document-dynamic-data-interface';
import {DocumentStaticDataInterface} from '../interfaces/document/document-static-data-interface';
import {DocumentFarmInterface} from '../interfaces/document/document-farm-interface';

export class DocumentModel {
    public dynamicData: DocumentDynamicDataInterface;
    public staticData: DocumentStaticDataInterface;
    public farmData: DocumentFarmInterface;
    public slaughterhouseData: DocumentSlaughterhouseInterface;
    public vetData: DocumentVetInterface;
    public driverData: DocumentDriverInterface;

    constructor(
        document?: DocumentInterface) {

        this.dynamicData = {
            status: getVal(document, 'dynamicData.status') || 'open',
            time: getVal(document, 'dynamicData.time') || '',
            errorTime: getVal(document, 'dynamicData.errorTime') || false,
            confirmed: getVal(document, 'dynamicData.confirmed') || false,
            confirmedDt: getVal(document, 'dynamicData.confirmedDt') || 0,
        };

        this.staticData = {
            data: {
                category: getVal(document, 'staticData.data.category') || '',
                categoryCode: getVal(document, 'staticData.data.categoryCode') || '',
                creationDt: getVal(document, 'staticData.data.creationDt') || +new Date(),
                departureDt: getVal(document, 'staticData.data.departureDt') || 0,
                deliveryDt: getVal(document, 'staticData.data.deliveryDt') || 0,
                documentId: getVal(document, 'staticData.data.documentId') || '',
                farm: {
                    id: getVal(document, 'staticData.data.farm.id') || '',
                    fiscalName: getVal(document, 'staticData.data.farm.fiscalName') || '',
                    holderId: getVal(document, 'staticData.data.farm.holderId') || '',
                    holderName: getVal(document, 'staticData.data.farm.holderName') || '',
                    postalCode: getVal(document, 'staticData.data.farm.postalCode') || '',
                    QS: getVal(document, 'staticData.data.farm.QS') || '',
                    city: getVal(document, 'staticData.data.farm.city') || '',
                    MOCode: getVal(document, 'staticData.data.farm.MOCode') || '',
                    REGACode: getVal(document, 'staticData.data.farm.REGACode') || '',
                    walletAddress: getVal(document, 'staticData.data.farm.walletAddress') || '',
                    provider: {
                        id: getVal(document, 'staticData.data.farm.provider.id') || '',
                        fiscalName: getVal(document, 'staticData.data.farm.provider.fiscalName') || '',
                        walletAddress: getVal(document, 'staticData.data.farm.provider.walletAddress') || '',
                    },
                },
                animalsQty: getVal(document, 'staticData.data.animalsQty') || '',
                sex: getVal(document, 'staticData.data.sex') || '',
                slaughterhouse: {
                    id: getVal(document, 'staticData.data.slaughterhouse.id') || '',
                    fiscalName: getVal(document, 'staticData.data.slaughterhouse.fiscalName') || '',
                    holderId: getVal(document, 'staticData.data.slaughterhouse.holderId') || '',
                    holderName: getVal(document, 'staticData.data.slaughterhouse.holderName') || '',
                    MOCode: getVal(document, 'staticData.data.slaughterhouse.MOCode') || '',
                    REGACode: getVal(document, 'staticData.data.slaughterhouse.REGACode') || '',
                    postalCode: getVal(document, 'staticData.data.slaughterhouse.postalCode') || '',
                    city: getVal(document, 'staticData.data.slaughterhouse.city') || '',
                    QS: getVal(document, 'staticData.data.slaughterhouse.QS') || '',
                    walletAddress: getVal(document, 'staticData.data.slaughterhouse.walletAddress') || ''
                },
                spice: 'Porcí',
                mobility: getVal(document, 'staticData.data.mobility') || ''
            },
            hashedData: getVal(document, 'staticData.hashedData') || '',
            hash: getVal(document, 'staticData.hash') || ''
        };

        this.farmData = {
            data: {
                comment: getVal(document, 'farmData.data.comment') || '',
                signatureDt: getVal(document, 'farmData.data.signatureDt') || ''
            },
            signature: {
                signedData: getVal(document, 'farmData.signature.signedData') || {},
                signature: getVal(document, 'farmData.signature.signature') || '',
                hash: getVal(document, 'farmData.signature.hash') || '',
                txDt: getVal(document, 'farmData.signature.txDt') || '',
                tx: getVal(document, 'farmData.signature.tx') || ''
            },
        };

        this.slaughterhouseData = {
            data: {
                animalsQty: getVal(document, 'slaughterhouseData.data.animalsQty') || '',
                comment: getVal(document, 'slaughterhouseData.data.comment') || '',
                signatureDt: getVal(document, 'slaughterhouseData.data.signatureDt') || ''
            },
            signature: {
                signedData: getVal(document, 'slaughterhouseData.signature.signedData') || '',
                signature: getVal(document, 'slaughterhouseData.signature.signature') || '',
                hash: getVal(document, 'slaughterhouseData.signature.hash') || '',
                txDt: getVal(document, 'slaughterhouseData.signature.txDt') || '',
                tx: getVal(document, 'slaughterhouseData.signature.tx') || ''
            }
        };

        this.vetData = {
            data: {
                vet: {
                    id: getVal(document, 'vetData.data.vet.id') || '',
                    collegeNumber: getVal(document, 'vetData.data.vet.collegeNumber') || '',
                    fiscalName: getVal(document, 'vetData.data.vet.fiscalName') || '',
                    walletAddress: getVal(document, 'vetData.data.vet.walletAddress') || ''
                },
                signatureDt: getVal(document, 'vetData.data.signatureDt') || '',
                comment: getVal(document, 'vetData.data.comment') || '',
            },
            signature: {
                signedData: getVal(document, 'vetData.signature.signedData') || '',
                signature: getVal(document, 'vetData.signature.signature') || '',
                hash: getVal(document, 'vetData.signature.hash') || '',
                txDt: getVal(document, 'vetData.signature.txDt') || '',
                tx: getVal(document, 'vetData.signature.tx') || ''
            }
        };

        this.driverData = {
            data: {
                cleanDt: getVal(document, 'driverData.data.cleanDt') || '',
                cleanNumber: getVal(document, 'driverData.data.cleanNumber') || '',
                driver: {
                    id: getVal(document, 'driverData.data.driver.id') || '',
                    fiscalName: getVal(document, 'driverData.data.driver.fiscalName') || '',
                    SIRCode: getVal(document, 'driverData.data.driver.SIRCode') || '',
                    ATESCode: getVal(document, 'driverData.data.driver.ATESCode') || '',
                    walletAddress: getVal(document, 'driverData.data.driver.walletAddress') || ''
                },
                driverComment: getVal(document, 'driverData.data.driverComment') || '',
                logistic: {
                    id: getVal(document, 'driverData.data.logistic.id') || '',
                    fiscalName: getVal(document, 'driverData.data.logistic.fiscalName') || '',
                    walletAddress: getVal(document, 'driverData.data.logistic.walletAddress') || ''
                },
                signatureDt: getVal(document, 'driverData.data.signatureDt') || '',
                transport: getVal(document, 'driverData.data.transport') || '',
                transportCode: getVal(document, 'driverData.data.transportCode') || '',
                registrationNumber: getVal(document, 'driverData.data.registrationNumber') || ''
            },
            signature: {
                signedData: getVal(document, 'driverData.signature.signedData') || '',
                signature: getVal(document, 'driverData.signature.signature') || '',
                hash: getVal(document, 'driverData.signature.hash') || '',
                txDt: getVal(document, 'driverData.signature.txDt') || '',
                tx: getVal(document, 'driverData.signature.tx') || ''
            }
        };

    }

}



