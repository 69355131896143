import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FarmsService {
    farms: any;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.farms = db.list('identity', ref =>
            ref.orderByChild('type').equalTo('FARM')
        ).snapshotChanges()
            .pipe(map(items => {
                    return items.reduce((total, current, i) => {
                        const value = current.payload.val();
                        total[i] = value;
                        return total;
                    }, []);
                })
            );
    }


}
