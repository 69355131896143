export const locale = {
  lang: 'en',
  data: {
    menu: {
      home: 'Home',
      identity: 'Identity',
      identities: 'Identities',
      farms: 'Farms',
      drivers: 'Drivers',
      documents: {
        documents: 'Documents',
        pendingDocuments: 'Pending',
        documentsHistoric: 'Historic',
      },
      lots: {
        lots: 'Lots',
        productionLots: 'Production lots',
        rawMaterials: 'Raw Materials lots',
        rawMaterialProducts: 'Raw Materials products',
        products: 'Production products',
        lotsTemplates: 'Lots templates',
        processes: 'Processes'
      },
      transactions: 'Transactions',
      charts: 'Stats',
      wallet: 'Wallet',
      config: 'Config',
      listFilter: {
        pending: 'Pending',
        success: 'Success'
      },
      chains: 'Chains',
      logout: 'Logout',
    },
    home: {
      hello: 'Hi',
      welcome: `
                <h4>You are successfully connected to the <b>blockchain</b></h4>
                <h4>Your public key is</h4>
             `,
      requestIdentity: {
        getNewIdentity: 'Get new identity',
        getIdentityMsg: 'You don\'t have an identiry.<br>To get access you must request a new identity.',
        newIdentity: 'New identity',
        identity: 'Identity'
      },
    },
    identity: {
      generic: {
        geolocation: 'Geolocation',
        id: 'NIF/CIF',
        fiscalName: 'Fiscal Name',
        commercialName: 'Commercial name',
        QS: 'QS',
        delegatedGas: 'Delegated GAS',
        address: 'Address',
        city: 'City',
        postalCode: 'Postal code',
        province: 'Province',
        country: 'Country',
        email: 'Email',
        phone: 'Phone',
        mobile: 'Mobile',
        MOCode: 'MO Code',
        REGACode: 'REGA Code',
        type: 'Type',
        holderId: 'Holder NIF/CIF',
        holderName: 'Holder name',
        token: 'Token',
        walletAddress: 'Wallet address',
        active: 'Active'
      },
      farm: {
        mobilityPass: 'Contraseña movilidad'
      },
      vet: {
        collegeNumber: 'Número de colegiado',
      },
      logistic: {
        transports: 'Vehículos',
        transportCode: 'Matrícula del vehículo',
        error: {
          emptyTransportCode: 'No se puede agregar una matrícula vacía.'
        }
      },
      driver: {
        SIRCode: 'Código Sirentra',
        ATESCode: 'Código ATES',
      },
      deleteProviderRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Estás seguro de que quiere eliminar esta solicitud?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Solicitud borrada correctamente'
        }
      },
      deleteProvider: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este proveedor?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Proveedor borrado correctamente'
        }
      },
      deleteLogisticRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta solicitud?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Solicitud borrada correctamente'
        }
      },
      deleteLogistic: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta empresa de logística?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Empresa de logística borrada correctamente'
        }
      },
      deleteTransport: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este vehículo?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Vehiculo borrado correctamente'
        }
      },
      deleteProviderFarm: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta granja?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Granja borrada correctamente'
        }
      },
      deleteProviderPendingFarm: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta granja pendiente de asignar?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Granja pendiente borrada correctamente'
        }
      },
      deleteLogisticDriver: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este transportista?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Transportista pendiente borrado correctamente'
        }
      },
      deleteLogisticPendingDriver: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este transportista pendiente de asignar?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Transportista pendiente borrado correctamente'
        }
      },
      deletePendingIdentity: {
        warning: {
          title: 'Estás seguro?',
          text: 'Este cambio no se podra revertir!'
        },
        deleted: {
          title: 'borrado!',
          text: 'Identidad pendiente borrada correctamente'
        }
      },
    },
    identities: {
      admin: 'Administrador',
      farms: 'Granjas',
      farm: 'Granja',
      provider: 'Proveedor',
      vet: 'Veterinario',
      slaughterhouse: 'Matadero',
      logistic: 'Logística',
      drivers: 'Transportistas',
      driver: 'Transportista',
    },
    pendingIdentities: {
      pendingIdentities: 'Identidades pendientes',
      newIdentity: 'Nueva identidad'
    },
    providerPendingFarms: {
      pendingFarms: 'Granjas pendientes',
      addFarm: 'Agregar granja'
    },
    logisticPendingDrivers: {
      pendingDrivers: 'Transportistas pendientes',
      addDriver: 'Agregar transportista'
    },
    transactions: {
      transaction: 'Transacción',
      signatureHash: 'Hash de la firma',
      info: 'Información'
    },
    charts: {
      ETHPrice: 'Precio ETH',
      serverBalance: 'ETHs Servidor',
      blockNumber: 'Número de bloque',
      lastTransactionDuration: 'Última transacción',
      documentsLeft: 'Guías disponibles',
      daily: 'Diario',
      monthly: 'Mensual',
      yearly: 'Anual',
      documentsQty: 'Número de guías',
      earnings: 'Ganancias',
      transactionsQty: 'Número de transacciones',
      transactionsDuration: 'Duración media transacciones',
      expenses: 'Gastos',
      profit: 'Ingresos',
      minutes: 'Minutos',
    },
    wallet: {
      publicKey: 'Llave publica',
      privateKey: 'Llave privada',
      mnemonicPhrase: 'Frase mnemotécnica',
      downloadKeystore: 'Descargar clave encriptada',
    },
    config: {
      blockChain: 'BlackChain',
      documentPrice: 'Precio por guía',
      gasPrice: 'Precio del gas',
    },
    form: {
      save: 'Save',
      saveTitle: 'Saved',
      close: 'Close',
      saveMessage: 'Successfully saved',
      link: 'Link',
      send: 'Send'
    },
    externalLink: {
      addExternalLink: 'Add external link',
      externalLinks: 'External links',
      externalLink: 'External link',
      reference: 'Reference',
      url: 'Link',
    },
    lots: {
      lots: 'Production lots',
      lotsOrigin: 'Lots origin',
      lotTemplate: 'Lot template',
      custom: 'Custom fields',
      lot: 'Production lot',
      newLot: 'New lot',
      rawMaterials: 'Raw materials lots',
      addRawMaterial: 'Add raw material lot',
      addLot: 'Add production lot',
      addDocument: 'Add traceability document',
      documents: 'Traceability documents',
      id: 'ID',
      externalId: 'External ID',
      name: 'Name',
      provider: 'Provider',
      withoutProvider: 'Without provider',
      start: 'Start',
      end: 'End',
      from: 'From',
      to: 'To',
      date: 'Date',
      units: 'units',
      quantity: 'Total units',
      expiration: 'Expiration date',
      process: 'Process',
      product: 'Product',
      weight: 'Total net weight',
      grossWeight: 'Total gross weight',
      type: 'Type',
      color: 'Color',
      origin: 'Country of origin',
      drumNum: 'Drum NO',
      homogenized: 'Homogenized honey',
      notHomogenized: 'Not homogenized honey',
      mainData: 'Main data',
      uniqueIdSeries: 'Unique id series',
      sensorTagIdSeries: 'Sensor tag id series',
      productIdAlreadyAddedAlert: {
        title: 'Error',
        text: 'There are some products that already are into another lot'
      },
      uploadToBlockchainAlert: {
        title: 'Are you sure?',
        text: 'You are going to upload the lot to the BlockChain. Once it has been uploaded, no modifications can be made to the lot.'
      }
    },
    rawMaterials: {
      rawMaterials: 'Raw materials lots',
      newRawMaterial: 'New raw material lot',
      id: 'ID',
      externalId: 'External ID',
      name: 'Name',
      date: 'Date',
      quantity: 'Total units',
      expiration: 'Expiration date',
      provider: 'Provider',
      rawMaterialProduct: 'Raw material product',
      weight: 'Total weight',
      geolocation: 'Geolocation',
    },
    rawMaterialProducts: {
      rawMaterialProducts: 'Raw material products',
      newRawMaterialProduct: 'New raw material product',
      ean13: 'EAN13 code',
      name: 'Name',
      description: 'Description',
      url: 'Website',
    },
    products: {
      products: 'Products',
      product: 'Product',
      newProduct: 'New product',
      ean13: 'EAN13 code',
      name: 'Name',
      description: 'Description',
      geolocation: 'Geolocation',
      type: 'Typo',
      color: 'Color',
      origin: 'Country of origin',
      url: 'Website',
    },
    lotsTemplates: {
      lotsTemplates: 'Lots templates',
      lotTemplate: 'Lot template',
      newLotTemplate: 'New lot template',
      name: 'Name',
      additionalFields: 'Additional fields',
      sections: 'Sections',
      section: 'Section',
      newField: 'New field',
    },
    processes: {
      processes: 'Process',
      newProcess: 'New process',
      name: 'Name',
      description: 'Description',
    },
    documents: {
      documents: 'Guías',
      document: 'Guía',
      historical: 'Histórico',
      newDocument: 'Guia nueva',
      documentId: 'Número de guía',
      category: 'Categoría',
      categories: {
        fattening: 'Engorde',
        piglets: 'Cerditos',
        recreationTransition: 'Recría / Transición',
        breedingFemales: 'Hembras reproductoras',
        replenishment: 'Reposición',
        stallions: 'Sementales',
      },
      animalsQty: 'Núm. animales',
      sex: 'Género',
      sexes: {
        male: 'Macho',
        female: 'Hembra'
      },
      mobility: 'Movilidad',
      departure: 'Salida',
      departureDt: 'Fecha/Hora Salida',
      exploitationOrigin: 'Explotación Origen',
      REGACode: 'Código REGA',
      MOCode: 'Marca Oficial',
      delivery: 'Entrega',
      deliveryDt: 'Fecha/Hora entrega',
      exploitationDelivery: 'Explotación Destino',
      requestDocument: 'Solicitar Guía',
      modifyDocument: 'Modificar Guía',
      documentHash: 'Hash de la Guía',
      comment: 'Comentario',
      signature: 'Firma',
      showSignature: 'Ver firma',
      hideSignature: 'Esconder firma',
      signatureDt: 'Fecha/Hora firma',
      show: 'Ver',
      signatureHash: 'Hash Firma',
      verify: 'Verificar',
      transaction: 'Transacción',
      explore: 'Explorar',
      sign: 'Firmar',
      SIRCode: 'Código Sirentra',
      cleanNumber: 'Resguardo',
      cleanDt: 'Fecha/Hora Desinfecció',
      transport: 'Medio de transporte',
      logisticCompany: 'Empresa logística',
      transports: {
        truck: 'Camión',
        others: 'Otros'
      },
      time: 'Tiempo',
      waitingResponse: 'Esperando respuesta...',
      confirmDocument: 'Confirmar guía',
      documentQR: {
        importDocument: 'Importar guía'
      },
      documentIdRequired: 'Es necesario solicitar la guia para poder guardar',
      documentSuccessfullyCreated: 'Guía número {{selectedDocumentId}} creada correctamente.',
      documentSuccessfullySaved: 'Guía añadida correctamente.',
      documentSuccessfullySigned: 'Guía firmada y guardada correctamente.',
      errorExternalServerRequest: 'Ha habido un problema con la respuesta de la Generalidad, intentar de nuevo',
      errorDriverRequiredData: 'Es obligatorio seleccionar un transportista con el código ATES y seleccionar la matrícula del transporte',
      requiredFieldsMsg: 'Los campos con * son obligatorios',
      documentSuccessfullyConfirmed: 'Guía número {{selectedDocumentId}} confirmada correctamente. Esta guía se guardará en el histórico',
      wrongSignature: 'La firma no es válida',
      verified: 'Verificado',
      signatureVerified: 'La firma es válida',
      symbols: {
        A: 'Confirmado a la administración',
        B: 'Certificado en la BlockChain',
        S: 'Firmado por el agente'
      },
      deleteDocumentRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Este cambio no se podrá revertir!'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Guía borrada correctamente'
        }
      },
    },
    login: {
      login: 'Login',
      enterPass: 'Password',
      useOtherWallet: 'Use another account',
      invalidPassword: 'Wrong password',
    },
    signUp: {
      signUp: 'Crear una nueva cuenta',
      password: 'Contraseña',
      repeatPassword: 'Repetir contraseña',
      newAccount: 'Nueva Cuenta',
      generate: 'Crear',
      importKeystore: 'Importar clave encriptada',
      importPrivateKey: 'Importar clave privada',
      importMnemonicPhrase: 'Importar frase mnemotécnica',
      keystorePassword: 'Contraseña de la clave encriptada',
      errorMnemonicPhrase: 'Frase mnemotécnica incorrecta',
      errorPrivateKey: 'Llave privada incorrecta',
      errorKeystorePassword: '<b>Error:</b> Contraseña incorrecta. Por favor verifique su contraseña.',
      errorKeystoreUpload: '<b>Error:</b> Necesitas subir el fichero y poner la contraseña.',
      rememberPassword: `<b>Recuerde la contraseña!</b><br>
               esta contraseña cifrará su cartera en un fichero local.<br>
               Con esta contraseña podrá iniciar sesión desencriptando la cartera.`,
      // `<b>Remember your password!</b><br>
      // This password encrypts your address in a keystore file.<br>
      // You will have to use it to log in with your wallet.
      errorRepeatPassword: 'Repite la contraseña correctamente',
      errorEmptyPassword: 'Introduce una contraseña',
    },
    datatable: {
      emptyTable: 'No data available in table',
      info: 'Showing _START_ of _END_ from _TOTAL_ entries',
      infoEmpty: 'No entries',
      infoFiltered: '(filtered from _MAX_ entradas)',
      lengthMenu: 'Showing _MENU_ entries',
      loadingRecords: 'Loading...',
      processing: 'Processing...',
      search: 'Search:',
      zeroRecords: 'No record found',
      paginate: {
        first: 'First',
        last: 'Last',
        next: 'Next',
        previous: 'Previous'
      },
      aria: {
        sortAscending: ': activate to sort the ascending column',
        sortDescending: ': activate to sort the descending column'
      }
    },
    slogan: 'BlockChain for traceability',
    partials: {
      exportQR: 'Export QR code',
      importQR: 'Importar QR code',
      cameraError: 'Unable to access video stream (please make sure you have a webcam enabled)'
      // Unable to access video stream (please make sure you have a webcam enabled)
    },
    auth: {
      identityAlreadyRegistered: 'Este NIF ya tiene una identidad asignada',
      unauthorizedIdentity: 'Este NIF no tiene permiso para crear esta identidad',
      identityCreatedSuccessfully: 'Su identidad ha sido creada'
    },
    chains: {
      chains: 'Chains',
      fields: {
        chain: 'Chain',
        contract: 'Contrato',
        txMaxMinutes: 'Tx. Max min.',
        checkInterval: 'Intervalo subida',
        explorer: 'Explorador',
        server: 'Servidor',
        active: 'Activo',
      },
      newChain: 'Chain nueva',
      alerts: {
        deleteChain: {
          warning: {
            title: 'Estás seguro?',
            text: 'Este cambio no se podrá revertir!'
          },
          deleted: {
            title: 'Borrado!',
            text: 'Chain borrada correctamente'
          }
        },
      }
    },
    generic: {
      actions: 'Actions',
      creationDt: 'Creation date',
      yes: 'Yes',
      no: 'No',
      status: 'Status',
      pending: 'Pending',
      process: 'Process',
      historical: 'Historic',
      congratulations: 'Congratulations',
      successfullySaved: 'Se a guardado correctamente',
      deleteAlert: {
        title: 'Are you sure?',
        text: 'Este cambio no se podrá revertir!'
      },
      deletedAlert: {
        title: 'Deleted!',
        text: 'Se ha borrada correctamente'
      },
      error: 'Error',
      ok: 'OK',
      copied: 'Copied',
      copy: 'Copy',
      info: 'Information',
      create: 'Create',
      deleteAll: 'Delete all',
      form: 'Form'
    }
  }
};
