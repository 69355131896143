import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {AngularFireDatabase} from '@angular/fire/database';
import {DatatableLangService} from '../datatable-lang.service';
import {first, map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import {DocumentInterface} from '../../interfaces/document/document';
import {Observable, Subject} from 'rxjs';
import {DocumentQRService} from './document-qr.service';
import {IdentityService} from '../identity.service';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import {DocumentModel} from '../../models/document.model';
import {DocumentFormService} from './document-form.service';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class DocumentsHistoricService {
    dataTable: any;
    public documents: any = new Observable();
    public documentHistoricList: any = new Observable();

    constructor(
        private db: AngularFireDatabase,
        private datatableLang: DatatableLangService,
        private documentQRService: DocumentQRService,
        private identityService: IdentityService,
        private translate: TranslateService,
        private documentFormService: DocumentFormService
    ) {

                this.documents = this.db.list(
                    'documentsHistoric'
                )
                    .snapshotChanges()
                    .pipe(
                        map(documents => {
                            return documents.reduce((total, current, i) => {
                                total[i] = current.payload.val();
                                total[i].id = current.key;
                                return total;
                            }, []);
                        })
                    );
    }

    renderTable() {
        this.identityService.identity.subscribe((identity: any) => {
            if (identity.type === 'FARM') {
                this.documents = this.db.list(
                    'documentsHistoric',
                    ref => ref.orderByChild('staticData/data/farm/walletAddress').equalTo(identity.walletAddress)
                )
                    .valueChanges();
            } else if (identity.type === 'PROVIDER') {
                this.documents = this.db.list(
                    'documentsHistoric',
                    ref => ref.orderByChild('staticData/data/farm/provider/walletAddress').equalTo(identity.walletAddress)
                )
                    .valueChanges();
            } else if (identity.type === 'SLAUGHTERHOUSE') {
                this.documents = this.db.list(
                    'documentsHistoric',
                    ref => ref.orderByChild('staticData/data/slaughterhouse/walletAddress').equalTo(identity.walletAddress)
                )
                    .valueChanges();
            } else if (identity.type === 'VET') {
                this.documents = this.db.list(
                    'documentsHistoric',
                    ref => ref.orderByChild('vetData/data/vet/walletAddress').equalTo(identity.walletAddress)
                )
                    .valueChanges();
            } else if (identity.type === 'DRIVER') {
                this.documents = this.db.list(
                    'documentsHistoric',
                    ref => ref.orderByChild('driverData/data/driver/walletAddress').equalTo(identity.walletAddress)
                )
                    .valueChanges();
            } else if (identity.type === 'LOGISTIC') {
                this.documents = this.db.list(
                    'documentsHistoric',
                    ref => ref.orderByChild('driverData/data/logistic/walletAddress').equalTo(identity.walletAddress)
                )
                    .valueChanges();
            } else if (identity.type === 'ADMIN') {
                this.documents = this.db.list('documentsHistoric')
                    .valueChanges();
            }

            this.documentHistoricList = this.documents.pipe(
                map(documents => {
                    for (let i = 0; i < Object.keys(documents).length; i++) {
                        const document: DocumentInterface = documents[i];
                        let time: string;
                        if (document.dynamicData.time) {
                            const timeInMinutes = document.dynamicData.time;
                            time = this.twoDigit(Math.floor(timeInMinutes / 60)) + ':' + this.twoDigit(timeInMinutes % 60);
                        }

                        documents[i] = {
                            id: getVal(document, 'staticData.data.documentId') || null,
                            datetime: getVal(document, 'staticData.data.departureDt') || null,
                            farm: getVal(document, 'staticData.data.farm.fiscalName') || null,
                            farmTxDt: getVal(document, 'farmData.signature.txDt') || null,
                            farmTx: getVal(document, 'farmData.signature.tx') || null,
                            farmSignature: getVal(document, 'farmData.signature.signature') || null,
                            farmSignatureDt: getVal(document, 'farmData.data.signatureDt') || null,
                            vet: getVal(document, 'vetData.data.vet.fiscalName') || null,
                            vetTxDt: getVal(document, 'vetData.signature.txDt') || null,
                            vetTx: getVal(document, 'vetData.signature.tx') || null,
                            vetSignature: getVal(document, 'vetData.signature.signature') || null,
                            vetSignatureDt: getVal(document, 'vetData.data.signatureDt') || null,
                            driver: getVal(document, 'driverData.data.driver.fiscalName') || null,
                            driverTxDt: getVal(document, 'driverData.signature.txDt') || null,
                            driverTx: getVal(document, 'driverData.signature.tx') || null,
                            driverSignature: getVal(document, 'driverData.signature.signature') || null,
                            driverSignatureDt: getVal(document, 'driverData.data.signatureDt') || null,
                            slaughterhouse: getVal(document, 'staticData.data.slaughterhouse.fiscalName') || null,
                            slaughterhouseTxDt: getVal(document, 'slaughterhouseData.signature.txDt') || null,
                            slaughterhouseTx: getVal(document, 'slaughterhouseData.signature.tx') || null,
                            slaughterhouseSignature: getVal(document, 'slaughterhouseData.signature.signature') || null,
                            slaughterhouseSignatureDt: getVal(document, 'slaughterhouseData.data.signatureDt') || null,
                            confirmedDt: getVal(document, 'dynamicData.confirmedDt') || null,
                            time
                        };
                    }
                    return documents;
                })
            );

            this.documentHistoricList.subscribe(documents => {
                const table: any = $('#documents-historic-table');
                this.dataTable = table.DataTable({
                    destroy: true,
                    scrollX: true,
                    language: this.datatableLang.lang,
                    lengthMenu: [5, 10, 25, 50],
                    pageLength: 10,
                    order: [[2, 'desc']],
                    data: documents,
                    columns: [
                        {data: 'id', width: '50px'},
                        {data: 'id', defaultContent: ''},
                        {data: 'datetime', defaultContent: ''},
                        {data: 'farm', defaultContent: ''},
                        {data: 'vet', defaultContent: ''},
                        {data: 'driver', defaultContent: ''},
                        {data: 'slaughterhouse', defaultContent: ''},
                        {data: 'time', defaultContent: ''}
                    ],
                    columnDefs: [
                        {
                            targets: 0,
                            data: 'id',
                            title: 'Actions',
                            orderable: false,
                            render: (id) => {
                                // language=HTML
                                return `
                            <button document-id="${id}" type="button" class="edit-doc-historic-btn btn btn-primary"
                                data-toggle="modal" data-target="#new-document-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            ${identity.type === 'ADMIN' ? `<button document-id="${id}" type="button" class="del-doc-historic-btn btn btn-danger">
                                <i class="fas fa-times"></i>
                            </button>` : ''}
                            <a href="/document/${id}" target="_blank"><button type="button" class="btn btn-warning">
                                <i class="fas fa-print"></i>
                            </button></a>
                            <button document-id="${id}" type="button" class="qr-doc-btn btn btn-secondary"
                                data-toggle="modal" data-target="#document-qr-modal">
                                <i class="fas fa-qrcode"></i>
                            </button>
                            `;
                            },
                        },
                        {
                            targets: 2,
                            data: 'datetime',
                            render: (datetime) => {
                                // language=HTML
                                return datetime ? `
                                <i class="far fa-calendar-alt"></i> ${moment(datetime, 'x').format('DD/MM/YYYY')}
                                <i class="far fa-clock"></i> ${moment(datetime, 'x').format('HH:mm')}
                            ` : '';
                            },
                        },
                        {
                            targets: 3,
                            data: 'farm',
                            render: (farm, type, full) => {
                                // language=HTML
                                return farm ? `
                                <div class="indicators">
                                    ${full.farmSignature ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.farmSignatureDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                    class="badge badge-pill badge-success">S</span>` : '<span class="badge badge-pill badge-secondary">S</span>'}
                                    ${full.farmTx ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.farmTxDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                     class="badge badge-pill badge-success">B</span>` : '<span class="badge badge-pill badge-secondary">B</span>'}
                                </div>
                                ${farm}
                            ` : '';
                            },
                        },
                        {
                            targets: 4,
                            data: 'vet',
                            render: (vet, type, full) => {
                                // language=HTML
                                return vet ? `
                                <div class="indicators">
                                    ${full.vetSignature ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.vetSignatureDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                    class="badge badge-pill badge-success">S</span>` : '<span class="badge badge-pill badge-secondary">S</span>'}
                                    ${full.vetTx ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.vetTxDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                     class="badge badge-pill badge-success">B</span>` : '<span class="badge badge-pill badge-secondary">B</span>'}
                                </div>
                                ${vet}
                            ` : '';
                            },
                        },
                        {
                            targets: 5,
                            data: 'driver',
                            render: (driver, type, full) => {
                                // language=HTML
                                return driver ? `
                                 <div class="indicators">
                                    ${full.driverSignature ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.driverSignatureDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                    class="badge badge-pill badge-success">S</span>` : '<span class="badge badge-pill badge-secondary">S</span>'}
                                    ${full.driverTx ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.driverTxDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                     class="badge badge-pill badge-success">B</span>` : '<span class="badge badge-pill badge-secondary">B</span>'}
                                </div>
                                ${driver}
                            ` : '';
                            },
                        },
                        {
                            targets: 6,
                            data: 'slaughterhouse',
                            render: (slaughterhouse, type, full) => {
                                // language=HTML
                                return slaughterhouse ? `
                               <div class="indicators">
                                    ${full.slaughterhouseSignature ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.slaughterhouseSignatureDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                    class="badge badge-pill badge-success">S</span>` : '<span class="badge badge-pill badge-secondary">S</span>'}
                                    ${full.slaughterhouseTx ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.slaughterhouseTxDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                     class="badge badge-pill badge-success">B</span>` : '<span class="badge badge-pill badge-secondary">B</span>'}
                                    ${full.confirmedDt ?
                                    `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full.confirmedDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                     class="badge badge-pill badge-success">A</span>` : '<span class="badge badge-pill badge-secondary">A</span>'}
                                </div>
                                ${slaughterhouse}
                            ` : '';
                            },
                        }
                    ],
                    drawCallback: () => {
                        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
                    }
                });
            });
        });

        $(document).on('click', '.edit-doc-historic-btn', (e) => {
            const id = $(e.currentTarget).attr('document-id');
            console.log('DOCUMENTS!!!!!!!');
            console.log(id);
            this.documentFormService.loadDocument(id, true);
        });


        $(document).on('click', '.qr-doc-btn', (e) => {
            const documentId = $(e.currentTarget).attr('document-id');
            this.documentQRService.documentId$.next(documentId);
        });

        $(document).on('click', '.del-doc-historic-btn', (e) => {
            const id = $(e.currentTarget).attr('document-id');
            this.delDocumentHistoric(id);
        });
    }


    delDocumentHistoric(documentId) {
        if (documentId) {
            this.translate.get([
                'generic.yes',
                'generic.no',
                'documents.deleteDocumentRequest.warning.title',
                'documents.deleteDocumentRequest.warning.text',
                'documents.deleteDocumentRequest.deleted.title',
                'documents.deleteDocumentRequest.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['documents.deleteDocumentRequest.warning.title'],
                    text: trans['documents.deleteDocumentRequest.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.list('documents/' + documentId).snapshotChanges()
                            .pipe(map(items => {
                                    return items.reduce((total, current) => {
                                        const value = current.payload.val();
                                        const key = current.payload.key;
                                        total[key] = value;
                                        return total;
                                    }, {});
                                })
                            ).subscribe((document) => {
                            this.db.object(`deletedDocumentsHistoric/${documentId}`).set(document)
                                .then(() => {
                                    this.db.object(`documentsHistoric/${documentId}`).remove()
                                        .then(() => {
                                            Swal.fire(
                                                trans['documents.deleteDocumentRequest.deleted.title'],
                                                trans['documents.deleteDocumentRequest.deleted.text'],
                                                'success'
                                            );
                                        });
                                });
                        });

                    }
                });
            });
        }
    }

    twoDigit(value: any) {
        const num = Math.trunc(Number(value));
        return num < 10 ? '0' + num : num;
    }
}
