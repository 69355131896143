import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentInterface} from '../../../core/interfaces/document/document';
import {map} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';
import {DocumentModel} from '../../../core/models/document.model';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
    document: DocumentInterface = new DocumentModel();
    domain: string;

    constructor(
        private route: ActivatedRoute,
        private db: AngularFireDatabase
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.db.list('documents/' + params.documentId).snapshotChanges()
                .pipe(map(items => {
                        return items.reduce((total, current) => {
                            const value = current.payload.val();
                            const key = current.payload.key;
                            total[key] = value;
                            return total;
                        }, {});
                    })
                )
                .subscribe((document: DocumentInterface) => {
                    console.log(document, 'docuemnt');
                    if (Object.keys(document).length !== 0) {
                        console.log( 'yes');
                        this.document = new DocumentModel(document);
                    } else {
                        this.db.list('documentsHistoric/' + params.documentId).snapshotChanges()
                            .pipe(map(items => {
                                    return items.reduce((total, current) => {
                                        const value = current.payload.val();
                                        const key = current.payload.key;
                                        total[key] = value;
                                        return total;
                                    }, {});
                                })
                            )
                            .subscribe((documentHistoric: DocumentInterface) => {
                                this.document = new DocumentModel(documentHistoric);
                            });
                    }

                });
        });
        this.domain = location.origin;
    }

}
