import {AdditionalFieldsInterface, ProductInterface} from '../interfaces/lot/product.interface';
import {ExternalLinkInterface} from '../interfaces/external-link.interface';
import { FileInterface } from '../interfaces/file.interface';

export class ProductModel {
  id: string;
  ean13: string;
  name: string;
  description: string;
  url: string;
  img: FileInterface;
  externalLinks: ExternalLinkInterface[];
  geolocation: string;
  additionalFields: AdditionalFieldsInterface;
  additionalInformation: any[];


  constructor(product: ProductInterface | any) {
    this.id = product.id || '';
    this.ean13 = product.ean13 || '';
    this.name = product.name || '';
    this.description = product.description || '';
    this.url = product.url || '';
    this.img = product.img || {};
    this.externalLinks = product.externalLinks || [];
    this.geolocation = product.geolocation || '';
    this.additionalFields = product.additionalFields || {sections: []};
    this.additionalInformation = product.additionalInformation || [];
  }

}



