import {getVal} from '../../utils/getNestedObject';
import {VetInterface} from '../../interfaces/identity/vet.interface';

export class VetModel {
    id: string;
    fiscalName: string;
    commercialName: string;
    collegeNumber: string;
    email: string;
    mobile: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
    country: string;
    token: string;
    type: 'VET';
    walletAddress: string;
    delegatedGas: boolean;
    active: boolean;

    constructor(
        vet?: VetInterface
    ) {
        this.id = getVal(vet, 'id') || '';
        this.fiscalName = getVal(vet, 'fiscalName') || '';
        this.fiscalName = getVal(vet, 'fiscalName') || '';
        this.commercialName = getVal(vet, 'commercialName') || '';
        this.collegeNumber = getVal(vet, 'collegeNumber') || '';
        this.email = getVal(vet, 'email') || '';
        this.mobile = getVal(vet, 'mobile') || '';
        this.phone = getVal(vet, 'phone') || '';
        this.address = getVal(vet, 'address') || '';
        this.city = getVal(vet, 'city') || '';
        this.state = getVal(vet, 'state') || '';
        this.province = getVal(vet, 'province') || '';
        this.postalCode = getVal(vet, 'postalCode') || '';
        this.country = getVal(vet, 'country') || '';
        this.token = getVal(vet, 'token') || '';
        this.type = getVal(vet, 'type') || '';
        this.walletAddress = getVal(vet, 'walletAddress') || '';
        this.delegatedGas = getVal(vet, 'delegatedGas') || true;
        this.active = getVal(vet, 'active') || true;
    }
}
