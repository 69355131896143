import {FileInterface} from '../interfaces/file.interface';

export class FileModel {
   name: string;
  url: string;
  path?: string;
  size?: string;
  type?: string;


 constructor(file: FileInterface | any) {

    this.name = file.name || '';
    this.url = file.url || '';
    this.path = file.path || '';
    this.size = file.size || '';
    this.type = file.type || '';

  }


}
