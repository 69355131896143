import {Component, OnInit} from '@angular/core';
import {ModifyIdentityFormService} from '../../../../core/services/identities/modify-identity-form.service';

@Component({
    selector: 'app-identity-form',
    templateUrl: './identity-form.component.html',
    styleUrls: ['./identity-form.component.css']
})
export class IdentityFormComponent implements OnInit {
    constructor(
        public modifyIdentityFormService: ModifyIdentityFormService
    ) {
    }

    ngOnInit() {
    }

    saveIdentity() {
        this.modifyIdentityFormService.saveIdentity();
    }

}
