import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../../core/services/authentication.service";
import {WalletService} from '../../../../core/services/wallet.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-import-wallet',
    templateUrl: './import-wallet.component.html',
    styleUrls: ['./import-wallet.component.css']
})
export class ImportWalletComponent implements OnInit {
    file: any;
    keystorePassword: string;

    keystorePasswordErr: boolean;
    fileErr: boolean;
    importKeystoreErrText: string;

    importBtn = {
        loading: false
    };

    constructor(
        private authService: AuthenticationService,
        private walletService: WalletService,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
    }

    reset(): void {
        this.file = '';
        this.keystorePassword = '';
        this.fileErr = false;
        this.keystorePasswordErr = false;
        this.importKeystoreErrText = '';
    }

    onFileChange(e) {
        this.file = e.target.files[0];
    }

    login() {
        if (this.file && this.keystorePassword) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                this.importBtn.loading = true;
                const wallet = await this.walletService.importKeystoreWallet(reader.result, this.keystorePassword);
                if (wallet) {
                    $('#import-wallet-modal').modal('hide');
                    this.authService.login();
                } else {
                    this.keystorePasswordErr = true;
                    this.translateService.get('errorKeystorePassword').subscribe((errorKeystorePassword) => {
                        this.importKeystoreErrText = errorKeystorePassword;
                    });
                }
                this.importBtn.loading = false;
            };
            reader.readAsBinaryString(this.file);
        } else {
            this.keystorePasswordErr = true;
            this.fileErr = true;
            this.translateService.get('errorKeystoreUpload').subscribe((errorKeystoreUpload) => {
                this.importKeystoreErrText = errorKeystoreUpload;
            });
        }
    }

}
