import { Component, OnInit } from '@angular/core';
import {IdentityService} from '../../../../core/services/identity.service';
import {SlaughterhouseInterface} from '../../../../core/interfaces/identity/slaughterhouse.interface';
import {SlaughterhouseModel} from 'src/app/core/models/identity/slaughterhouse.model';

@Component({
  selector: 'app-slaughterhouse-form',
  templateUrl: './slaughterhouse-form.component.html',
  styleUrls: ['./slaughterhouse-form.component.css']
})
export class SlaughterhouseFormComponent implements OnInit {

  slaughterhouse: SlaughterhouseInterface = new SlaughterhouseModel();

  constructor(
      private identityService: IdentityService
  ) {
  }

  ngOnInit() {
    this.identityService.identity.subscribe((slaughterhouse: SlaughterhouseInterface) => {
      this.slaughterhouse = new SlaughterhouseModel(slaughterhouse);
    });
  }

  saveIdentity() {
    this.identityService.saveIdentity(this.slaughterhouse);
  }

}
