import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LotsService} from 'src/app/core/services/lots/lots.service';
import {LotInterface} from '../../../../core/interfaces/lot/lot.interface';

@Component({
  selector: 'app-lot-modal',
  templateUrl: './lot-modal.component.html',
  styleUrls: ['./lot-modal.component.css']
})
export class LotModalComponent implements OnInit {
  lot: LotInterface;
  lots: LotInterface[];
  lotQuantity: number;
  lotWeight: number;
  lotWeightUnit = 'kg';

  constructor(
    public activeModal: NgbActiveModal,
    public lotsService: LotsService,
  ) {
  }

  ngOnInit(): void {
    this.lotsService.lots.subscribe((lots: LotInterface[]) => this.lots = lots);
  }


  save() {
    this.activeModal.close({
      ...this.lot,
      lotQuantity: this.lotQuantity,
      lotWeight: this.lotWeight,
      lotWeightUnit: this.lotWeightUnit
    });
  }
}
