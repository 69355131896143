import {Component, OnInit} from '@angular/core';
import {LotFormService} from '../../../../core/services/lots/lot-form.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {LotInterface} from '../../../../core/interfaces/lot/lot.interface';
import {ProcessesService} from '../../../../core/services/processes/processes.service';
import {ProcessInterface} from '../../../../core/interfaces/lot/process.interface';
import {ProductInterface} from '../../../../core/interfaces/lot/product.interface';
import {ProductsService} from '../../../../core/services/products/products.service';
import {LotModalComponent} from '../lot-modal/lot-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RawMaterialModalComponent} from '../raw-material-modal/raw-material-modal.component';
import {DocumentModalComponent} from '../document-modal/document-modal.component';
import {ExternalLinkModalComponent} from '../../external-link-modal/external-link-modal.component';
import {IdentityService} from '../../../../core/services/identity.service';
import {CustomersService} from '../../../../core/services/customers.service';
import {CustomerInterface} from '../../../../core/interfaces/identity/customer.interface';
import {ProviderInterface} from '../../../../core/interfaces/identity/provider.interface';
import {ProvidersService} from '../../../../core/services/providers.service';
import {ProductModalComponent} from '../product-modal/product-modal.component';
import {ProductModel} from '../../../../core/models/product.model';
import {LotTemplateInterface} from '../../../../core/interfaces/lot/lot-template.interface';
import {LotsTemplatesService} from '../../../../core/services/lots-templates/lots-templates.service';
import {AngularFireStorage} from '@angular/fire/storage';


@Component({
  selector: 'app-lot-form',
  templateUrl: './lot-form.component.html',
  styleUrls: ['./lot-form.component.css']
})
export class LotFormComponent implements OnInit {
  dtOptions = {
    format: 'DD/MM/YYYY HH:mm',
    icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'far fa-calendar-check',
      clear: 'fas fa-trash',
      close: 'fas fa-times'
    },
    locale: 'ca'
  };
  identity: any;
  processes: ProcessInterface[];
  customers: CustomerInterface[];
  providers: ProviderInterface[];
  provider: ProviderInterface;
  product: ProductInterface;
  products: ProductInterface[];
  lotsTemplates: LotTemplateInterface[];
  lots: LotInterface[];
  lotProducts: any[] = [];
  startNumber: number;
  endNumber: number;
  maxIntervalProducts = 10000;

  constructor(
    public formService: LotFormService,
    public processesService: ProcessesService,
    public productsService: ProductsService,
    public lotsTemplatesService: LotsTemplatesService,
    public identityService: IdentityService,
    private customersService: CustomersService,
    private providersService: ProvidersService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private storage: AngularFireStorage
  ) {
    this.identityService.identity.subscribe((identity) => this.identity = identity);
    this.customersService.customers.subscribe((customers) => this.customers = customers);
    this.providersService.providers.subscribe((providers) => this.providers = providers);
    this.processesService.processes.subscribe((processes: ProcessInterface[]) => this.processes = processes);
    this.productsService.products.subscribe((products: ProductInterface[]) => {
      // console.log(this.formService.lot.product,'this.formService.lot.product')
      console.log(products[0], 'products[0]')
      this.products = products;
    });
    this.lotsTemplatesService.lotsTemplates.subscribe((lotsTemplates: LotTemplateInterface[]) => {
      // console.log(this.formService.lot.product,'this.formService.lot.product')
      console.log(lotsTemplates[0], 'lotsTemplates[0]')
      this.lotsTemplates = lotsTemplates
    });
  }

  ngOnInit() {

  }


  async saveLot() {
    console.log(this.formService.lot, 'SAVE this.formService.lot')
    await this.formService.saveLot();
    if (this.formService.lot.id) {
      this.translate.get([
        'generic.congratulations',
        'generic.successfullySaved',
      ]).subscribe((trans: any) => {
        Swal.fire({
          icon: 'success',
          title: trans['generic.congratulations'],
          text: trans['generic.successfullySaved'],
          timer: 2000
        });
      });
    }

  }

  ngChangeDt(e) {
    this.formService.lot.expiration = (e && typeof e.unix === 'function' ? e.unix() * 1000 : this.formService.lot.expiration);
  }

  sameLotTemplateId(lotTemplate1: LotTemplateInterface, lotTemplate2: LotTemplateInterface) {
    return lotTemplate1?.id === lotTemplate2?.id
  }

  sameProcessId(process1: ProcessInterface, process2: ProcessInterface) {
    return process1?.id === process2?.id
  }

  sameCustomerId(customer1: CustomerInterface, customer2: CustomerInterface) {
    console.log('----------')
    console.log(customer1?.id, 'customer1' + ' ' + customer1?.commercialName)
    console.log(customer2?.id, 'customer2' + ' ' + customer2?.commercialName)
    return customer1?.id === customer2?.id
  }

  addNewRawMaterial() {
    const modalRef = this.modalService.open(RawMaterialModalComponent, {size: 'lg', scrollable: true});
    modalRef.result.then((rawMaterial) => {
      this.formService.lot.rawMaterials.push(rawMaterial);
    })
  }

  addNewLot() {
    const modalRef = this.modalService.open(LotModalComponent, {size: 'lg', scrollable: true});
    modalRef.result.then((lot) => {
      this.formService.lot.lots.push(lot);
    })
  }

  delRawMaterial(i: number) {
    this.formService.lot.rawMaterials.splice(i, 1);
  }

  delLot(i: number) {
    this.formService.lot.lots.splice(i, 1);
  }

  addNewDocument() {
    const modalRef = this.modalService.open(DocumentModalComponent, {size: 'lg', scrollable: true});
    modalRef.result.then((document) => {
      this.formService.lot.documents.push(document);
    })
  }

  delDocument(i: number) {
    this.formService.lot.documents.splice(i, 1);
  }


  delInterval(i: number) {
    this.formService.lot.quantity -= this.formService.lot.intervals[i].quantity;
    this.formService.lot.intervals.splice(i, 1);
  }


  async createLotProducts() {
    const start = this.startNumber <= this.endNumber ? this.startNumber : this.endNumber;
    const end = this.endNumber > this.startNumber ? this.endNumber : this.startNumber;

    if(end - start > this.maxIntervalProducts){
      Swal.fire({
          icon: 'error',
          title: 'error',
          html: 'You are trying to add '+(end - start)+' products in an interval. <br>You can add maximum '+this.maxIntervalProducts+' products in an interval'
        });
    } else {
      if (await this.formService.isIntervalAllowed(start, end)) {
      const quantity = end + 1 - start;
      this.formService.lot.intervals.push({start, end, quantity, provider: this.provider, product: this.product});
      this.formService.lot.quantity += quantity;
    } else {
      this.translate.get([
        'lots.productIdAlreadyAddedAlert.title',
        'lots.productIdAlreadyAddedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          icon: 'error',
          title: trans['lots.productIdAlreadyAddedAlert.title'],
          text: trans['lots.productIdAlreadyAddedAlert.text']
        });
      });
    }
    }


  }

  delLotProducts() {
    this.formService.lot.intervals = [];
    this.formService.lot.quantity = 0;
  }

  addNewExternalLink() {
    const modalRef = this.modalService.open(ExternalLinkModalComponent, {scrollable: true});
    modalRef.result.then((externalLink) => {
      this.formService.lot.externalLinks.push(externalLink);
    })
  }

  delExternalLink(i: number) {
    this.formService.lot.externalLinks.splice(i, 1);
  }


  lotTemplateChanged() {
    console.log(this.formService.lot.lotTemplate, 'this.formService.lot.lotTemplate')
    for (const section of this.formService.lot.lotTemplate?.additionalFields?.sections) {
      for (const field of section.fields) {
        this.formService.lot.additionalInformation[field.fieldName] = field.defaultValue;
      }
    }
  }

  editIntervalProduct(i: number) {
    const modalRef = this.modalService.open(ProductModalComponent, {scrollable: true});
    modalRef.componentInstance.product = new ProductModel(this.formService.lot.intervals[i].product);
    modalRef.result.then((product) => {
      this.formService.lot.intervals[i].product = product;
    }).catch(() => {
      console.log('CLOSED')
    })
    // this.formService.lot.intervals[i].product;

  }
}
