import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from './documents/document-form.service';
import {TranslateService} from '@ngx-translate/core';
import {WalletService} from './wallet.service';

@Injectable({
    providedIn: 'root'
})
export class IdentityService {
    public identity: Observable<any>;
    public providersRequests: Observable<any>;
    public logisticsRequests: Observable<any>;

    constructor(
        private db: AngularFireDatabase,
        private translate: TranslateService
    ) {
    }

    saveIdentity(identity: any) {
        this.db.object(`identity/${identity.walletAddress}`).update(identity).then(() => {
            this.translate.get(['form.saveTitle', 'form.saveMessage']).subscribe((translate: any) => {
                Swal.fire({
                    icon: 'success',
                    title: translate['form.saveTitle'],
                    text: translate['form.saveMessage']
                });
            });

        });
    }

    getProvidersRequest(identity) {
        return this.providersRequests = this.db.list('farmPendingProviders/' + identity.walletAddress)
            .snapshotChanges()
            .pipe(
                map(providerPendingFarms => {
                    return providerPendingFarms.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].key = current.key;
                        return total;
                    }, []);
                })
            );
    }

    delProviderRequest(key: string) {
        this.translate.get([
            'generic.yes',
            'generic.no',
            'identity.deleteProviderRequest.warning.title',
            'identity.deleteProviderRequest.warning.text',
            'identity.deleteProviderRequest.deleted.title',
            'identity.deleteProviderRequest.deleted.text',
        ]).subscribe((trans: any) => {
            Swal.fire({
                title: trans['identity.deleteProviderRequest.warning.title'],
                text: trans['identity.deleteProviderRequest.warning.text'],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: trans['generic.yes'],
                cancelButtonText: trans['generic.no']
            }).then((result) => {
                if (result.isConfirmed) {
                    this.identity.subscribe((identity: any) => {
                        this.db.object(`farmPendingProvider/${identity.walletAddress}/${key}`).remove()
                            .then(() => {
                                this.db.object(`providerPendingFarms/${key}/${identity.walletAddress}`).remove()
                                    .then(() => {
                                        Swal.fire(
                                            trans['identity.deleteProviderRequest.deleted.title'],
                                            trans['identity.deleteProviderRequest.deleted.text'],
                                            'success'
                                        );
                                    });
                            });
                    });
                }
            });
        });
    }

    acceptProviderRequest(providerRequest: any) {
        const provider = {...providerRequest};
        delete provider.key;
        this.identity.pipe(
            first()
        ).subscribe((identity) => {
            this.db.object(`identity/${identity.walletAddress}/provider`).set(provider);
            this.db.object(`identity/${providerRequest.walletAddress}/farms/${identity.walletAddress}`).set(identity);
            this.db.object(`farmPendingProviders/${identity.walletAddress}/${providerRequest.key}`).remove();
            this.db.object(`providerPendingFarms/${providerRequest.key}/${identity.walletAddress}`).remove();
        });
    }

    delProvider(providerWalletAddress) {
        this.identity.pipe(
            first()
        ).subscribe((identity) => {
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteProvider.warning.title',
                'identity.deleteProvider.warning.text',
                'identity.deleteProvider.deleted.title',
                'identity.deleteProvider.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteProvider.warning.title'],
                    text: trans['identity.deleteProvider.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.object(`identity/${providerWalletAddress}/farms/${identity.walletAddress}`).remove().then(() => {
                            this.db.object(`identity/${identity.walletAddress}/provider`).remove()
                                .then(() => {
                                    Swal.fire(
                                        trans['identity.deleteProvider.deleted.title'],
                                        trans['identity.deleteProvider.deleted.text'],
                                        'success'
                                    );
                                });
                        });
                    }
                });
            });
        });
    }

    getLogisticsRequest(identity) {
        return this.logisticsRequests = this.db.list('logisticPendingDrivers', ref => ref.orderByChild('walletAddress').equalTo(identity.walletAddress))
            .snapshotChanges()
            .pipe(
                map(logisticPendingDrivers => {
                    return logisticPendingDrivers.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].key = current.key;
                        return total;
                    }, []);
                })
            );
    }

    delLogisticRequest(key: string) {
        this.translate.get([
            'generic.yes',
            'generic.no',
            'identity.deleteLogisticRequest.warning.title',
            'identity.deleteLogisticRequest.warning.text',
            'identity.deleteLogisticRequest.deleted.title',
            'identity.deleteLogisticRequest.deleted.text',
        ]).subscribe((trans: any) => {
            Swal.fire({
                title: trans['identity.deleteLogisticRequest.warning.title'],
                text: trans['identity.deleteLogisticRequest.warning.text'],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: trans['generic.yes'],
                cancelButtonText: trans['generic.no']
            }).then((result) => {
                if (result.isConfirmed) {
                    this.db.object('logisticPendingDrivers/' + key).remove()
                        .then(() => {
                            Swal.fire(
                                trans['identity.deleteLogisticRequest.deleted.title'],
                                trans['identity.deleteLogisticRequest.deleted.text'],
                                'success'
                            );
                        });
                }
            });
        });
    }

    acceptLogisticRequest(logisticRequest: any) {
        const logistic = {...logisticRequest};
        delete logistic.key;
        this.identity.pipe(
            first()
        ).subscribe((identity) => {
            this.db.object(`identity/${identity.walletAddress}/logistic`).set(logistic);
            this.db.object(`identity/${logisticRequest.walletAddress}/drivers/${identity.walletAddress}`).set(identity);
            this.db.object(`logisticPendingDrivers/${logisticRequest.key}`).remove();
        });
    }


    delLogistic(logisticWalletAddress) {
        this.identity.pipe(
            first()
        ).subscribe((identity) => {
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteLogistic.warning.title',
                'identity.deleteLogistic.warning.text',
                'identity.deleteLogistic.deleted.title',
                'identity.deleteLogistic.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteLogistic.warning.title'],
                    text: trans['identity.deleteLogistic.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.object(`identity/${logisticWalletAddress}/drivers/${identity.walletAddress}`).remove().then(() => {
                            this.db.object(`identity/${identity.walletAddress}/logistic`).remove()
                                .then(() => {
                                    Swal.fire(
                                        trans['identity.deleteLogistic.deleted.title'],
                                        trans['identity.deleteLogistic.deleted.text'],
                                        'success'
                                    );
                                });
                        });
                    }
                });
            });
        });
    }

    addTransport(transportCode: string) {
        this.identity.pipe(
            first()
        ).subscribe((identity) => {
            this.db.object(`identity/${identity.walletAddress}/transports/${transportCode}`).set('true');
        });
    }

    delTransport(transportCode: string) {
        this.identity.pipe(
            first()
        ).subscribe((identity) => {
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteTransport.warning.title',
                'identity.deleteTransport.warning.text',
                'identity.deleteTransport.deleted.title',
                'identity.deleteTransport.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteTransport.warning.title'],
                    text: trans['identity.deleteTransport.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.object(`identity/${identity.walletAddress}/transports/${transportCode}`).remove().then(() => {
                            Swal.fire(
                                trans['identity.deleteTransport.deleted.title'],
                                trans['identity.deleteTransport.deleted.text'],
                                'success'
                            );
                        });
                    }
                });
            });
        });
    }

}
