import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {WalletService} from '../../core/services/wallet.service';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

    constructor(
        private walletService: WalletService,
        private router: Router
    ) {
        if (!walletService.wallet) {
            router.navigate(['/login']);
        }
    }

    ngOnInit() {
    }

}
