import {Injectable, ChangeDetectorRef} from '@angular/core';
import {LotsService} from './lots.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {LotInterface} from '../../interfaces/lot/lot.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {LotModel} from '../../models/lot.model';
import {HashService} from '../hash.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LotFormService {
  lot: LotModel = new LotModel({});
  oldLot: LotModel = new LotModel({});
  selectedId: string;
  expiration: string;
  date: string;

  constructor(
    private lotsService: LotsService,
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    public clipboardService: ClipboardService,
    private http: HttpClient,
    private hashService: HashService,
    private translate: TranslateService
  ) {


    $(document).on('click', '.edit-lot-btn', (e) => {
      const id = $(e.currentTarget).attr('lot-id');
      console.log(id, 'id');
      this.loadLot(id);
    });


    $(document).on('click', '.blockchain-lot-btn', (e) => {
      const id = $(e.currentTarget).attr('lot-id');
      this.lotToBlockchain(id);
    });

    $(document).on('click', '.del-lot-btn', (e) => {
      const id = $(e.currentTarget).attr('lot-id');
      this.delLot(id);
    });
  }

  async saveLot(close: boolean = true) {
    console.log(this.lot, 'this.lot')
    console.log(this.oldLot, 'this.oldLot')
    // if (this.lot.id) {
    await this.lotsService.saveLot(this.lot, this.oldLot);
    if (close) {
      $('#new-lot-modal').modal('hide');
    }
    // } else {
    //   this.translate.get([
    //     'generic.error',
    //     'lots.lotIdRequired'
    //   ]).subscribe((trans: any) => {
    //     Swal.fire({
    //       icon: 'error',
    //       title: trans['generic.error'],
    //       html: trans['lots.lotIdRequired']
    //     });
    //   });
    // }
  }

  lotToBlockchain(lotId) {
    if (lotId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'lots.uploadToBlockchainAlert.title',
        'lots.uploadToBlockchainAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['lots.uploadToBlockchainAlert.title'],
          text: trans['lots.uploadToBlockchainAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.lotsService.getLot(lotId)
              .pipe(
                first()
              )
              .subscribe((lot: LotInterface) => {
                const data = JSON.stringify(lot);

                this.hashService.setHash(data).then((hashInfo: any) => {
                  console.log(hashInfo, 'hashInfo')
                  this.db.object(`lots/${lotId}/blockchain`).update({...hashInfo, data})
                    .then(() => {

                    });
                })


              });

            // const maximum = 30;
            // const minimum = 15;
            // const randomnumber = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
            // setTimeout(()=>{
            //   this.db.object(`lots/${lotId}/blockchain/txDt`).set(Number(moment().format('x')))
            //   .then(() => {
            //   });
            // },randomnumber*1000)

          }
        });
      });
    }
  }

  loadLot(id) {
    console.log('LOAD LOT LOT-FORM')
    this.lot = new LotModel({});
    this.oldLot = new LotModel({});

    this.lotsService.getLot(id)
      .pipe(
        first()
      )
      .subscribe((lot: LotInterface) => {

        console.log(lot, 'lot1');
        this.selectedId = lot.id;
        this.lot = new LotModel(lot);
        console.log(this.lot, 'this.lot');
        this.expiration = moment(this.lot.expiration, 'x').format('DD/MM/YYYY HH:mm');
        this.date = moment(this.lot.date, 'x').format('DD/MM/YYYY HH:mm');
        this.oldLot = new LotModel(JSON.parse(JSON.stringify(lot)));
      });

  }

  delLot(lotId) {
    console.log(lotId, 'delLot')
    if (lotId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'generic.deleteAlert.title',
        'generic.deleteAlert.text',
        'generic.deletedAlert.title',
        'generic.deletedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['generic.deleteAlert.title'],
          text: trans['generic.deleteAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.lotsService.getLot(lotId)
              .pipe(
                first()
              )
              .subscribe((lot: LotInterface) => {

                for (const interval of lot.intervals) {
                  this.delProductIdLotInterval(interval.start, interval.end)
                }

                this.db.object(`lots/${lotId}`).remove()
                  .then(() => {
                    Swal.fire(
                      trans['generic.deletedAlert.title'],
                      trans['generic.deletedAlert.text'],
                      'success'
                    );
                  });
              });


          }
        });
      });
    }
  }

  isIntervalAllowed(start: number, end: number) {
    return this.lotsService.isIntervalAllowed(start, end)
  }

  delProductIdLotInterval(start: number, end: number) {
    return this.lotsService.delProductIdLotInterval(start, end);
  }

  newLot() {
    this.lot = new LotModel({});
    this.selectedId = null;
  }

}
