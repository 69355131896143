import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import {map, first} from 'rxjs/operators';
import {IdentityService} from './identity.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClient} from '@angular/common/http';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public onUserRolesUpdated$: Subject<any>;
  public identity: any;
  public wallet: any;
  public decodePass: string;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFireDatabase,
    private identityService: IdentityService,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    this.onUserRolesUpdated$ = new Subject();
  }

  public getUserRoles(): Observable<any> {
    const roles: any = localStorage.getItem('userRoles');
    try {
      return of(JSON.parse(roles));
    } catch (e) {
    }
  }

  public setUserRoles(roles: any): any {
    if (roles != null) {
      localStorage.setItem('userRoles', JSON.stringify(roles));
      this.onUserRolesUpdated$.next(roles);
    }
    return this;
  }

  public clear() {
    localStorage.removeItem('userRoles');
    this.onUserRolesUpdated$.next('');
  }

  public login() {
    return new Promise((resolve, reject) => {
      const getCodeUrl = 'https://us-central1-porkchain-3cb1e.cloudfunctions.net/requestCode';
      this.http.get(getCodeUrl, {responseType: 'text'}).subscribe(async (token) => {
        const signature = await this.wallet.signMessage(token);
        const loginUrl = `https://us-central1-porkchain-3cb1e.cloudfunctions.net/blockchainLogin?token=${token}&signature=${signature}`;
        this.http.get(loginUrl, {responseType: 'text'}).subscribe((customToken) => {
          if (customToken === 'GUEST') {
            this.setUserRoles(['USER']);
            this.router.navigate(['/']);
          } else {
            this.afAuth.signInWithCustomToken(customToken)
              .then((res: any) => {
                this.identityService.identity = this.db.list('identity/' + this.wallet.address).snapshotChanges()
                  .pipe(map(items => {
                    return items.reduce((total, current) => {
                      const value = current.payload.val();
                      const key = current.payload.key;
                      total[key] = value;
                      return total;
                    }, {});
                  }));

                this.identityService.identity.pipe(first()).subscribe(identity => {
                  if (Object.keys(identity).length !== 0 && identity.constructor === Object) {
                    const role = identity.type.toUpperCase();
                    this.identity = identity;
                    this.setUserRoles([role]);
                  } else {
                    this.setUserRoles(['USER']);
                  }
                  // TODO: DELETE THIS!
                  // this.router.navigate(['/lots']);
                  // resolve('')


                  this.router.navigate(['/']);
                });
              })
              .catch((error) => {
                // Handle Errors here.
                reject()
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, 'errorCode');
                console.log(errorMessage, 'errorMessage');
                // ...
              });
          }
        });
      });
    })


  }

  requestIdentity(identity: any) {
    this.db.list('pendingIdentities', ref => ref.orderByChild('id').equalTo(identity.id))
      .snapshotChanges()
      .pipe(
        first(),
        map(logisticPendingDrivers => {
          return logisticPendingDrivers.reduce((total, current, i) => {
            total[i] = current.payload.val();
            return total;
          }, []);
        })
      )
      .subscribe((identities: any) => {
        if (identities[0] && identities[0].type === identity.type) {
          this.db.list('identity', ref => ref.orderByChild('id').equalTo(identity.id))
            .snapshotChanges()
            .pipe(
              first(),
              map(logisticPendingDrivers => {
                return logisticPendingDrivers.reduce((total, current, i) => {
                  total[i] = current.payload.val();
                  return total;
                }, []);
              })
            )
            .subscribe((dbIdentities: any) => {
              if (dbIdentities[0] && dbIdentities[0].type === dbIdentities.type) {
                this.translate.get([
                  'generic.error',
                  'auth.identityAlreadyRegistered'
                ]).subscribe((trans: any) => {
                  Swal.fire({
                    icon: 'error',
                    title: trans['generic.error'],
                    text: trans['auth.identityAlreadyRegistered']
                  });
                });
              } else {
                this.db.object(`identity/${this.wallet.address}`)
                  .set({...identity, walletAddress: this.wallet.address})
                  .then(() => {
                     this.db.list('pendingIdentities', ref => ref.orderByChild('id').equalTo(identity.id))
                      .snapshotChanges()
                      .pipe(
                        first()
                      )
                      .subscribe((pendingIdentities: any) => {

                        for (const pendingIdentity of pendingIdentities) {
                          this.db.object(`pendingIdentities/${pendingIdentity.key}`)
                            .remove()
                            .then(() => {
                              this.identityService.identity = this.db.list('identity/' + this.wallet.address)
                                .snapshotChanges()
                                .pipe(map(items => {
                                  return items.reduce((total, current) => {
                                    const value = current.payload.val();
                                    const key = current.payload.key;
                                    total[key] = value;
                                    return total;
                                  }, {});
                                }));
                              this.setUserRoles([identity.type]);
                              this.router.navigate(['/']);
                              this.translate.get([
                                'generic.congratulations',
                                'auth.identityCreatedSuccessfully'
                              ]).subscribe((trans: any) => {
                                Swal.fire({
                                  icon: 'success',
                                  title: trans['generic.congratulations'],
                                  text: trans['auth.identityCreatedSuccessfully']
                                });
                              });
                            });

                        }
                      })
                  });
              }
            });
        } else {
          this.translate.get([
            'generic.error',
            'auth.unauthorizedIdentity'
          ]).subscribe((trans: any) => {
            Swal.fire({
              icon: 'error',
              title: trans['generic.error'],
              text: trans['auth.unauthorizedIdentity']
            });
          });
        }
      });
  }


  public logout() {
    this.afAuth.signOut();
    this.decodePass = '';
    this.router.navigate(['/login']);
  }
}
