import {Component, OnInit} from '@angular/core';
import {RawMaterialProductsService} from '../../../core/services/raw-material-products/raw-material-products.service';
import {RawMaterialProductFormService} from '../../../core/services/raw-material-products/raw-material-product-form.service';

@Component({
  selector: 'app-raw-material-products',
  templateUrl: './raw-material-products.component.html',
  styleUrls: ['./raw-material-products.component.css']
})
export class RawMaterialProductsComponent implements OnInit {

  constructor(
    public rawMaterialProductsService: RawMaterialProductsService,
    public rawMaterialProductFormService: RawMaterialProductFormService,
  ) {
  }

  ngOnInit() {
    this.rawMaterialProductsService.renderTable();
  }

  newRawMaterialProduct() {
    this.rawMaterialProductFormService.newRawMaterialProduct();
  }


}
