import {Component, OnInit} from '@angular/core';
import {DocumentFormService} from '../../../../../core/services/documents/document-form.service';
import {getVal} from '../../../../../core/utils/getNestedObject';

@Component({
    selector: 'app-logistic-section',
    templateUrl: './logistic-section.component.html',
    styleUrls: ['./logistic-section.component.css']
})
export class LogisticSectionComponent implements OnInit {
    dtOptions = {
        format: 'DD/MM/YYYY HH:mm',
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'far fa-calendar-check',
            clear: 'fas fa-trash',
            close: 'fas fa-times'
        },
        locale: 'ca'
    };

    constructor(
        public formService: DocumentFormService
    ) {
    }

    ngOnInit() {
    }

    logisticChange(e) {
        const walletAddress = e.target.value;
        this.formService.selectLogisticByWalletAddress(walletAddress);
    }

    driverChange(e) {
        const walletAddress = e.target.value;
        this.formService.selectDriverByWalletAddress(walletAddress);
    }

    sign() {
        this.formService.sign('DRIVER');
    }

    ngChangeDt(e) {
        this.formService.document.driverData.data.cleanDt = (e && typeof e.unix === 'function' ? e.unix() * 1000 : this.formService.document.driverData.data.cleanDt);
    }

}
