import {Injectable, ChangeDetectorRef} from '@angular/core';
import {LotsTemplatesService} from './lots-templates.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {LotTemplateInterface} from '../../interfaces/lot/lot-template.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {LotTemplateModel} from '../../models/lot-template.model';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LotTemplateFormService {
  lotTemplate: LotTemplateModel = new LotTemplateModel({});
  selectedId: string;

  constructor(
    private lotsTemplatesService: LotsTemplatesService,
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    public clipboardService: ClipboardService,
    private http: HttpClient,
    private translate: TranslateService
  ) {


    $(document).on('click', '.edit-lot-template-btn', (e) => {
      const id = $(e.currentTarget).attr('lot-template-id');
      console.log(id, 'id');
      this.loadLotTemplate(id);
    });

    $(document).on('click', '.del-lot-template-btn', (e) => {
      const id = $(e.currentTarget).attr('lot-template-id');
      this.delLotTemplate(id);
    });
  }

  saveLotTemplate(close: boolean = true) {
    console.log(this.lotTemplate, 'this.lotTemplate')
    this.lotsTemplatesService.saveLotTemplate(this.lotTemplate, this.selectedId);
    if (close) {
      $('#new-lot-template-modal').modal('hide');
    }
  }

  loadLotTemplate(id) {
    // this.lotTemplate = new LotTemplateModel();
    console.log(id, 'id')
    this.lotsTemplatesService.getLotTemplate(id)
      .pipe(
        first()
      )
      .subscribe((lotTemplate: LotTemplateInterface) => {

        console.log(lotTemplate, 'lotTemplate1');
        this.selectedId = lotTemplate.id;
        this.lotTemplate = new LotTemplateModel(lotTemplate);

      });

  }

  delLotTemplate(lotTemplateId) {
    console.log(lotTemplateId, 'delLotTemplate')
    if (lotTemplateId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'generic.deleteAlert.title',
        'generic.deleteAlert.text',
        'generic.deletedAlert.title',
        'generic.deletedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['generic.deleteAlert.title'],
          text: trans['generic.deleteAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.db.object(`lotsTemplates/${lotTemplateId}`).remove()
              .then(() => {
                Swal.fire(
                  trans['generic.deletedAlert.title'],
                  trans['generic.deletedAlert.text'],
                  'success'
                );
              });
          }
        });
      });
    }
  }

  newLotTemplate() {
    this.lotTemplate = new LotTemplateModel({});
    this.selectedId = null;
  }

}
