import {Injectable, ChangeDetectorRef} from '@angular/core';
import {RawMaterialsService} from './raw-materials.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {RawMaterialInterface} from '../../interfaces/lot/raw-material.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {RawMaterialModel} from '../../models/raw-material.model';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class RawMaterialFormService {
  rawMaterial: RawMaterialModel = new RawMaterialModel({});
  selectedId: string;
  expiration: string;
  date: string;

  constructor(
    private rawMaterialsService: RawMaterialsService,
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private db: AngularFireDatabase,
    public clipboardService: ClipboardService,
    private http: HttpClient,
    private translate: TranslateService
  ) {


    $(document).on('click', '.edit-raw-material-btn', (e) => {
      const id = $(e.currentTarget).attr('raw-material-id');
      console.log(id, 'id');
      this.loadRawMaterial(id);
    });

    $(document).on('click', '.del-raw-material-btn', (e) => {
      const id = $(e.currentTarget).attr('raw-material-id');
      this.delRawMaterial(id);
    });
  }

  saveRawMaterial(close: boolean = true) {
    console.log(this.rawMaterial, 'this.rawMaterial')
    // if (this.rawMaterial.id) {

      this.rawMaterialsService.saveRawMaterial(this.rawMaterial);

      if (close) {
        $('#new-raw-material-modal').modal('hide');
      }
    // } else {
    //   this.translate.get([
    //     'generic.error',
    //     'lots.lotIdRequired'
    //   ]).subscribe((trans: any) => {
    //     Swal.fire({
    //       icon: 'error',
    //       title: trans['generic.error'],
    //       html: trans['lots.lotIdRequired']
    //     });
    //   });
    // }
  }

  loadRawMaterial(id) {
    // this.lot = new RawMaterialModel();

      this.rawMaterialsService.getRawMaterial(id)
        .pipe(
          first()
        )
        .subscribe((rawMaterial: RawMaterialInterface) => {

          console.log(rawMaterial, 'rawMaterial1');
          this.selectedId = rawMaterial.id;
          this.rawMaterial = new RawMaterialModel(rawMaterial);
          this.expiration = moment(this.rawMaterial.expiration, 'x').format('DD/MM/YYYY HH:mm');
          this.date = moment(this.rawMaterial.date, 'x').format('DD/MM/YYYY HH:mm');

        });

  }

  delRawMaterial(rawMaterialId) {
    console.log(rawMaterialId, 'delRawMaterial')
    if (rawMaterialId) {
      this.translate.get([
        'generic.yes',
        'generic.no',
        'generic.deleteAlert.title',
        'generic.deleteAlert.text',
        'generic.deletedAlert.title',
        'generic.deletedAlert.text',
      ]).subscribe((trans: any) => {
        Swal.fire({
          title: trans['generic.deleteAlert.title'],
          text: trans['generic.deleteAlert.text'],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.isConfirmed) {
            this.db.object(`rawMaterials/${rawMaterialId}`).remove()
              .then(() => {
                Swal.fire(
                  trans['generic.deletedAlert.title'],
                  trans['generic.deletedAlert.text'],
                  'success'
                );
              });
          }
        });
      });
    }
  }

  newRawMaterial() {
    this.rawMaterial = new RawMaterialModel({});
    this.selectedId = null;
  }

}
