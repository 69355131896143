import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {first, map} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {DatatableLangService} from '../datatable-lang.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class LogisticPendingDriversService {
    dataTable: any;
    public pendingDrivers: any;
    public pendingDriverList: any;


    constructor(
        private db: AngularFireDatabase,
        private datatableLang: DatatableLangService,
        private translate: TranslateService
    ) {
        this.pendingDriverList = db.list('logisticPendingDrivers')
            .snapshotChanges()
            .pipe(
                map(pendingTransaction => {
                    return pendingTransaction.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].key = current.key;
                        return total;
                    }, []);
                })
            );
    }

    renderTable() {
        this.pendingDriverList.subscribe(pendingDrivers => {
            const table: any = $('#pending-drivers-table');
            this.dataTable = table.DataTable({
                destroy: true,
                scrollX: true,
                language: this.datatableLang.lang,
                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                order: [[1, 'desc']],
                data: pendingDrivers,
                columns: [
                    {data: 'key', width: '50px'},
                    {data: 'id', defaultContent: ''},
                    {data: 'fiscalName', defaultContent: ''}
                ],
                columnDefs: [
                    {
                        targets: 0,
                        data: 'key',
                        title: 'Actions',
                        orderable: false,
                        render: (key) => {
                            // language=HTML
                            return `
                            <button pending-driver-id="${key}" type="button" class="del-pending-driver-btn btn btn-danger px-3 mr-2">
                                <i class="fas fa-times"></i>
                            </button>
                            `;
                        },
                    }
                ],
                drawCallback: () => {
                    $('[data-toggle="tooltip"]').tooltip();
                }
            });
        });

        $(document).on('click', '.del-pending-driver-btn', (e) => {
            const key = $(e.currentTarget).attr('pending-driver-id');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteLogisticPendingDriver.warning.title',
                'identity.deleteLogisticPendingDriver.warning.text',
                'identity.deleteLogisticPendingDriver.deleted.title',
                'identity.deleteLogisticPendingDriver.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteLogisticPendingDriver.warning.title'],
                    text: trans['identity.deleteLogisticPendingDriver.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.object('logisticPendingDrivers/' + key).remove()
                            .then(() => {
                                Swal.fire(
                                    trans['identity.deleteLogisticPendingDriver.deleted.title'],
                                    trans['identity.deleteLogisticPendingDriver.deleted.text'],
                                    'success'
                                );
                            });
                    }
                });
            });
        });

    }

    savePendingDriver(driverWalletAddress) {
        if (driverWalletAddress) {
            this.db.list('identity/' + driverWalletAddress).snapshotChanges()
                .pipe(map(items => {
                        return items.reduce((total, current) => {
                            total[current.payload.key] = current.payload.val();
                            return total;
                        }, {});
                    }),
                    first()
                ).subscribe(driver => {
                this.db.list('logisticPendingDrivers').push(JSON.parse(JSON.stringify(driver)));
            });
        }
    }
}
