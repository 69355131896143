import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentQRService {
  public documentId$: Subject<any> = new Subject();

  constructor() {
  }
}
