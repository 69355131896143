import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DatatableLangService {
    lang: object;

    constructor(
        private translate: TranslateService
    ) {
        translate.get([
            'datatable.decimal',
            'datatable.emptyTable',
            'datatable.info',
            'datatable.infoEmpty',
            'datatable.infoFiltered',
            'datatable.lengthMenu',
            'datatable.loadingRecords',
            'datatable.processing',
            'datatable.search',
            'datatable.zeroRecords',
            'datatable.paginate.first',
            'datatable.paginate.last',
            'datatable.paginate.next',
            'datatable.paginate.previous',
            'datatable.aria.sortAscending',
            'datatable.aria.sortDescending'
        ])
            .subscribe((lang) => {
                this.lang = {
                    emptyTable: lang['datatable.emptyTable'],
                    info: lang['datatable.info'],
                    infoEmpty: lang['datatable.infoEmpty'],
                    infoFiltered: lang['datatable.infoFiltered'],
                    lengthMenu: lang['datatable.lengthMenu'],
                    loadingRecords: lang['datatable.loadingRecords'],
                    processing: lang['datatable.processing'],
                    search: lang['datatable.search'],
                    zeroRecords: lang['datatable.zeroRecords'],
                    paginate: {
                        first: lang['datatable.paginate.first'],
                        last: lang['datatable.paginate.last'],
                        next: lang['datatable.paginate.next'],
                        previous: lang['datatable.paginate.previous']
                    },
                    aria: {
                        sortAscending: lang['datatable.aria.sortAscending'],
                        sortDescending: lang['datatable.aria.sortDescending']
                    }
                };
            });
    }
}
