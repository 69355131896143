import {Injectable} from '@angular/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class QrExporterService {
    qrData: string;

    constructor() {
    }

    showQrExporter(qrData) {
        if (qrData) {
            this.qrData = qrData;
            $('#qr-exporter-modal').modal('show');
        }
    }
}
