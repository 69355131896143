import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {

    transform(value: number): string {
        const minutes = value % 60;
        const hours = (value - minutes) / 60;
        return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    }

}
