import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-document',
  templateUrl: './print-document.component.html',
  styleUrls: ['./print-document.component.css']
})
export class PrintDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
