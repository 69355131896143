import { Component, OnInit } from '@angular/core';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';
import {LogisticModel} from '../../../../../core/models/identity/logistic.model';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modify-logistic-form',
  templateUrl: './modify-logistic-form.component.html',
  styleUrls: ['./modify-logistic-form.component.css']
})
export class ModifyLogisticFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService,
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new LogisticModel(this.modifyIdentityFormService.identity);
  }

  addTransport() {
    this.modifyIdentityFormService.addTransport();
  }

  delTransport(transportCode) {
    this.modifyIdentityFormService.delTransport(transportCode);
  }

}
