import { Component, OnInit } from '@angular/core';
import {LogisticDriversService} from '../../../core/services/logistics/logistic-drivers.service';

@Component({
  selector: 'app-logistic-drivers',
  templateUrl: './logistic-drivers.component.html',
  styleUrls: ['./logistic-drivers.component.css']
})
export class LogisticDriversComponent implements OnInit {

  constructor(
      public logisticDriversService: LogisticDriversService
  ) { }

  ngOnInit() {
    this.logisticDriversService.renderTable();
  }

}
