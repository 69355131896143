import {PendingIdentityInterface} from '../../interfaces/identity/pending-identity';
import {getVal} from '../../utils/getNestedObject';


export class PendingIdentityModel {
    public type;
    public id;
    public fiscalName;

    constructor(
        pendingIdentity?: PendingIdentityInterface
    ) {
        this.type = getVal(pendingIdentity, 'type') || 'FARM';
        this.id = getVal(pendingIdentity, 'id') || '';
        this.fiscalName = getVal(pendingIdentity, 'fiscalName') || '';
    }
}



