import {RawMaterialProductInterface} from '../interfaces/lot/raw-material-product.interface';

export class RawMaterialProductModel {
  id: string;
  ean13: string;
  name: string;
  description: string;
  url: string;


  constructor(rawMaterialProduct: RawMaterialProductInterface | any) {
    this.id = rawMaterialProduct.id || '';
    this.ean13 = rawMaterialProduct.ean13 || '';
    this.name = rawMaterialProduct.name || '';
    this.description = rawMaterialProduct.description || '';
    this.url = rawMaterialProduct.url || '';
  }

}



