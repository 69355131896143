import { Component, OnInit } from '@angular/core';
import {RawMaterialInterface} from '../../../../core/interfaces/lot/raw-material.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RawMaterialsService} from '../../../../core/services/raw-materials/raw-materials.service';

@Component({
  selector: 'app-raw-material-modal',
  templateUrl: './raw-material-modal.component.html',
  styleUrls: ['./raw-material-modal.component.css']
})
export class RawMaterialModalComponent implements OnInit {

 rawMaterial: RawMaterialInterface;
  rawMaterials: RawMaterialInterface[];
  lotQuantity: number;
  lotWeight: number;
  lotWeightUnit = 'kg';

  constructor(
    public activeModal: NgbActiveModal,
    public rawMaterialsService: RawMaterialsService,
  ) {
  }

  ngOnInit(): void {
    this.rawMaterialsService.rawMaterials.subscribe((rawMaterials: RawMaterialInterface[]) => this.rawMaterials = rawMaterials);
  }


  save() {
    this.activeModal.close({
      ...this.rawMaterial,
      lotQuantity: this.lotQuantity,
      lotWeight: this.lotWeight,
      lotWeightUnit: this.lotWeightUnit
    });
  }

}
