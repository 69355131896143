import { Component, OnInit } from '@angular/core';
import {IdentityService} from '../../../../core/services/identity.service';

@Component({
  selector: 'app-logistics-requests',
  templateUrl: './logistics-requests.component.html',
  styleUrls: ['./logistics-requests.component.css']
})
export class LogisticsRequestsComponent implements OnInit {
  logisticsRequests: any[];

  constructor(
      private identityService: IdentityService,
  ) {
  }

  ngOnInit() {
    this.identityService.identity.subscribe((identity) => {
      this.identityService.getLogisticsRequest(identity).subscribe((logisticsRequests) => {
        this.logisticsRequests = logisticsRequests;
      });
    });
  }

  delLogisticRequest(key: string) {
    this.identityService.delLogisticRequest(key);
  }

  acceptLogisticRequest(logisticRequest: any) {
    this.identityService.acceptLogisticRequest(logisticRequest);
  }

}
