import {Component, OnInit} from '@angular/core';
import {ChainsService} from '../../../core/services/chains/chains.service';

@Component({
    selector: 'app-chains',
    templateUrl: './chains.component.html',
    styleUrls: ['./chains.component.css']
})
export class ChainsComponent implements OnInit {

    constructor(
        private chainsServices: ChainsService
    ) {
    }

    ngOnInit() {
        this.chainsServices.renderTable();
    }

    newChain() {
        this.chainsServices.newChain();
    }

}
