import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[selectAll]'
})
export class SelectAllDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('click')
    selectAll() {
        let nativeEl: HTMLInputElement = this.el.nativeElement;

        if (nativeEl.type === 'text') {
            nativeEl.select();
        }
    }


}
