import { FieldInterface, OptionInterface } from '../interfaces/lot/product.interface';

export class FieldModel {
  label: string;
  fieldName: string;
  type: string;
  options?: OptionInterface[];
  defaultValue: string;


  constructor(field: FieldInterface | any) {
    this.label = field.label || '';
    this.fieldName = field.fieldName || '';
    this.defaultValue = field.defaultValue || '';
    this.options = field.options || [];
  }

}



