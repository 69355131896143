import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {DocumentFarmInterface} from 'src/app/core/interfaces/document/document-farm-interface';
import {DocumentDriverInterface} from 'src/app/core/interfaces/document/document-driver-interface';
import {ClipboardService} from 'ngx-clipboard';
import {DocumentInterface} from '../../../core/interfaces/document/document';
import {ethers} from 'ethers';
import {ContractService} from '../../../core/services/contract/contract.service';
import {DocumentModel} from '../../../core/models/document.model';

@Component({
    selector: 'app-verify-signature',
    templateUrl: './verify-signature.component.html',
    styleUrls: ['./verify-signature.component.css']
})
export class VerifySignatureComponent implements OnInit {
    document: DocumentInterface;
    signingAddress: string;
    signedData: string;
    signedDataJSON: any;
    type: string;
    copied: boolean;
    inBlockChain: boolean;
    id: string;
    fiscalName: string;

    constructor(
        private route: ActivatedRoute,
        private db: AngularFireDatabase,
        private clipboardService: ClipboardService,
        public contractService: ContractService
    ) {
        this.route.params.subscribe(params => {

            this.type = params.type.toLowerCase();

            this.db.list(`documents/${params.documentId}`).snapshotChanges()
                .pipe(map(items => {
                        return items.reduce((total, current) => {
                            const value = current.payload.val();
                            const key = current.payload.key;
                            total[key] = value;
                            return total;
                        }, {});
                    })
                )
                .subscribe(async (document: DocumentInterface) => {
                    if (Object.entries(document).length === 0 && document.constructor === Object) {
                        this.db.list(`documentsHistoric/${params.documentId}`).snapshotChanges()
                            .pipe(map(items => {
                                    return items.reduce((total, current) => {
                                        const value = current.payload.val();
                                        const key = current.payload.key;
                                        total[key] = value;
                                        return total;
                                    }, {});
                                })
                            )
                            .subscribe(async (documentHistoric: DocumentInterface) => {
                                    this.document = documentHistoric;
                                    this.signedData = this.document[this.type + 'Data'].signature.signedData;
                                    this.signedDataJSON = JSON.parse(this.signedData);
                                    if (this.type === 'farm') {
                                        this.id = documentHistoric.staticData.data.farm.id;
                                        this.fiscalName = documentHistoric.staticData.data.farm.fiscalName;
                                    } else {
                                        this.id = documentHistoric.driverData.data.driver.id;
                                        this.fiscalName = documentHistoric.driverData.data.driver.fiscalName;
                                    }
                                    this.signingAddress = await ethers.utils
                                        .verifyMessage(this.signedData, this.document[this.type + 'Data'].signature.signature);
                                    this.inBlockChain = await this.contractService.validate(this.document[this.type + 'Data'].signature.hash);
                            });
                    } else {
                        this.document = document;
                        this.signedData = this.document[this.type + 'Data'].signature.signedData;
                        this.signedDataJSON = JSON.parse(this.signedData);
                        if (this.type === 'farm') {
                            this.id = document.staticData.data.farm.id;
                            this.fiscalName = document.staticData.data.farm.fiscalName;
                        } else {
                            this.id = document.driverData.data.driver.id;
                            this.fiscalName = document.driverData.data.driver.fiscalName;
                        }
                        this.signingAddress = await ethers.utils
                            .verifyMessage(this.signedData, this.document[this.type + 'Data'].signature.signature);
                        this.inBlockChain = await this.contractService.validate(this.document[this.type + 'Data'].signature.hash);

                    }
                });
        });
    }

    ngOnInit() {
    }

    copySignedData() {
        this.clipboardService.copyFromContent(this.signedData);
        this.copied = true;
        setTimeout(() => {
            this.copied = false;
        }, 2000);
    }

}
