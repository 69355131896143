export class ChainModel {
    chain: string;
    checkInterval: number;
    contract: string;
    explorer: string;
    server: string;
    txMaxMinutes: number;
    active: boolean;


    clear() {
        this.chain = '';
        this.checkInterval = 0;
        this.contract = '';
        this.explorer = '';
        this.server = '';
        this.txMaxMinutes = 0;
        this.active = true;
    }

}
