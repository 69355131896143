import {Component, OnInit} from '@angular/core';
import {LogisticInterface} from '../../../../core/interfaces/identity/logistic.interface';
import {IdentityService} from '../../../../core/services/identity.service';
import {LogisticModel} from '../../../../core/models/identity/logistic.model';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-logistic-form',
    templateUrl: './logistic-form.component.html',
    styleUrls: ['./logistic-form.component.css']
})
export class LogisticFormComponent implements OnInit {
    logistic: LogisticInterface = new LogisticModel();
    transportsCode: string[];
    transportCode: string;

    constructor(
        private identityService: IdentityService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.identityService.identity.subscribe((logistic: LogisticInterface) => {
            this.logistic = new LogisticModel(logistic);
            this.transportsCode = logistic.transports && Object.keys(logistic.transports) || [];
        });
    }

    saveIdentity() {
        this.identityService.saveIdentity(this.logistic);
    }

    addTransport() {
        if (this.transportCode) {
            this.identityService.addTransport(this.transportCode);
            this.transportCode = '';
        } else {
            this.translate.get([
                'generic.error',
                'identity.logistic.error.emptyTransportCode',
                'generic.ok'
            ]).subscribe((translate: any) => {
                Swal.fire({
                    icon: 'error',
                    title: translate['generic.error'],
                    text: translate['identity.logistic.error.emptyTransportCode'],
                    confirmButtonText: translate['generic.ok'],
                });
            });
        }
    }

    delTransport(transportCode: string) {
        this.identityService.delTransport(transportCode);
    }

}
