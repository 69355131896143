import {Component, OnInit} from '@angular/core';
import {RawMaterialProductFormService} from '../../../../core/services/raw-material-products/raw-material-product-form.service';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';
import {RawMaterialProductInterface} from '../../../../core/interfaces/lot/raw-material-product.interface';
import {RawMaterialProductModel} from '../../../../core/models/raw-material-product.model';

@Component({
  selector: 'app-raw-material-product-form',
  templateUrl: './raw-material-product-form.component.html',
  styleUrls: ['./raw-material-product-form.component.css']
})
export class RawMaterialProductFormComponent implements OnInit {


  constructor(
    public formService: RawMaterialProductFormService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {

  }

  saveRawMaterialProduct() {
    this.formService.saveRawMaterialProduct();

    this.translate.get([
      'generic.congratulations',
      'generic.successfullySaved',
    ]).subscribe((trans: any) => {
      Swal.fire({
        icon: 'success',
        title: trans['generic.congratulations'],
        text: trans['generic.successfullySaved'],
        timer: 2000
      });
    });


  }


}
