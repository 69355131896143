export const locale = {
  lang: 'cat',
  data: {
    menu: {
      home: 'Inici',
      files: 'Fitxers',
      identity: 'Perfil',
      identities: 'Identitats',
      farms: 'Granges',
      drivers: 'Transportistes',
      documents: {
        documents: 'Guies',
        pendingDocuments: 'Pendents',
        documentsHistoric: 'Històric',
      },
      lots: {
        lots: 'Lots',
        productionLots: 'Lots producció',
        rawMaterials: 'Lots matèria prima',
        rawMaterialProducts: 'Productes lots matèria prima',
        products: 'Productes producció',
        lotsTemplates: 'Plantilles lots',
        processes: 'Processos'
      },
      transactions: 'Transaccions',
      charts: 'Estadístiques',
      wallet: 'Cartera',
      config: 'Configuració',
      listFilter: {
        pending: 'Pendents',
        success: 'Acceptades'
      },
      chains: 'Chains',
      logout: 'Tancar Sessió',
    },
    home: {
      hello: 'Hola',
      welcome: `
                <h4>Has connectat amb èxit a la <b>blockchain</b></h4>
                <h4>La teva clau pública és</h4>
             `,
      requestIdentity: {
        getNewIdentity: 'Obtenir una identitat',
        getIdentityMsg: 'No tens cap identitat.<br>Per poder accedir, has de sol·licitar una nova identitat.',
        newIdentity: 'Nova identitat',
        identity: 'Identitat'
      },
    },
    identity: {
      generic: {
        id: 'NIF/CIF',
        fiscalName: 'Nom fiscal',
        commercialName: 'Nom comercial',
        QS: 'Qualificació Sanitària',
        delegatedGas: 'Gas delegat',
        address: 'Adreça',
        city: 'Ciutat',
        postalCode: 'Codi postal',
        province: 'Provincia',
        country: 'País',
        email: 'Email',
        phone: 'Telèfon',
        mobile: 'Móvil',
        MOCode: 'Codi MO',
        REGACode: 'Codi REGA',
        type: 'Tipus',
        holderId: 'NIF/CIF del titular',
        holderName: 'Nom del titular',
        token: 'Token',
        walletAddress: 'Clau pública',
        active: 'Actiu'
      },
      farm: {
        mobilityPass: 'Contrasenya mobilitat'
      },
      vet: {
        collegeNumber: 'Número de col·legiat',
      },
      logistic: {
        transports: 'Vehicles',
        transportCode: 'Matrícula del vehicle',
        error: {
          emptyTransportCode: 'No es pot afegir una matrícula buida.'
        }
      },
      driver: {
        SIRCode: 'Codi Sirentra',
        ATESCode: 'Codi ATES',
      },
      deleteProviderRequest: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquesta solicitud?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Sol·licitud esborrada correctament'
        }
      },
      deleteProvider: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquest proveïdor?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Proveïdor esborrat correctament'
        }
      },
      deleteLogisticRequest: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquesta solicitud?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Sol·licitud esborrada correctament'
        }
      },
      deleteLogistic: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquesta empresa de logística?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Empresa de logística esborrada correctament'
        }
      },
      deleteTransport: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquest vehicle?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Vehicle esborrat correctament'
        }
      },
      deleteProviderFarm: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquesta granja?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Granja esborrada correctament'
        }
      },
      deleteProviderPendingFarm: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquesta granja pendent per assignar?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Granja assignada esborrada correctament'
        }
      },
      deleteLogisticDriver: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquest transportista?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Transportista esborrat correctament'
        }
      },
      deleteLogisticPendingDriver: {
        warning: {
          title: 'Estàs segur?',
          text: 'Està segur que vol eliminar aquest transportista pendent per assignar?'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Transportista assignat esborrat correctament'
        }
      },
      deletePendingIdentity: {
        warning: {
          title: 'Estàs segur?',
          text: 'Aquest canvi no es podrà revertir!'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Identitat pendent esborrada correctament'
        }
      },
    },
    identities: {
      admin: 'Administrador',
      farms: 'Granges',
      farm: 'Granja',
      provider: 'Proveïdor',
      vet: 'Veterinari',
      slaughterhouse: 'Escorxador',
      logistic: 'Logística',
      drivers: 'Transportistes',
      driver: 'Transportista',
    },
    pendingIdentities: {
      pendingIdentities: 'Identitats pendents',
      newIdentity: 'Nova identitat'
    },
    providerPendingFarms: {
      pendingFarms: 'Granges pendents',
      addFarm: 'Afegir granja'
    },
    logisticPendingDrivers: {
      pendingDrivers: 'Transportistes pendents',
      addDriver: 'Afegir transportista'
    },
    transactions: {
      transaction: 'Transacció',
      signatureHash: 'Hash de la signatura',
      info: 'Informació'
    },
    charts: {
      ETHPrice: 'Preu ETH',
      serverBalance: 'ETH Delegat',
      lastBlock: 'Últim block',
      lastTransactionDuration: 'Última transacció',
      documentsLeft: 'Guies disponibles',
      daily: 'Diari',
      monthly: 'Mensual',
      yearly: 'Anual',
      documentsQty: 'Número de guíes',
      earnings: 'Guanys',
      transactionsQty: 'Número de transaccions',
      transactionsDuration: 'Duració mitja transaccions',
      expenses: 'Despeses',
      profit: 'Ingressos',
      minutes: 'Minuts',
    },
    wallet: {
      publicKey: 'Clau publica',
      privateKey: 'Clau privada',
      mnemonicPhrase: 'Frase mnemotècnica',
      downloadKeystore: 'Descarregar clau encriptada',
    },
    config: {
      blockChain: 'BlockChain',
      documentPrice: 'Preu per guia',
      gasPrice: 'Preu del gas',
    },
    form: {
      save: 'Guardar',
      saveTitle: 'Guardat',
      close: 'Tancar',
      saveMessage: 'Guardat correctament',
      link: 'Enllaç',
      send: 'Enviar'
    },
    externalLink: {
      addExternalLink: 'Afegir enllaç extern',
      externalLinks: 'Enllaços externs',
      externalLink: 'Enllaç extern',
      reference: 'Referència',
      url: 'Enllaç',
    },
    lots: {
      lots: 'Lots producción',
      lotsOrigin: 'Lots origen',
      lotTemplate: 'Plantilla lot',
      custom: 'Camps personalitzats',
      lot: 'Lot producció',
      newLot: 'Nou lot',
      rawMaterials: 'Lots matèria prima',
      addRawMaterial: 'Afegir lot matèria prima',
      addLot: 'Afegir lot producció',
      documents: 'Guies de traçabilitat',
      addDocument: 'Afegir guia de traçabilitat',
      id: 'ID',
      externalId: 'ID extern',
      name: 'Nom',
      provider: 'Proveïdor',
      withoutProvider: 'Sin proveïdor',
      start: 'Inici',
      end: 'Final',
      from: 'Des de',
      to: 'Fins',
      date: 'Data',
      units: 'Unitats',
      quantity: 'Total unitats lot',
      expiration: 'Caducitat',
      process: 'Procés',
      product: 'Producte',
      products: 'Productes',
      weight: 'Pes total net lot',
      grossWeight: 'Pes total brut lot',
      type: 'Tipus',
      color: 'Color',
      origin: 'País d\'origen',
      drumNum: 'Drum Num',
      homogenized: 'Homogenized honey',
      notHomogenized: 'Not homogenized honey',
      mainData: 'Dades principals',
      uniqueIdSeries: 'Sèrie d\'identificadors únics',
      sensorTagIdSeries: 'Sèrie de sensors',
      productIdAlreadyAddedAlert: {
        title: 'Error',
        text: 'Hi ha productes d’aquesta sèrie que pertanyen a un altre lot'
      },
      uploadToBlockchainAlert: {
        title: 'Estàs segur?',
        text: 'Carregarà el lot al BlockChain. Un cop s\'hagi carregat, no es poden fer modificacions al lot.'
      }
    },
    rawMaterials: {
      rawMaterials: 'Lots matèria prima',
      newRawMaterial: 'Nou lot matèria prima',
      id: 'Id',
      externalId: 'ID extern',
      name: 'Nom',
      date: 'Data',
      quantity: 'Total unitats lot',
      expiration: 'Caducitat',
      provider: 'Proveïdor',
      rawMaterialProduct: 'Producte matèria prima',
      weight: 'Pes total lot',
      geolocation: 'Geolocalització',
    },
    rawMaterialProducts: {
      rawMaterialProducts: 'Productes matèria prima',
      newRawMaterialProduct: 'Nou producte matèria prima',
      ean13: 'Códi EAN13',
      name: 'Nom',
      description: 'Descripció',
      url: 'Pàgina web',
    },
    products: {
      products: 'Productes',
      product: 'Producte',
      newProduct: 'Nou producte',
      ean13: 'Códi EAN13',
      name: 'Nom',
      description: 'Descripció',
      geolocation: 'Geolocalització',
      type: 'Tipu',
      color: 'Color',
      origin: 'País d\'origen',
      url: 'Pàgina web',
      additionalFields: 'Camps adicionals',
      sections: 'Seccions',
      section: 'Secció',
      newField: 'Nou camp',
    },
    lotsTemplates: {
      lotsTemplates: 'Plantilles lots',
      lotTemplate: 'Plantilla lot',
      newLotTemplate: 'Nova plantilla lot',
      name: 'Nom',
      additionalFields: 'Camps adicionals',
      sections: 'Seccions',
      section: 'Secció',
      newField: 'Nou camp',
    },
    field: {
      field: 'Camp',
      fieldName: 'Códi del camp',
      label: 'Descripció del camp',
      type: 'Tipus',
      defaultValue: 'Valor per defecte',
      options: 'Opcions',
      option: 'Opció',
      description: 'Descripció',
      value: 'Valor',
    },
    processes: {
      processes: 'Processos',
      newProcess: 'Nou procés',
      name: 'Nom',
      description: 'Descripció',
    },
    documents: {
      documents: 'Guies',
      document: 'Guia',
      historical: 'Històric',
      newDocument: 'Guia nova',
      documentId: 'Número de guia',
      category: 'Categoría',
      categories: {
        fattening: 'Engreix',
        piglets: 'Garrins',
        recreationTransition: 'Recria / Transició',
        breedingFemales: 'Famelles reproductores',
        replenishment: 'Reposició',
        stallions: 'Sementals',
      },
      animalsQty: 'Núm. animals',
      sex: 'Sexe',
      sexes: {
        male: 'Masculí',
        female: 'Femení'
      },
      mobility: 'Mobilitat',
      departure: 'Sortida',
      departureDt: 'Data/Hora Sortida',
      exploitationOrigin: 'Explotació Origen',
      REGACode: 'Codi REGA',
      MOCode: 'Marca Oficial',
      delivery: 'Lliurament',
      deliveryDt: 'Data/Hora lliurament',
      exploitationDelivery: 'Explotació Destí',
      requestDocument: 'Sol·licitar Guia',
      modifyDocument: 'Modificar Guia',
      documentHash: 'Hash de la Guia',
      comment: 'Comentari',
      signature: 'Signatura',
      showSignature: 'Veure signatura',
      hideSignature: 'Amagar signatura',
      signatureDt: 'Data/Hora Signatura',
      show: 'Veure',
      signatureHash: 'Hash Signatura',
      verify: 'Verificar',
      transaction: 'Transacció',
      explore: 'Explorar',
      sign: 'Signar',
      SIRCode: 'Codi Sirentra',
      cleanNumber: 'Butlleta',
      cleanDt: 'Data/Hora Desinfecció',
      transport: 'Mitjà de transport',
      logisticCompany: 'Empresa logística',
      transports: {
        truck: 'Camió',
        others: 'Altres'
      },
      time: 'Temps',
      waitingResponse: 'Esperant resposta...',
      confirmDocument: 'Confirmar guia',
      documentQR: {
        importDocument: 'Importar guia'
      },
      documentIdRequired: 'És necessari sol·licitar la guia per poder guardar',
      documentSuccessfullyCreated: 'Guia número {{selectedDocumentId}} creada correctament.',
      documentSuccessfullySaved: 'Guia guardada correctament.',
      documentSuccessfullySigned: 'Guia signada i guardada correctament.',
      errorExternalServerRequest: 'Hi ha hagut un problema amb la resposta de la Generalitat, torna-ho a intentar',
      errorDriverRequiredData: 'És obligatori seleccionar un transportista amb el codi ATES i seleccionar la matrícula del transport',
      requiredFieldsMsg: 'Els camps amb * són obligatoris',
      documentSuccessfullyConfirmed: 'Guia número {{selectedDocumentId}} confirmada correctament. Aquesta guia es guardarà a l\'històric',
      wrongSignature: 'La signatura no és vàlida',
      verified: 'Verificat',
      signatureVerified: 'La signatura és vàlida',
      symbols: {
        A: 'Confirmat a l\'administració',
        B: 'Certificat a la BlockChain',
        S: 'Signat per l\'agent'
      },
      deleteDocumentRequest: {
        warning: {
          title: 'Estàs segur?',
          text: 'Aquest canvi no es podrà revertir!'
        },
        deleted: {
          title: 'Esborrat!',
          text: 'Guia esborrada correctament'
        }
      },
    },
    login: {
      login: 'Iniciar Sessió',
      enterPass: 'Contrasenya',
      useOtherWallet: 'Fer servir un altre compte',
      invalidPassword: 'Contrasenya incorrecte',
    },
    signUp: {
      signUp: 'Crear un compte',
      password: 'Contrasenya',
      repeatPassword: 'Repetir contrasenya',
      newAccount: 'Nou compte',
      generate: 'Crear',
      importKeystore: 'Importar clau encriptada',
      importPrivateKey: 'Importar clau privada',
      importMnemonicPhrase: 'Importar frase mnemotècnica',
      keystorePassword: 'Contrasenya de la clau encriptada',
      errorMnemonicPhrase: 'Frase mnemotècnica incorrecte',
      errorPrivateKey: 'Clau privada incorrecte',
      errorKeystorePassword: '<b>Error:</b> Contrasenya incorrecte. Si us plau verifiqui la seva contrasenya.',
      errorKeystoreUpload: '<b>Error:</b> Necesites pujar el fitcher i possar la contrasenya.',
      rememberPassword: `<b>Enrecordat de la contrasenya!</b><br>
               Aquesta contrasenya xifrarà la vostra adreça en un fitxer.<br>
               L'haureu de fer servir per iniciar sessió amb la vostra cartera.`,
      // `<b>Remember your password!</b><br>
      // This password encrypts your address in a keystore file.<br>
      // You will have to use it to log in with your wallet.
      errorRepeatPassword: 'Repeteix la contrasenya correctament',
      errorEmptyPassword: 'Introduïu una contrasenya',
    },
    datatable: {
      emptyTable: 'No hi ha dades disponibles a la taula',
      info: 'Mostrant _START_ de _END_ de _TOTAL_ entrades',
      infoEmpty: 'No hi han entrades',
      infoFiltered: '(filtrat de _MAX_ entrades)',
      lengthMenu: 'Mostrar _MENU_ entrades',
      loadingRecords: 'Carregant...',
      processing: 'Processant...',
      search: 'Buscar:',
      zeroRecords: 'No s’ha trobat cap registre',
      paginate: {
        first: 'Primer',
        last: 'Últim',
        next: 'Següent',
        previous: 'Anterior'
      },
      aria: {
        sortAscending: ': activar per ordenar la columna ascendent',
        sortDescending: ': activar per ordenar la columna descendent'
      }
    },
    slogan: 'BlockChain per a la traçabilitat', // Blockchain gateway for traceability
    partials: {
      exportQR: 'Exportar códi QR',
      importQR: 'Importar códi QR',
      cameraError: 'No es pot accedir a la transmissió de vídeo (assegureu-vos que teniu una càmera web habilitada)'
      // Unable to access video stream (please make sure you have a webcam enabled)
    },
    auth: {
      identityAlreadyRegistered: 'Aquest NIF ja té una identitat assignada',
      unauthorizedIdentity: 'Aquest NIF no te permís per crear aquesta identitat',
      identityCreatedSuccessfully: 'La seva identitat ha estat creada'
    },
    chains: {
      chains: 'Chains',
      fields: {
        chain: 'Chain',
        contract: 'Contracte',
        txMaxMinutes: 'Tx. Max min.',
        checkInterval: 'Interval pujada',
        explorer: 'Explorador',
        server: 'Servidor',
        active: 'Actiu',
      },
      newChain: 'Chain nova',
      alerts: {
        deleteChain: {
          warning: {
            title: 'Estàs segur?',
            text: 'Aquest canvi no es podrà revertir!'
          },
          deleted: {
            title: 'Esborrat!',
            text: 'Chain esborrada correctament'
          }
        },
      }
    },
    generic: {
      actions: 'Accions',
      creationDt: 'Data Creació',
      yes: 'Sí',
      no: 'No',
      status: 'Estat',
      pending: 'Pendent',
      process: 'Procés',
      historical: 'Històric',
      congratulations: 'Enhorabona',
      successfullySaved: 'S\'ha guardat correctament',
      deleteAlert: {
        title: 'Estàs segur?',
        text: 'Aquest canvi no es pot revertir!'
      },
      deletedAlert: {
        title: 'Esborrat!',
        text: 'S\'ha esborrat correctament'
      },
      error: 'Error',
      ok: 'D\'acord',
      copied: 'Copiat',
      copy: 'Copiar',
      info: 'Informació',
      create: 'Crear',
      deleteAll: 'Esborrar tot',
      form: 'Formulari'
    }
  }
};
