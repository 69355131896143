import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../core/services/config.service';

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
    config = {
        blockchain: null,
        documentPrice: null,
        gasPriceGwei: null
        };

    constructor(
        public configService: ConfigService
    ) {
    }

    ngOnInit() {
        this.configService.config.subscribe((config) => {
            this.config = config;
        });
    }

    saveConfig() {
        this.configService.saveConfig(this.config);
    }

}
