import {Component, OnInit} from '@angular/core';
import {DocumentFormService} from '../../../../core/services/documents/document-form.service';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.css']
})
export class DocumentFormComponent implements OnInit {
    newDocumentBtn = {
        type: 'primary',
        loading: false
    };

    dtOptions = {
        format: 'DD/MM/YYYY HH:mm',
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'far fa-calendar-check',
            clear: 'fas fa-trash',
            close: 'fas fa-times'
        },
        locale: 'ca'
    };

    constructor(
        public formService: DocumentFormService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {

    }

    saveDocument(documentId?: any, document?: any) {
        this.formService.saveDocument(documentId, document);
        if (this.formService.selectedDocumentId) {
            this.translate.get([
                'generic.congratulations',
                'documents.documentSuccessfullySaved',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    icon: 'success',
                    title: trans['generic.congratulations'],
                    text: trans['documents.documentSuccessfullySaved'],
                    timer: 2000
                });
            });
        }

    }

    async getDocumentId() {
        this.newDocumentBtn.loading = true;
        await this.formService.getDocumentId();
        this.newDocumentBtn.loading = false;
    }

    slaughterhouseChange(e) {
        const walletAddress = e.target.value;
        this.formService.selectSlaughterhouseByWalletAddress(walletAddress);
    }

    logisticChange(e) {
        const walletAddress = e.target.value;
        this.formService.selectLogisticByWalletAddress(walletAddress);
    }

    driverChange(e) {
        const walletAddress = e.target.value;
        this.formService.selectDriverByWalletAddress(walletAddress);
    }

    farmChange(e) {
        const walletAddress = e.target.value;
        this.formService.selectFarmByWalletAddress(walletAddress);
    }

    changeCategoryCode(categoryCode) {
        this.formService.document.staticData.data.category = categoryCode;
    }

    changeAnimalsQty(animalsQty) {
        this.formService.document.slaughterhouseData.data.animalsQty = animalsQty;
    }

    ngChangeDt(dtVar, e) {
        // console.log('-------');
        // console.log('ngChangeDt: ' + dtVar);
        // console.log('e: ' + e);
        // console.log('e.unix(): ' + e.unix());
        switch (dtVar) {
            case 'departureDt':
                this.formService.document.staticData.data.departureDt = (e && typeof e.unix === 'function' ? e.unix() * 1000 : this.formService.document.staticData.data.departureDt);
                break;
            case 'deliveryDt':
                this.formService.document.staticData.data.deliveryDt = (e && typeof e.unix === 'function' ? e.unix() * 1000 : this.formService.document.staticData.data.deliveryDt);
                break;
            case 'cleanDt':
                this.formService.document.driverData.data.cleanDt = (e && typeof e.unix === 'function' ? e.unix() * 1000 : this.formService.document.driverData.data.cleanDt);
                break;
        }
    }
}
