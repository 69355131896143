import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {DatatableLangService} from '../datatable-lang.service';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import Swal from 'sweetalert2';
import {ChainModel} from '../../models/chain.model';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ChainsService {
    private chainId: number;
    public chain: ChainModel = new ChainModel();
    dataTable: any;
    public chains: any;
    public chainList: any;

    constructor(
        private db: AngularFireDatabase,
        private datatableLang: DatatableLangService,
        private translate: TranslateService
    ) {
        this.chain.clear();
        this.chainList = db.list('chains')
            .snapshotChanges()
            .pipe(
                map(pendingTransaction => {
                    return pendingTransaction.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].id = current.key;
                        return total;
                    }, []);
                })
            );


        $(document).on('click', '.del-chain-btn', (e) => {
            const key = $(e.currentTarget).attr('chain-id');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteChain.warning.title',
                'identity.deleteChain.warning.text',
                'identity.deleteChain.deleted.title',
                'identity.deleteChain.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteChain.warning.title'],
                    text: trans['identity.deleteChain.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.object('chains/' + key).remove()
                            .then(() => {
                                Swal.fire(
                                    trans['identity.deleteChain.deleted.title'],
                                    trans['identity.deleteChain.deleted.text'],
                                    'success'
                                );
                            });
                    }
                });
            });
        });
    }

    renderTable() {
        this.chainList.subscribe(chains => {
            console.log(chains, 'chains');
            const table: any = $('#chains-table');
            this.dataTable = table.DataTable({
                destroy: true,
                scrollX: true,
                language: this.datatableLang.lang,
                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                order: [[1, 'desc']],
                data: chains,
                columns: [
                    {data: 'id', width: '50px'},
                    {data: 'chain', defaultContent: ''},
                    {data: 'contract', defaultContent: ''},
                    {data: 'txMaxMinutes', defaultContent: ''},
                    {data: 'checkInterval', defaultContent: ''},
                    {data: 'active', defaultContent: ''}
                ],
                columnDefs: [
                    {
                        targets: 0,
                        data: 'id',
                        title: 'Actions',
                        orderable: false,
                        render: (id, a, full) => {
                            // language=HTML
                            return `
                            <button data-id="${id}" type="button" class="del-chain-btn btn btn-danger px-3 mr-2">
                                <i class="fas fa-times"></i>
                            </button>
                            <button data-id="${id}" type="button" class="modify-chain-btn btn btn-primary mr-2"
                                data-toggle="modal" data-target="#chain-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            ${full.explorer ?
                                `<a href="${full.explorer}" target="_blank" class="btn btn-secondary">
                                <i class="fas fa-external-link-square-alt"></i>
                            </a>` : ''
                                }

                            `;
                        },
                    },
                    {
                        targets: 5,
                        data: 'active',
                        render: (active) => {
                            if (active) {
                                // language=HTML
                                return `
                                    <span class="badge badge-pill badge-success px-2 pb-2">
                                Active
                                </span>
                                `;
                            } else {
                                return `
                                <span class="badge badge-pill badge-secondary px-2 pb-2">
                                Inactive
                                </span>
                            `;
                            }
                        },
                    },
                ],
                drawCallback: () => {
                    $('[data-toggle="tooltip"]').tooltip();
                }
            });
        });

        $(document).on('click', '.del-chain-btn', (e) => {
            const id = $(e.currentTarget).data('id');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'chains.alerts.deleteChain.warning.title',
                'chains.alerts.deleteChain.warning.text',
                'chains.alerts.deleteChain.deleted.title',
                'chains.alerts.deleteChain.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['chains.alerts.deleteChain.warning.title'],
                    text: trans['chains.alerts.deleteChain.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.value) {
                        this.db.object('chains/' + id).remove()
                            .then(() => {
                                Swal.fire(
                                    trans['chains.alerts.deleteChain.deleted.title'],
                                    trans['chains.alerts.deleteChain.deleted.text'],
                                    'success'
                                );
                            });
                    }
                });
            });
        });

        $(document).on('click', '.modify-chain-btn', (e) => {
            this.chainId = $(e.currentTarget).data('id');
            this.db.list('chains/' + this.chainId).snapshotChanges()
                .pipe(map(items => {
                    return items.reduce((total, current) => {
                        const value = current.payload.val();
                        const key = current.payload.key;
                        total[key] = value;
                        return total;
                    }, {});
                })).subscribe((chain: ChainModel) => {
                this.chain = chain;
                console.log( this.chain, 'modify this.chain');
            });
        });

    }


    saveChain(): any {
        if (!this.chainId) {
            this.db.list('chains').push(JSON.parse(JSON.stringify(this.chain)));
        } else {
            this.db.object('chains/' + this.chainId).update(JSON.parse(JSON.stringify(this.chain)));
        }
    }

    newChain() {
        this.chainId = 0;
        this.chain = new ChainModel();
        this.chain.clear();

        console.log( this.chain, 'newChain this.chain');
    }
}
