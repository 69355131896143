import { Component, OnInit } from '@angular/core';
import {TransactionsService} from '../../../../core/services/transactions.service';
import {MinifyTextPipe} from '../../../../core/pipes/minify-text.pipe';
import * as moment from 'moment';
import {DatatableLangService} from '../../../../core/services/datatable-lang.service';
declare var $: any;

@Component({
  selector: 'app-success-transactions',
  templateUrl: './success-transactions.component.html',
  styleUrls: ['./success-transactions.component.css']
})
export class SuccessTransactionsComponent implements OnInit {
  dataTable: any;

  constructor(
      private transactionsService: TransactionsService,
      private minifyString: MinifyTextPipe,
      private datatableLang: DatatableLangService
  ) {
  }


  ngOnInit() {
    this.transactionsService.successTransactions.subscribe(transactions => {
      const table: any = $('#success-transactions-table');
      this.dataTable = table.DataTable({
        destroy: true,
        scrollX: true,
        language: this.datatableLang.lang,
        lengthMenu: [5, 10, 25, 50],
        pageLength: 10,
        order: [[0, 'desc']],
        data: transactions,
        columns: [
          {
            data: 'creationDt',
            defaultContent: ''
          }, {
            data: 'signature',
            defaultContent: ''
          }, {
            data: 'document',
            defaultContent: ''
          }, {
            data: 'transaction',
            defaultContent: ''
          }, {
            data: 'status',
            defaultContent: ''
          }
        ],
        columnDefs: [
          {
            targets: 0,
            data: 'creationDt',
            render: (creationDt: number) => {
              return moment.unix(parseInt((creationDt / 1000).toString(), 10)).format('YYYY-MM-DD HH:mm:ss');
            },
          },
          {
            targets: 1,
            data: 'signature',
            render: (signature: string) => {
              return this.minifyString.transform(signature);
            },
          },
          {
            targets: 3,
            data: 'transaction',
            render: (transaction: string) => {
              // language=HTML
              return `
                      <a href="https://ropsten.etherscan.io/tx/${transaction}" target="_blank">
                          ${this.minifyString.transform(transaction)}
                      </a>
                      `;
            },
          },
          {
            targets: 4,
            data: 'status',
            render: (status: string, type: string, full: any) => {
              switch (status) {
                case 'pending':
                  return `<span class="badge badge-pill badge-warning">
                                        Pending <span class="recurrent-time" since="${full.creationDt}">00:00</span>
                                    </span>`;
                case 'process':
                  return `<span class="badge badge-pill badge-primary"">
                                        Process <span class="recurrent-time" since="${full.processDt}">00:00</span>
                                    </span>`;
              }
            },
          }
        ],
        drawCallback: () => {
          setInterval(() => {
            $('.recurrent-time').each(function() {
              const since = $(this).attr('since');
              const now = moment.utc().valueOf();
              const difference = now - since;
              const hours = moment.duration(difference).get('hours');
              const minutes = moment.duration(difference).get('minutes');
              const seconds = moment.duration(difference).get('seconds');

              $(this).html(`
                            ${hours ? hours + ':' : ''}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}
                            `);
            });
          }, 1000);
        }
      });
    });
  }

}
