import {Injectable} from '@angular/core';
import {WalletService} from '../wallet.service';
import {ethers} from 'ethers';
import abi from './abi.json';

@Injectable({
    providedIn: 'root'
})
export class ContractService {
    contract: any;

    constructor(
        private walletService: WalletService
    ) {
        const contractAddress = '0x593cc1a399a65d3eaf8316da933745c4f5b94429';
        this.contract = new ethers.Contract(contractAddress, abi, walletService.provider);
    }

    async validate(hash: string): Promise<boolean> {
        return (await this.contract.getHash(hash))[1];
    }
}
