import {ExternalLinkInterface} from '../interfaces/external-link.interface';

export class ExternalLinkModel {
  reference: string;
  url: string;


  constructor(externalLink: ExternalLinkInterface | any) {
    this.reference = externalLink.reference || '';
    this.url = externalLink.url || '';
  }

}



