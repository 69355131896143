import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {IdentityService} from '../../../core/services/identity.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  identity = {
    id: '',
    fiscalName: '',
    type: '',
  };

  decodePassword: string;

  constructor(
      private authService: AuthenticationService,
      public identityService: IdentityService
  ) {
  }

  ngOnInit() {
    this.decodePassword = this.authService.decodePass;
  }

  sendRequest() {
   this.authService.requestIdentity(this.identity);
  }

}
