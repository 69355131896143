import {Component, OnInit, Input} from '@angular/core';
import {DownloadDataService} from '../../../core/services/download-data.service';
import {WalletService} from '../../../core/services/wallet.service';

@Component({
    selector: 'app-download-keystore',
    templateUrl: './download-keystore.component.html',
    styleUrls: ['./download-keystore.component.css']
})
export class DownloadKeystoreComponent implements OnInit {
    @Input() password: string;
    @Input() wallet: any;
    keystoreBtn = {
        type: 'secondary',
        loading: false
    };

    constructor(
        private downloadDataService: DownloadDataService,
        private walletService: WalletService
    ) {
    }

    ngOnInit() {
    }

    async downloadKeystore() {
        this.keystoreBtn.loading = true;
        const json = await this.walletService.generateKeystore(this.password);
        const date = new Date();
        const filename = ['UTC--', date.toJSON().replace(/:/g, '-'), '--', this.wallet.address.toString('hex')].join('');
        const blob = new Blob([json], {type: 'text/json;charset=UTF-8'});
        this.downloadDataService.download(filename, window.URL.createObjectURL(blob));
        this.keystoreBtn.loading = false;
    }
}
