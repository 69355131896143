import { Component, OnInit } from '@angular/core';
import {ProviderInterface} from '../../../../core/interfaces/identity/provider.interface';
import {IdentityService} from '../../../../core/services/identity.service';
import {ProviderModel} from '../../../../core/models/identity/provider.model';

@Component({
  selector: 'app-provider-form',
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.css']
})
export class ProviderFormComponent implements OnInit {

  provider: ProviderInterface = new ProviderModel();

  constructor(
      private identityService: IdentityService
  ) {
  }

  ngOnInit() {
    this.identityService.identity.subscribe((provider: ProviderInterface) => {
      this.provider = new ProviderModel(provider);
    });
  }

  saveIdentity() {
    this.identityService.saveIdentity(this.provider);
  }
}
