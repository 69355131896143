import {Component, OnInit} from '@angular/core';
import {IdentityService} from '../../../core/services/identity.service';

@Component({
    selector: 'app-identity',
    templateUrl: './identity.component.html',
    styleUrls: ['./identity.component.css']
})
export class IdentityComponent implements OnInit {
    identity: any;
    transportsCode: string[];
    transportCode: string;

    constructor(
        private identityService: IdentityService
    ) {
    }

    ngOnInit() {
        this.identityService.identity.subscribe((identity) => {
            this.identity = identity;
            this.transportsCode = identity.transports && Object.keys(identity.transports) || [];
        });
    }
}
