import { Component, OnInit } from '@angular/core';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';
import {FarmModel} from 'src/app/core/models/identity/farm.model';

@Component({
  selector: 'app-modify-farm-form',
  templateUrl: './modify-farm-form.component.html',
  styleUrls: ['./modify-farm-form.component.css']
})
export class ModifyFarmFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new FarmModel(this.modifyIdentityFormService.identity);
  }

  delProvider() {
    this.modifyIdentityFormService.delProvider();
  }

}
