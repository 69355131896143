import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {first, map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {DatatableLangService} from '../datatable-lang.service';
// import {lotInterface} from '../../interfaces/lot/lot';
import {IdentityService} from '../identity.service';
import {LotModel} from '../../models/lot.model';
import {HashService} from '../hash.service';
import {webSocket} from 'rxjs/webSocket';
import {ProductInterface} from '../../interfaces/lot/product.interface';
import Swal from 'sweetalert2';

const subject = webSocket('ws://46.253.45.22:8081');
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LotsService {
  dataTable: any;
  public lots: any = new Observable();
  public lot: any;
  productOrder: number;


  constructor(
    private db: AngularFireDatabase,
    private datatableLang: DatatableLangService,
    private identityService: IdentityService,
    private hashService: HashService
  ) {


    subject.subscribe(
      (msg: any) => {
        if (msg.type === 'success-transaction') {
          this.updateBlokchain();
        }
        console.log(msg, 'msg!!')
      }, // Called whenever there is a message from the server.
      err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete') // Called when connection is closed (for whatever reason).
    );

    this.lots = this.db.list('lots')
      .snapshotChanges()
      .pipe(
        map(lots => {
          return lots.reduce((total, current, i) => {
            total[i] = current.payload.val();
            total[i].id = current.key;
            return total;
          }, []);
        })
      );

    // this.productIdLotExist(234)
    // let now = moment();
    // this.isIntervalAllowed(4000, 5000).then((res) => {
    //   let end = moment();
    //   console.log(res+' isIntervalAllowed ++++++++++++ ' + end.diff(now, 'seconds') + ' seg')
    // })

    // let now1 = moment();
    // this.addProductIdLotInterval(1001, 1021, "30000").then(() => {
    //   let end2 = moment();
    //   console.log('addProductIdLotInterval ++++++++++++ ' + end2.diff(now1, 'seconds') + ' seg')
    // })

    // let now1 = moment();
    // this.delProductIdLotInterval(1000, 10000).then(() => {
    //   let end2 = moment();
    //   console.log('addProductIdLotInterval ++++++++++++ ' + end2.diff(now1, 'seconds') + ' seg')
    // })

    // this.db.list(
    //   'lots',
    //   ref => ref.orderByChild('intervals/start').startAt(1000).endAt(2000)
    // )
    //   .valueChanges().subscribe(data => console.log(data, 'data+-+-+-+--+---+-'));
    //
    //
    // this.db.list(
    //   'productIdLot',
    //   ref => ref.orderByChild('productId').startAt(1000).endAt(20000)
    // )
    //   .valueChanges().subscribe(data => console.log(data, 'data+-+-+-+--+---+-'));


  }

  renderTable() {
    this.identityService.identity.subscribe((identity: any) => {


      this.lots.subscribe(lots => {
        const table: any = $('#lots-table');
        this.dataTable = table.DataTable({
          destroy: true,
          scrollX: true,
          language: this.datatableLang.lang,
          lengthMenu: [5, 10, 25, 50],
          pageLength: 10,
          order: [[3, 'desc']],
          data: lots,
          columns: [
            {data: 'id', width: '50px'},
            {data: 'id', defaultContent: '', type: 'string'},
            {data: 'intervals', defaultContent: ''},
            {data: 'date', defaultContent: ''},
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'id',
              title: 'Actions',
              orderable: false,
              render: (id, type, full) => {
                // language=HTML
                return `
                            <button lot-id="${id}" type="button" class="edit-lot-btn btn btn-primary"
                                data-toggle="modal" data-target="#new-lot-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            ${identity.type === 'ADMIN' ? `<button lot-id="${id}" type="button" class="del-lot-btn btn btn-danger">
                                <i class="fas fa-times"></i>
                            </button>` : ''}
                            ${full?.blockchain?.tx ?
                  `<button type="button" class="btn btn-secondary" disabled>
                                <i class="fas fa-check"></i>
                            </button>` :
                  `<button lot-id="${id}" type="button" class="blockchain-lot-btn btn btn-success">
                                <i class="fas fa-check"></i>
                            </button>`
                }


                            `;
              },
            },
            {
              targets: 1,
              data: 'id',
              render: (id, type, full) => {
                // language=HTML
                return id ? `
                                <div class="indicators">
                                   ${full?.blockchain?.tx ?
                  `<span data-toggle="tooltip" data-placement="top"
                                    title="${moment(full?.blockchain?.creationDt, 'x').format('DD/MM/YYYY HH:mm')}"
                                     class="badge badge-pill badge-success">B</span>` : '<span class="badge badge-pill badge-secondary">B</span>'}
                                </div>
                                ${id}
                            ` : '';
              },
            },
            {
              targets: 2,
              data: 'intervals',
              render: (intervals = [], type, full) => {
                console.log({intervals})

                // language=HTML
                const productsNames = [];
                for (const interval of intervals) {
                  if(interval.product && !productsNames.includes(interval.product.name)){
                    productsNames.push(interval.product.name)
                  }
                }

                return productsNames ? productsNames.join(', '): '';
              },
            },
            {
              targets: 3,
              data: 'date',
              render: (datetime, type) => {
                // language=HTML
                if (type === 'display')
                  return datetime ? `
                                <i class="far fa-calendar-alt"></i> ${moment(datetime, 'x').format('DD/MM/YYYY')}
                                <i class="far fa-clock"></i> ${moment(datetime, 'x').format('HH:mm')}
                            ` : '';
                else
                  return datetime;
              },
            }
          ],
          drawCallback: () => {
            $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
            console.log(this, 'THIS')
            this.updateBlokchain();

          }
        });
      });


    });

  }

  updateBlokchain() {
    const api = $('#lots-table').dataTable().api();
    console.log(api, 'apiii')
    // Output the data for the visible rows to the browser's console
    console.log(api.rows({page: 'current'}).data(), 'dataaaa');

    api.rows({page: 'current'}).data().each((lot) => {
      console.log(lot, 'aaaa')
      const hash = lot.blockchain?.hash;
      const tx = lot.blockchain?.tx;
      if (hash && !tx) {

        this.hashService.getHash(hash).then((data) => {
          if (data)
            this.db.object(`lots/${lot.id}/blockchain`).update(data);
          console.log(data, 'getHash data2')
        })
      }
    })
  }

  getLot(id) {
    return this.lot = this.db.list('lots/' + id).snapshotChanges()
      .pipe(map(items => {
          return items.reduce((total, current) => {
            const value = current.payload.val();
            const key = current.payload.key;
            total[key] = value;
            return total;
          }, {});
        })
      );
  }

  isIntervalAllowed(start: number, end: number) {
    return new Promise(async (resolve, reject) => {
      const startId = start <= end ? start : end;
      const endId = end > start ? end : start;

      this.db.list(
        'productIdLot',
        ref => ref.orderByChild('productId').startAt(startId).endAt(endId)
      )
        .valueChanges().pipe(first()).subscribe(data => {
        console.log(data, 'productIdLot')
        if (data.length) {
          resolve(false)
        } else {
          resolve(true)
        }

      }, error => {
        resolve(false);
      });


      //
      //
      // const allPromises = [];
      // while (startId <= endId) {
      //   console.log(startId, 'startId!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
      //   allPromises.push(this.productIdLotExist(startId))
      //   startId++;
      // }
      // Promise.all(allPromises)
      //   .then(() => resolve(true))
      //   .catch(() => resolve(false))

    })
  }

  // addProductIdLotInterval(start: number, end: number) {
  //   return new Promise(async (resolve, reject) => {
  //     let startId = start <= end ? start : end;
  //     const endId = end > start ? end : start;
  //     const allPromises = [];
  //     while (startId <= endId) {
  //       console.log(startId, 'New ID !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
  //       allPromises.push(this.newProductIdLot(startId, 2000));
  //       startId++;
  //     }
  //     Promise.all(allPromises).then((data) => {
  //       console.log(data, 'dataa')
  //       resolve(true)
  //
  //     })
  //
  //   })
  // }

  delProductIdLotInterval(start: number, end: number) {
    return new Promise(async (resolve, reject) => {
      let startId = start <= end ? start : end;
      const endId = end > start ? end : start;

      const allProducts = {};

      let timerInterval;
      await Swal.fire({
        icon: 'warning',
        title: 'Processing products',
        html: 'We are deleting your processing please wait',
        didOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(() => {
            while (startId <= endId) {
              allProducts[startId] = null;
              startId++;
            }
            Swal.close()
          }, 100)
        },
        willClose: () => {
          this.db.object('productIdLot').update(JSON.parse(JSON.stringify(allProducts))).then((data) => {

            resolve(data);
          }).catch((error) => {

            reject(error);
          });
          clearInterval(timerInterval)
        }


      });
    });
  }

  addProductIdLotInterval(start: number, end: number, lotId: string, product: ProductInterface) {
    return new Promise(async (resolve, reject) => {
      let startId = start <= end ? start : end;
      const endId = end > start ? end : start;
      const allProducts = {};
      let timerInterval;
      await Swal.fire({
        icon: 'warning',
        title: 'Creating products',
        html: 'We are creating your products please wait',
        didOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(() => {
            while (startId <= endId) {
              allProducts[startId] = {
                productId: startId,
                lotId,
                number: ++this.productOrder,
                product,
                interval: {start, end}
              };
              // $('#products').html('Adding product ' + startId);
              startId++;
            }

            Swal.close()
          }, 100)
        },
        willClose: () => {
          // console.log(allProducts, 'allProducts')
          this.db.object('productIdLot').update(JSON.parse(JSON.stringify(allProducts))).then((data) => {
            console.log(data, 'data')
            resolve(data);
          }).catch((error) => {
            console.error(error, 'error')
            // reject(error);
            resolve(error);
          });
          clearInterval(timerInterval)
        }
      })

    })
  }


  async saveLot(lot: LotModel, oldLot: LotModel) {
    try {

      console.log(lot, 'lot')
      console.log(oldLot, 'oldLot')


      for (const interval of oldLot.intervals) {
        const start = interval.start;
        const end = interval.end;
        const isAllowed = await this.isIntervalAllowed(start, end)
        console.log(isAllowed, 'isAllowed')
        if (!isAllowed)
          await this.delProductIdLotInterval(start, end)

      }


      if (!lot.id) {
        lot.id = await this.getNewLotId();
      }

      this.db.object('lots/' + lot.id).update(JSON.parse(JSON.stringify(lot)));

      this.productOrder = 0;
      for (const interval of lot.intervals) {
        const start = interval.start;
        const end = interval.end;
        // this.isIntervalAllowed(start, end).then(() => {
        console.log('start test')
        let test = await this.addProductIdLotInterval(start, end, lot.id, interval.product)
        console.log(test, 'test1')
        // })
      }
    } catch (e) {
      console.log(e, 'e')
    }

  }

  getNewLotId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db.object('counters/lots').valueChanges().pipe(first()).subscribe((lotCounter: string) => {
        const newLotCounter = parseInt(lotCounter || '0', 10) + 1;
        this.db.object('counters/lots').set(newLotCounter);
        resolve(newLotCounter.toString());
      }, error => {
        reject(error)
      })
    })

  }


}
