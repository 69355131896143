import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class TransactionsService {
    pendingTransactions: any;
    successTransactions: any;
    lastTransactionDuration: any;
    transactionInfo: any;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.pendingTransactions = db.list('pendingTransactions')
            .snapshotChanges()
            .pipe(
                map(pendingTransaction => {
                    return pendingTransaction.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].signature = current.key;

                        return total;
                    }, []);
                })
            );

        this.successTransactions = db.list('successTransactions')
            .snapshotChanges()
            .pipe(
                map(successTransaction => {
                    return successTransaction.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].signature = current.key;
                        return total;
                    }, []);
                })
            );

        db.object('charts/lastTransactionDuration')
            .valueChanges().subscribe(lastTransactionDuration => {
            if (lastTransactionDuration) {
                this.lastTransactionDuration = lastTransactionDuration;
            }
        });

        this.transactionInfo = db.list('parameters')
            .snapshotChanges().pipe(
                map((parameters: any) => {
                    return parameters.reduce((total, current) => {
                        const value = current.payload.val();
                        const key = current.payload.key;
                        const alowedParams = ['transactionGas', 'gasPriceGwei'];

                        if (alowedParams.includes(key)) {
                            total[key] = value;
                        }
                        return total;
                    }, {});
                })
            );
    }

    delSignature(signature) {
        this.db.object(`pendingTransactions/${signature}`).remove();
    }

    retrySignature(signature) {
        this.db.list('pendingTransactions/' + signature).snapshotChanges()
            .pipe(map(items => {
                    return items.reduce((total, current) => {
                        const value = current.payload.val();
                        const key = current.payload.key;
                        total[key] = value;
                        return total;
                    }, {});
                })
            ).subscribe((signatureData) => {
            this.db.object(`pendingTransactions/${signature}`).remove();
            this.db.object(`pendingTransactions/${signature}`).set(signatureData);
        });

    }
}
