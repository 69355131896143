import {Component, OnInit} from '@angular/core';
import {ProductsService} from '../../../core/services/products/products.service';
import {ProductFormService} from '../../../core/services/products/product-form.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  constructor(
    public productsService: ProductsService,
    public productFormService: ProductFormService,
  ) {
  }

  ngOnInit() {
    this.productsService.renderTable();
  }

  newProduct() {
    this.productFormService.newProduct();
  }


}
