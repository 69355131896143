import {Component, OnInit} from '@angular/core';
import {IdentityService} from '../../../../core/services/identity.service';

@Component({
    selector: 'app-providers-requests',
    templateUrl: './providers-requests.component.html',
    styleUrls: ['./providers-requests.component.css']
})
export class ProvidersRequestsComponent implements OnInit {
    providersRequests: any[];

    constructor(
        private identityService: IdentityService,
    ) {
    }

    ngOnInit() {
        this.identityService.identity.subscribe((identity) => {
            this.identityService.getProvidersRequest(identity).subscribe((providersRequests) => {
                this.providersRequests = providersRequests;
            });
        });
    }

    delProviderRequest(key: string) {
        this.identityService.delProviderRequest(key);
    }

    acceptProviderRequest(providerRequest: any) {
        this.identityService.acceptProviderRequest(providerRequest);
    }
}
