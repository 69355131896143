import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {DatatableLangService} from '../datatable-lang.service';
// import {lotTemplateInterface} from '../../interfaces/product/product';
import {IdentityService} from '../identity.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LotsTemplatesService {
  dataTable: any;
  public lotsTemplates: any = new Observable();
  public lotTemplate: any;


  constructor(
    private db: AngularFireDatabase,
    private datatableLang: DatatableLangService,
    private identityService: IdentityService
  ) {

      this.lotsTemplates = this.db.list('lotsTemplates')
        .snapshotChanges()
        .pipe(
          map(lotsTemplates => {
            return lotsTemplates.reduce((total, current, i) => {
              total[i] = current.payload.val();
              total[i].id = current.key;
              return total;
            }, []);
          })
        );
  }

  renderTable() {
    this.identityService.identity.subscribe((identity: any) => {


      this.lotsTemplates.subscribe(lotsTemplates => {
        console.log(lotsTemplates, 'lotsTemplates')
        const table: any = $('#lots-templates-table');
        console.log(table, 'table')
        this.dataTable = table.DataTable({
          destroy: true,
          scrollX: true,
          language: this.datatableLang.lang,
          lengthMenu: [5, 10, 25, 50],
          pageLength: 10,
          order: [[1, 'asc']],
          data: lotsTemplates || [],
          columns: [
            {data: 'id', width: '50px'},
            {data: 'name', defaultContent: '', type: 'string'},
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'id',
              title: 'Actions',
              orderable: false,
              render: (id) => {
                // language=HTML
                return `
                            <button lot-template-id="${id}" type="button" class="edit-lot-template-btn btn btn-primary"
                                data-toggle="modal" data-target="#new-lot-template-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            ${identity.type === 'ADMIN' ? `<button lot-template-id="${id}" type="button" class="del-lot-template-btn btn btn-danger">
                                <i class="fas fa-times"></i>
                            </button>` : ''}
                            `;
              },
            }
          ],
          drawCallback: () => {
            $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
          }
        });
      });


    });

  }

  getLotTemplate(id) {
    return this.lotTemplate = this.db.list('lotsTemplates/' + id).snapshotChanges()
      .pipe(map(items => {
          return items.reduce((total: any, current) => {
            const value = current.payload.val();
            const key = current.payload.key;
            console.log(value, 'value')
            console.log(key, 'key')
            total[key] = value;
            total.id = id;
            return total;
          }, {});
        })
      );
  }

  async saveLotTemplate(lotTemplate, oldId = null) {
    if (oldId) {
      this.db.object('lotsTemplates/' + lotTemplate.id).update(JSON.parse(JSON.stringify(lotTemplate)));
    } else {
      this.db.list('lotsTemplates').push(JSON.parse(JSON.stringify(lotTemplate)));
    }
  }


}
