import {Injectable} from '@angular/core';
import {PendingIdentityInterface} from '../../interfaces/identity/pending-identity';
import {PendingIdentityModel} from '../../models/identity/pending-identity.model';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PendingIdentityFormService {
    selectedPendingIdentityKey: number;
    pendingIdentity: PendingIdentityInterface;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.pendingIdentity = new PendingIdentityModel();
    }

    savePendingIdentity() {
        if (!this.selectedPendingIdentityKey) {
            this.db.list('pendingIdentities').push(JSON.parse(JSON.stringify(this.pendingIdentity)));
        } else {
            this.db.object('pendingIdentities/' + this.selectedPendingIdentityKey).update(JSON.parse(JSON.stringify(this.pendingIdentity)));
        }
    }

    newPendingIdentity() {
        this.selectedPendingIdentityKey = null;
        this.pendingIdentity = new PendingIdentityModel();
    }

    loadPendingIdentity(key) {
        this.selectedPendingIdentityKey = key;

        this.db.list('pendingIdentities/' + key).snapshotChanges()
            .pipe(map(items => {
                    return items.reduce((total, current) => {
                        total[current.payload.key] = current.payload.val();
                        return total;
                    }, {});
                })
            )
            .subscribe((pendingIdentity: PendingIdentityInterface) => {
                this.pendingIdentity = new PendingIdentityModel(pendingIdentity);
            });
    }
}
