import { Component, OnInit } from '@angular/core';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';
import {SlaughterhouseModel} from '../../../../../core/models/identity/slaughterhouse.model';

@Component({
  selector: 'app-modify-slaughterhouse-form',
  templateUrl: './modify-slaughterhouse-form.component.html',
  styleUrls: ['./modify-slaughterhouse-form.component.css']
})
export class ModifySlaughterhouseFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new SlaughterhouseModel(this.modifyIdentityFormService.identity);
  }

}
