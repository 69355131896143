import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireStorage} from '@angular/fire/storage';
import Swal from 'sweetalert2';
import {FileModel} from '../../../core/models/file.model';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {finalize} from 'rxjs/operators';


@Component({
  selector: 'app-drag-drop-uploader-modal',
  templateUrl: './drag-drop-uploader-modal.component.html',
  styleUrls: ['./drag-drop-uploader-modal.component.css']
})
export class DragDropUploaderModalComponent implements OnInit {
  @Input() public folder;
  public files: NgxFileDropEntry[] = [];
  allFiles = [];


  constructor(
    public activeModal: NgbActiveModal,
    private storage: AngularFireStorage
  ) {


  }

  ngOnInit(): void {

    // console.log(this.folder, 'this.folder')

    // this.storage.ref(this.folder).listAll().subscribe((res) => {
    //   // console.log(res, 'refs')
    //   // res.prefixes.forEach((folderRef) => {
    //   //
    //   //   console.log(folderRef, 'folderRef')
    //   //   console.log(folderRef.name, 'folderRef.name')
    //   //   console.log(folderRef.getMetadata(), 'folderRef.getMetaData()')
    //   //
    //   //
    //   //   // All the prefixes under listRef.
    //   //   // You may call listAll() recursively on them.
    //   // });
    //
    //   res.items.forEach((itemRef) => {
    //
    //     itemRef.getMetadata().then((file) => {
    //       itemRef.getDownloadURL().then((url) => {
    //         const name = file.name;
    //         const path = file.fullPath;
    //         const size = file.size;
    //         const type = file.contentType;
    //         this.files.push(new FileModel({name, url, path, size, type}));
    //
    //       })
    //     })
    //
    //     // All the items under listRef.
    //   });
    //
    // });
  }


  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    console.log(this.allFiles, 'this.allFiles')
    this.activeModal.close(files);
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  addFiles() {

  }
}
