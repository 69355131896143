import {Component, OnInit} from '@angular/core';
import {LotsTemplatesService} from '../../../core/services/lots-templates/lots-templates.service';
import {LotTemplateFormService} from '../../../core/services/lots-templates/lot-template-form.service';

@Component({
  selector: 'app-lots-templates',
  templateUrl: './lots-templates.component.html',
  styleUrls: ['./lots-templates.component.css']
})
export class LotsTemplatesComponent implements OnInit {

  constructor(
    public lotsTemplatesService: LotsTemplatesService,
    public lotTemplateFormService: LotTemplateFormService,
  ) {
  }

  ngOnInit() {
    this.lotsTemplatesService.renderTable();
  }

  newLotTemplate() {
    this.lotTemplateFormService.newLotTemplate();
  }


}
