import {Component, OnInit} from '@angular/core';
import {VetInterface} from '../../../../core/interfaces/identity/vet.interface';
import {IdentityService} from '../../../../core/services/identity.service';
import {VetModel} from '../../../../core/models/identity/vet.model';

@Component({
    selector: 'app-vet-form',
    templateUrl: './vet-form.component.html',
    styleUrls: ['./vet-form.component.css']
})
export class VetFormComponent implements OnInit {
    vet: VetInterface = new VetModel();

    constructor(
        private identityService: IdentityService
    ) {
    }

    ngOnInit() {
        this.identityService.identity.subscribe((vet: VetInterface) => {
            this.vet = new VetModel(vet);
        });
    }

    saveIdentity() {
        this.identityService.saveIdentity(this.vet);
    }

}
