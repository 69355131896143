import { Component, OnInit } from '@angular/core';
import {DocumentFormService} from '../../../../../core/services/documents/document-form.service';

@Component({
  selector: 'app-vet-section',
  templateUrl: './vet-section.component.html',
  styleUrls: ['./vet-section.component.css']
})
export class VetSectionComponent implements OnInit {

  constructor(
      public formService: DocumentFormService
  ) {
  }

  ngOnInit() {
  }

  sign() {
    this.formService.sign('VET');
  }

  vetChange(e) {
    const walletAddress = e.target.value;
    this.formService.selectVetByWalletAddress(walletAddress);
  }
}
