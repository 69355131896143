import {Component, OnInit} from '@angular/core';
import {RawMaterialsService} from '../../../core/services/raw-materials/raw-materials.service';
import {RawMaterialFormService} from '../../../core/services/raw-materials/raw-material-form.service';

@Component({
  selector: 'app-raw-materials',
  templateUrl: './raw-materials.component.html',
  styleUrls: ['./raw-materials.component.css']
})
export class RawMaterialsComponent implements OnInit {

  constructor(
    public rawMaterialsService: RawMaterialsService,
    public rawMaterialFormService: RawMaterialFormService,
  ) {
  }

  ngOnInit() {
    this.rawMaterialsService.renderTable();
  }

  newRawMaterial() {
    this.rawMaterialFormService.newRawMaterial();
  }


}
