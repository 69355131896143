import {Component, OnInit} from '@angular/core';
import {LotTemplateFormService} from '../../../../core/services/lots-templates/lot-template-form.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {LotTemplateInterface} from '../../../../core/interfaces/lot/lot-template.interface';
import {LotTemplateModel} from '../../../../core/models/lot-template.model';
import {ExternalLinkModalComponent} from '../../external-link-modal/external-link-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FieldModalComponent} from '../../../partials/field-modal/field-modal.component';
import * as randomstring from 'randomstring';

@Component({
  selector: 'app-lot-template-form',
  templateUrl: './lot-template-form.component.html',
  styleUrls: ['./lot-template-form.component.css']
})
export class LotTemplateFormComponent implements OnInit {
  newExternalLink: string;
  newSection: string;

  constructor(
    public formService: LotTemplateFormService,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {

  }

  saveLotTemplate() {
    this.formService.saveLotTemplate();

    this.translate.get([
      'generic.congratulations',
      'generic.successfullySaved',
    ]).subscribe((trans: any) => {
      Swal.fire({
        icon: 'success',
        title: trans['generic.congratulations'],
        text: trans['generic.successfullySaved'],
        timer: 2000
      });
    });
  }


  addNewSection() {
    this.formService.lotTemplate.additionalFields.sections.push({id: randomstring.generate(20),section: this.newSection, fields: []});
    this.newSection = null;
  }

  delSection(sectionIndex: number) {
    this.formService.lotTemplate.additionalFields.sections.splice(sectionIndex, 1);
  }

  addNewField(sectionIndex: number) {
    const modalRef = this.modalService.open(FieldModalComponent, {scrollable: true});
    modalRef.result.then((field) => {
      this.formService.lotTemplate.additionalFields.sections[sectionIndex].fields.push(field);
    })
  }

  delField(sectionIndex: number, fieldIndex: number) {
    this.translate.get([
      'generic.yes',
      'generic.no',
      'lotTemplate.deleteField.warning.title',
      'lotTemplate.deleteField.warning.text',
      'lotTemplate.deleteField.deleted.title',
      'lotTemplate.deleteField.deleted.text',
    ]).subscribe((trans: any) => {
      Swal.fire({
        title: trans['lotTemplate.deleteField.warning.title'],
        text: trans['lotTemplate.deleteField.warning.text'],
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: trans['generic.yes'],
        cancelButtonText: trans['generic.no']
      }).then((result) => {
        if (result.isConfirmed) {
          this.formService.lotTemplate.additionalFields.sections[sectionIndex].fields.splice(fieldIndex, 1);

          Swal.fire(
            trans['lotTemplate.deleteField.deleted.title'],
            trans['lotTemplate.deleteField.deleted.text'],
            'success'
          );
        }
      });
    });
  }
}
