import {Component, OnInit} from '@angular/core';
import {ProductFormService} from '../../../../core/services/products/product-form.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {ProductInterface} from '../../../../core/interfaces/lot/product.interface';
import {ProductModel} from '../../../../core/models/product.model';
import {ExternalLinkModalComponent} from '../../external-link-modal/external-link-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FieldModalComponent} from '../../../partials/field-modal/field-modal.component';
import {AngularFireStorage} from '@angular/fire/storage';
import {FileInterface} from '../../../../core/interfaces/file.interface';
import {FileModel} from '../../../../core/models/file.model';
import * as randomstring from 'randomstring';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit {
  newExternalLink: string;
  newSection: string;
  files: FileInterface[];

  constructor(
    public formService: ProductFormService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private storage: AngularFireStorage
  ) {
  }

  ngOnInit() {
 this.storage.ref('').listAll().subscribe((res) => {
   this.files = [];
      // console.log(res, 'refs')
      // res.prefixes.forEach((folderRef) => {
      //
      //   console.log(folderRef, 'folderRef')
      //   console.log(folderRef.name, 'folderRef.name')
      //   console.log(folderRef.getMetadata(), 'folderRef.getMetaData()')
      //
      //
      //   // All the prefixes under listRef.
      //   // You may call listAll() recursively on them.
      // });

      res.items.forEach((itemRef) => {

        itemRef.getMetadata().then((file) => {
          itemRef.getDownloadURL().then((url) => {
            const name = file.name;
            const path = file.fullPath;
            const size = file.size;
            const type = file.contentType;
            this.files.push(new FileModel({name, url, path, size, type}));
          })
        })

        // All the items under listRef.
      });

    });
  }

   sameFileName(file1: FileInterface, file2: FileInterface) {
    return file1?.name === file2?.name
  }

  saveProduct() {
    this.formService.saveProduct();

    this.translate.get([
      'generic.congratulations',
      'generic.successfullySaved',
    ]).subscribe((trans: any) => {
      Swal.fire({
        icon: 'success',
        title: trans['generic.congratulations'],
        text: trans['generic.successfullySaved'],
        timer: 2000
      });
    });
  }

  addNewExternalLink() {
    const modalRef = this.modalService.open(ExternalLinkModalComponent, {scrollable: true});
    modalRef.result.then((externalLink) => {
      this.formService.product.externalLinks.push(externalLink);
    })
  }

  delExternalLink(i: number) {
    this.formService.product.externalLinks.splice(i, 1);
  }


  addNewSection() {
    this.formService.product.additionalFields.sections.push({
      id: randomstring.generate(20),
      section: this.newSection,
      fields: []});
    this.newSection = null;
  }

  delSection(sectionIndex: number) {
    this.formService.product.additionalFields.sections.splice(sectionIndex, 1);
  }

  addNewField(sectionIndex: number) {
    const modalRef = this.modalService.open(FieldModalComponent, {scrollable: true});
    modalRef.result.then((field) => {
      console.log(this.formService.product.additionalFields.sections[sectionIndex], 'this.formService.product.additionalFields.sections[sectionIndex]')
      if (!this.formService.product.additionalFields.sections[sectionIndex].fields) {
        this.formService.product.additionalFields.sections[sectionIndex].fields = [];
      }
      this.formService.product.additionalFields.sections[sectionIndex].fields.push(field);
    })
  }

  delField(sectionIndex: number, fieldIndex: number) {
    this.translate.get([
      'generic.yes',
      'generic.no',
      'product.deleteField.warning.title',
      'product.deleteField.warning.text',
      'product.deleteField.deleted.title',
      'product.deleteField.deleted.text',
    ]).subscribe((trans: any) => {
      Swal.fire({
        title: trans['product.deleteField.warning.title'],
        text: trans['product.deleteField.warning.text'],
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: trans['generic.yes'],
        cancelButtonText: trans['generic.no']
      }).then((result) => {
        if (result.isConfirmed) {
          this.formService.product.additionalFields.sections[sectionIndex].fields.splice(fieldIndex, 1);

          Swal.fire(
            trans['product.deleteField.deleted.title'],
            trans['product.deleteField.deleted.text'],
            'success'
          );
        }
      });
    });
  }

  moveSection(from, to) {
    // remove `from` item and store it
    const f = this.formService.product.additionalFields.sections.splice(from, 1)[0];
    // insert stored item into position `to`
    this.formService.product.additionalFields.sections.splice(to, 0, f);
  }

  moveField(sectionIndex, from, to) {
    // remove `from` item and store it
    const f = this.formService.product.additionalFields.sections[sectionIndex].fields.splice(from, 1)[0];
    // insert stored item into position `to`
    this.formService.product.additionalFields.sections[sectionIndex].fields.splice(to, 0, f);
  }
}
