import {getVal} from '../../utils/getNestedObject';
import {LogisticInterface} from '../../interfaces/identity/logistic.interface';

export class LogisticModel {
    id: string;
    fiscalName: string;
    commercialName: string;
    email: string;
    mobile: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
    country: string;
    token: string;
    transports: {[registrationNumber: string]: boolean}[];
    type: 'LOGISTIC';
    walletAddress: string;
    delegatedGas: boolean;
    active: boolean;

    constructor(
        logistic?: LogisticInterface
    ) {
        this.id = getVal(logistic, 'id') || '';
        this.fiscalName = getVal(logistic, 'fiscalName') || '';
        this.commercialName = getVal(logistic, 'commercialName') || '';
        this.email = getVal(logistic, 'email') || '';
        this.mobile = getVal(logistic, 'mobile') || '';
        this.phone = getVal(logistic, 'phone') || '';
        this.address = getVal(logistic, 'address') || '';
        this.city = getVal(logistic, 'city') || '';
        this.state = getVal(logistic, 'state') || '';
        this.province = getVal(logistic, 'province') || '';
        this.postalCode = getVal(logistic, 'postalCode') || '';
        this.country = getVal(logistic, 'country') || '';
        this.token = getVal(logistic, 'token') || '';
        this.transports = getVal(logistic, 'transports') || '';
        this.type = getVal(logistic, 'type') || '';
        this.walletAddress = getVal(logistic, 'walletAddress') || '';
        this.delegatedGas = getVal(logistic, 'delegatedGas') || true;
        this.active = getVal(logistic, 'active') || true;
    }
}
