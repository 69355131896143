import {Component, OnInit} from '@angular/core';
import {AdminInterface} from '../../../../core/interfaces/identity/admin.interface';
import {IdentityService} from '../../../../core/services/identity.service';
import {AdminModel} from '../../../../core/models/identity/admin.model';

@Component({
    selector: 'app-admin-form',
    templateUrl: './admin-form.component.html',
    styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent implements OnInit {
    admin: AdminInterface = new AdminModel();

    constructor(
        private identityService: IdentityService
    ) {
    }

    ngOnInit() {
        this.identityService.identity.subscribe((admin: AdminInterface) => {
            this.admin = new AdminModel(admin);
        });
    }

    saveIdentity() {
        this.identityService.saveIdentity(this.admin);
    }
}
