import {Component, OnInit} from '@angular/core';
import {DocumentsService} from 'src/app/core/services/documents/documents.service';
import {DocumentFormService} from '../../../core/services/documents/document-form.service';


@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

    constructor(
        private documentsService: DocumentsService,
        public formService: DocumentFormService
    ) {

    }

    ngOnInit() {
        this.documentsService.renderTable();
    }

    newDocument() {
        this.formService.newDocument();
    }


}
