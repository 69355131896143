import { Component, OnInit } from '@angular/core';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';
import {ProviderModel} from 'src/app/core/models/identity/provider.model';

@Component({
  selector: 'app-modify-provider-form',
  templateUrl: './modify-provider-form.component.html',
  styleUrls: ['./modify-provider-form.component.css']
})
export class ModifyProviderFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new ProviderModel(this.modifyIdentityFormService.identity);
  }

}
