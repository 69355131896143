import { Component, OnInit } from '@angular/core';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';
import {DriverModel} from 'src/app/core/models/identity/driver.model';

@Component({
  selector: 'app-modify-driver-form',
  templateUrl: './modify-driver-form.component.html',
  styleUrls: ['./modify-driver-form.component.css']
})
export class ModifyDriverFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new DriverModel(this.modifyIdentityFormService.identity);
  }

  delLogistic() {
    this.modifyIdentityFormService.delLogistic();
  }

}
