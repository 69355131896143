import {Component, OnInit} from '@angular/core';
import {PendingIdentityFormService} from '../../../../core/services/identities/pending-identity-form.service';


@Component({
    selector: 'app-pending-identity-form',
    templateUrl: './pending-identity-form.component.html',
    styleUrls: ['./pending-identity-form.component.css']
})
export class PendingIdentityFormComponent implements OnInit {

    constructor(
        public formService: PendingIdentityFormService
    ) {
    }

    ngOnInit() {
    }

    savePendingIdentity() {
        this.formService.savePendingIdentity();
    }
}
