import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {ChartsService} from '../../../../core/services/charts.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-earnings-chart',
    templateUrl: './earnings-chart.component.html',
    styleUrls: ['./earnings-chart.component.css']
})
export class EarningsChartComponent implements OnInit {
    public barChartOptions: object;
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: ChartDataSets[];

    constructor(
        public chartsService: ChartsService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.loadChart();
        this.chartsService.interval$.subscribe(() => {
            this.loadChart();
        });
    }

    loadChart() {
        this.translate.get(['charts.expenses', 'charts.profit']).subscribe((trans: any) => {
            this.barChartData = [
                {
                    data: [],
                    label: trans['charts.expenses'],
                    stack: 'a',
                    backgroundColor: '#ffa1b5',
                    hoverBackgroundColor: '#ff6384',
                    borderColor: '#ff6384'
                },
                {
                    data: [],
                    label: trans['charts.profit'],
                    stack: 'a',
                    backgroundColor: '#91d7d7',
                    hoverBackgroundColor: '#4bc0c0',
                    borderColor: '#4bc0c0'
                }
            ];
        });

        this.chartsService.earnings.subscribe((earnings) => {
            const profit = [];
            const expenses = [];
            const labels = [];
console.log(earnings, 'earnings');
            for (const earning of earnings) {
                labels.push(earning.date);
                profit.push(earning.profit);
                expenses.push(earning.expenses);
            }

            this.barChartOptions = {
                responsive: true,
                scales: {
                    xAxes: [{}], yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true
                        },
                        minBarLength: 3
                    }]
                }
            };

            this.barChartData[0].data = expenses;
            this.barChartData[1].data = profit;
            this.barChartLabels = labels;
        });
    }

}
