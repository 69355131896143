import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit, Output,
} from '@angular/core';
import {finalize, first} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import Swal from 'sweetalert2';
import {FileInterface} from 'src/app/core/interfaces/file.interface';
import {FileModel} from '../../../core/models/file.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-file-select-input',
  templateUrl: './file-select-input.component.html',
  styleUrls: ['./file-select-input.component.css']
})
export class FileSelectInputComponent implements OnInit, OnChanges {
  @Input() file: FileInterface;
  @Input() type: null | 'img' | 'pdf';
  @Output() fileChange: EventEmitter<FileInterface> = new EventEmitter();
  @Input() folder: string;
  @Input() enableSelect: boolean;
  files: FileInterface[];


  constructor(
    private storage: AngularFireStorage,
  ) {
    this.getFiles().then((files: FileInterface[]) => this.files = files);
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.fileChange.emit(this.file);
  }

  getFiles() {
    return new Promise((resolve, reject) => {
      this.storage.ref('').listAll().pipe(first()).subscribe(async (res) => {
        const files = [];
        console.log(res.items, 'res.items')
        console.log(res.items.length, 'res.items.length')

        for (const itemRef of res.items) {
          const file = await itemRef.getMetadata();
          const url = await itemRef.getDownloadURL();

          const name = file.name;
          const path = file.fullPath;
          const size = file.size;
          const type = file.contentType;
          files.push(new FileModel({name, url, path, size, type}));
        }

        resolve(files)
        // res.items.forEach((itemRef) => {
        // });
      });
    })
  }

  sameFileName(file1: FileInterface, file2: FileInterface) {
    return file1?.name === file2?.name
  }

}
