import {Component, OnInit} from '@angular/core';
import {DocumentInterface} from '../../../../core/interfaces/document/document';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentsService} from '../../../../core/services/documents/documents.service';
import {DocumentsHistoricService} from '../../../../core/services/documents/documents-historic.service';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.css']
})
export class DocumentModalComponent implements OnInit {
  document: DocumentInterface;
  documents: DocumentInterface[];
  lotQuantity: number;
  lotWeight: number;
  lotWeightUnit = 'kg';

  constructor(
    public activeModal: NgbActiveModal,
    public documentsService: DocumentsHistoricService,
  ) {
  }

  ngOnInit(): void {
    this.documentsService.documents.subscribe((documents: DocumentInterface[]) => {
      this.documents = documents;
      console.log({documents});
    })
  }


  save() {
    this.activeModal.close({
      ...this.document,
      lotQuantity: this.lotQuantity,
      lotWeight: this.lotWeight,
      lotWeightUnit: this.lotWeightUnit
    });
  }
}
