import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {IdentityService} from '../identity.service';
import Swal from 'sweetalert2';
import {first} from 'rxjs/operators';
import {DatatableLangService} from '../datatable-lang.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class LogisticDriversService {
    dataTable: any;


    constructor(
        private db: AngularFireDatabase,
        public identityService: IdentityService,
        private datatableLang: DatatableLangService,
        private translate: TranslateService
    ) {
    }

    renderTable() {
        this.identityService.identity.subscribe(identity => {
            const drivers = [];
            if (identity.drivers) {
                for (const key of Object.keys(identity.drivers)) {
                    drivers.push({...identity.drivers[key], key});
                }
            }

            const table: any = $('#logistic-drivers-table');
            this.dataTable = table.DataTable({
                destroy: true,
                scrollX: true,
                language: this.datatableLang.lang,
                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                order: [[1, 'desc']],
                data: drivers,
                columns: [
                    {data: 'key', width: '50px'},
                    {data: 'id', defaultContent: ''},
                    {data: 'fiscalName', defaultContent: ''}
                ],
                columnDefs: [
                    {
                        targets: 0,
                        data: 'key',
                        title: 'Actions',
                        orderable: false,
                        render: (key) => {
                            // language=HTML
                            return `
                                <button logistic-driver-id="${key}" type="button" class="del-logistic-driver-btn btn btn-danger px-3 mr-2">
                                    <i class="fas fa-times"></i>
                                </button>
                                `;
                        },
                    }
                ],
                drawCallback: () => {
                    $('[data-toggle="tooltip"]').tooltip();
                }
            });
        });

        $(document).on('click', '.del-logistic-driver-btn', (e) => {
            const logisticDriverWalletAddress = $(e.currentTarget).attr('logistic-driver-id');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteLogisticDriver.warning.title',
                'identity.deleteLogisticDriver.warning.text',
                'identity.deleteLogisticDriver.deleted.title',
                'identity.deleteLogisticDriver.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteLogisticDriver.warning.title'],
                    text: trans['identity.deleteLogisticDriver.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.identityService.identity.pipe(first())
                            .subscribe((identity) => {
                                this.db.object('identity/' + logisticDriverWalletAddress + '/logistic').remove()
                                    .then(() => {
                                        this.db.object('identity/' + identity.walletAddress + '/drivers/' + logisticDriverWalletAddress).remove()
                                            .then(() => {
                                                Swal.fire(
                                                    trans['identity.deleteLogisticDriver.deleted.title'],
                                                    trans['identity.deleteLogisticDriver.deleted.text'],
                                                    'success'
                                                );
                                            });
                                    });
                            });
                    }
                });
            });
        });

    }
}
