import {Injectable, ChangeDetectorRef} from '@angular/core';
import {SlaughterhousesService} from '../slaughterhouses.service';
import {DocumentsService} from './documents.service';
import {DriversService} from '../drivers.service';
import {LogisticsService} from '../logistics.service';
import {VetsService} from '../vets.service';
import {IdentityService} from '../identity.service';
import {AuthenticationService} from '../authentication.service';
import {first, map} from 'rxjs/operators';
import * as moment from 'moment';
import {ethers} from 'ethers';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {ClipboardService} from 'ngx-clipboard';
import {ContractService} from '../contract/contract.service';
import {DocumentInterface} from '../../interfaces/document/document';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FarmInterface} from '../../interfaces/identity/farm.interface';
import {DocumentModel} from '../../models/document.model';
import {SlaughterhouseInterface} from '../../interfaces/identity/slaughterhouse.interface';
import {VetInterface} from '../../interfaces/identity/vet.interface';
import {DriverInterface} from '../../interfaces/identity/driver.interface';
import {FarmsService} from '../farms/farms.service';
import {FarmModel} from '../../models/identity/farm.model';
import {TranslateService} from '@ngx-translate/core';
import * as BigNumber from 'big-number';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class DocumentFormService {
    document: DocumentInterface;
    selectedDocumentId: string;
    slaughterhouses: any;
    farms: FarmInterface[];
    vets: any;
    drivers: any;
    logistics: any;
    transports: any;
    departureDt: string;
    deliveryDt: string;
    cleanDt: string;
    documentType: 'HISTORIC' | 'DOCUMENT' = 'DOCUMENT';
    useGeneralitatNumber = false;

    constructor(
        private farmsService: FarmsService,
        private slaughterhousesService: SlaughterhousesService,
        private documentsService: DocumentsService,
        private driversService: DriversService,
        private logisticsService: LogisticsService,
        private vetsService: VetsService,
        private identityService: IdentityService,
        private authService: AuthenticationService,
        private db: AngularFireDatabase,
        public clipboardService: ClipboardService,
        public contractService: ContractService,
        private http: HttpClient,
        private translate: TranslateService
    ) {
        this.document = new DocumentModel();

        this.farmsService.farms.subscribe((farms: FarmInterface[]) => {
            this.farms = farms.map((val) => new FarmModel(val));
        });

        this.slaughterhousesService.slaughterhouses.subscribe(slaughterhouses => {
            this.slaughterhouses = slaughterhouses;
        });

        this.vetsService.vets.subscribe(vets => {
            this.vets = vets;
        });

        this.driversService.drivers.subscribe(drivers => {
            this.drivers = drivers;
        });

        this.logisticsService.logistics.subscribe(logistics => {
            this.logistics = logistics;
        });

        $(document).on('click', '.edit-doc-btn', (e) => {
            const id = $(e.currentTarget).attr('document-id');
            this.loadDocument(id);
        });

        $(document).on('click', '.del-doc-btn', (e) => {
            const id = $(e.currentTarget).attr('document-id');
            this.delDocument(id);
        });

        $(document).on('click', '.signature-validation-btn', (e) => {
            const target = e.currentTarget;
            const data = decodeURI($(e.currentTarget).attr('data'));
            this.clipboardService.copyFromContent(data);
            this.translate.get([
                'generic.copy',
                'generic.copied'
            ]).subscribe((trans: any) => {
                $(e.currentTarget).html(`${trans['generic.copied']} <i class="fas fa-check"></i>`);
                setTimeout(() => {
                    $(target).html(trans['generic.copy']);
                }, 2000);
            });
        });
    }

    saveDocument(documentId?: any, document?: any, close: boolean = true) {
        if (this.selectedDocumentId) {
            console.log(document, 'document');
            console.log(this.document, 'this.document');
            this.documentsService.saveDocument(documentId ? documentId : this.selectedDocumentId, document ? document : this.document);

            if (close) {
                $('#new-document-modal').modal('hide');
            }
        } else {
            this.translate.get([
                'generic.error',
                'documents.documentIdRequired'
            ]).subscribe((trans: any) => {
                Swal.fire({
                    icon: 'error',
                    title: trans['generic.error'],
                    html: trans['documents.documentIdRequired']
                });
            });
        }
    }

    loadDocument(id, historic = false) {
        this.document = new DocumentModel();
        this.selectedDocumentId = id;
        if (!historic) {
            this.documentType = 'DOCUMENT';
            this.documentsService.getDocument(id)
                .pipe(
                    first()
                )
                .subscribe((document: DocumentInterface) => {
                    // console.log('-------------------------------------------------------------------------------------------------');
                    console.log(document, 'document1');
                    // console.log(new DocumentModel(document), 'new DocumentModel(document)');
                    this.document = new DocumentModel(document);
                    // console.log(this.document, 'this.document2');

                    this.departureDt = moment(this.document.staticData.data.departureDt, 'x').format('DD/MM/YYYY HH:mm');
                    this.deliveryDt = moment(this.document.staticData.data.deliveryDt, 'x').format('DD/MM/YYYY HH:mm');
                    this.cleanDt = moment(this.document.driverData.data.cleanDt, 'x').format('DD/MM/YYYY HH:mm');

                    this.selectLogisticByWalletAddress(this.document.driverData.data.logistic.walletAddress);
                });
        } else {
            this.documentType = 'HISTORIC';
            this.db.list('documentsHistoric/' + id).snapshotChanges()
                .pipe(map(items => {
                        return items.reduce((total, current) => {
                            const value = current.payload.val();
                            const key = current.payload.key;
                            total[key] = value;
                            return total;
                        }, {});
                    }),
                    first()
                )
                .subscribe((document: DocumentInterface) => {
                    // console.log('-------------------------------------------------------------------------------------------------');
                    console.log(document, 'document1');
                    // console.log(new DocumentModel(document), 'new DocumentModel(document)');
                    this.document = new DocumentModel(document);
                    // console.log(this.document, 'this.document2');

                    this.departureDt = moment(this.document.staticData.data.departureDt, 'x').format('DD/MM/YYYY HH:mm');
                    this.deliveryDt = moment(this.document.staticData.data.deliveryDt, 'x').format('DD/MM/YYYY HH:mm');
                    this.cleanDt = moment(this.document.driverData.data.cleanDt, 'x').format('DD/MM/YYYY HH:mm');

                    this.selectLogisticByWalletAddress(this.document.driverData.data.logistic.walletAddress);
                });
        }

    }

    delDocument(documentId) {
        if (documentId) {
            this.translate.get([
                'generic.yes',
                'generic.no',
                'documents.deleteDocumentRequest.warning.title',
                'documents.deleteDocumentRequest.warning.text',
                'documents.deleteDocumentRequest.deleted.title',
                'documents.deleteDocumentRequest.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['documents.deleteDocumentRequest.warning.title'],
                    text: trans['documents.deleteDocumentRequest.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.db.object(`deletedDocuments/${documentId}`).set(this.document)
                            .then(() => {
                                this.db.object(`documents/${documentId}`).remove()
                                    .then(() => {
                                        Swal.fire(
                                            trans['documents.deleteDocumentRequest.deleted.title'],
                                            trans['documents.deleteDocumentRequest.deleted.text'],
                                            'success'
                                        );
                                    });
                            });
                    }
                });
            });
        }
    }

    getDocumentId() {
        return new Promise((resolve) => {
            if (
                // 1 === 1 ||
                this.document.staticData.data.category &&
                this.document.staticData.data.animalsQty &&
                this.document.staticData.data.departureDt &&
                this.document.staticData.data.deliveryDt &&
                this.document.staticData.data.farm.fiscalName &&
                this.document.staticData.data.farm.REGACode &&
                this.document.staticData.data.slaughterhouse.fiscalName &&
                this.document.staticData.data.slaughterhouse.REGACode
            ) {
                if (!this.useGeneralitatNumber) {
                    console.log('HOLAAAAAAAAAAAAAAAAAAA');
                    // if (1 === Number(this.document.staticData.data.farm.fiscalName)) {
                    this.documentsService.getDocumentId().pipe(first()).subscribe((documentId: string) => {
                        this.selectedDocumentId = BigNumber(documentId).plus(1).val();
                        this.document.staticData.data.documentId = this.selectedDocumentId;
                        this.documentsService.updateDocumentId(this.selectedDocumentId);
                        this.translate.get([
                            'generic.congratulations',
                            'documents.documentSuccessfullyCreated',
                        ], {
                            selectedDocumentId: this.selectedDocumentId
                        }).subscribe((trans: any) => {
                            Swal.fire({
                                icon: 'success',
                                title: trans['generic.congratulations'],
                                text: trans['documents.documentSuccessfullyCreated'],
                                timer: 2000
                            });
                        });
                        resolve('');
                    });
                } else {
                    const url = 'https://preproduccio.aplicacions.agricultura.gencat.cat/gtr/WSAltaguies/AppJava/WSAltaGuia';
                    const guide = {
                        nif: 'G25229543',
                        password: 'jYNZCc2d',
                        tipusEspecie: '02',
                        tipusAccio: 'NO',
                        tipusMoviment: '01',
                        explotacioSortida: '7510CN',
                        explotacioEntrada: '497E01',
                        codiCategoria: this.document.staticData.data.categoryCode,
                        numAnimals: this.document.staticData.data.animalsQty,
                        dataSortida: moment(this.document.staticData.data.departureDt).format('YYYYMMDDHHmm'),
                        dataArribada: moment(this.document.staticData.data.deliveryDt).format('YYYYMMDDHHmm'),
                        codiSirentra: this.document.driverData.data.driver.SIRCode,
                        mitjaTransport: this.document.driverData.data.transportCode,
                        matricula: this.document.driverData.data.registrationNumber,
                        nifConductor: this.document.driverData.data.driver.id,
                        mobilitat: this.document.staticData.data.mobility
                    };
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json'
                        })
                    };
                    this.http.put(url, guide, httpOptions).subscribe((res: any) => {
                        if (res.descripcio[0] === 'OK') {
                            this.selectedDocumentId = res.descripcio[res.descripcio.length - 1];
                            this.document.staticData.data.documentId = this.selectedDocumentId;
                            this.documentsService.updateDocumentId(this.selectedDocumentId);
                            this.translate.get([
                                'generic.congratulations',
                                'documents.documentSuccessfullyCreated',
                            ], {
                                selectedDocumentId: this.selectedDocumentId
                            }).subscribe((trans: any) => {
                                Swal.fire({
                                    icon: 'success',
                                    title: trans['generic.congratulations'],
                                    text: trans['documents.documentSuccessfullyCreated'],
                                    timer: 2000
                                });
                            });
                            resolve('');
                        } else {
                            this.translate.get('generic.error').subscribe((error: string) => {
                                Swal.fire({
                                    icon: 'error',
                                    title: error,
                                    html: res.descripcio.join('<br>')
                                });
                            });
                            resolve('');
                        }
                    }, (err: any) => {
                        this.translate.get([
                            'generic.error',
                            'documents.errorExternalServerRequest',
                        ]).subscribe((trans: any) => {
                            Swal.fire({
                                icon: 'error',
                                title: trans['generic.error'],
                                html: trans['documents.errorExternalServerRequest']
                            });
                        });
                        resolve('');
                    });
                }
            } else {
                this.translate.get([
                    'generic.error',
                    'documents.requiredFieldsMsg',
                ]).subscribe((trans: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: trans['generic.error'],
                        html: trans['documents.requiredFieldsMsg']
                    });
                });
                resolve('');
            }
        });
    }


    newDocument() {
        this.documentType = 'DOCUMENT';
        this.document = new DocumentModel();
        this.departureDt = null;
        this.deliveryDt = null;
        this.cleanDt = null;
        this.selectedDocumentId = '';
        this.selectLogisticByWalletAddress(null);
        this.identityService.identity.pipe(first()).subscribe((identity: FarmInterface) => {
            if (identity.type === 'FARM') {
                this.document.staticData.data.farm = {
                    id: identity.id,
                    fiscalName: identity.fiscalName,
                    holderId: identity.holderId,
                    holderName: identity.holderName,
                    QS: identity.QS,
                    MOCode: identity.MOCode,
                    REGACode: identity.REGACode,
                    walletAddress: identity.walletAddress,
                    city: identity.city,
                    postalCode: identity.postalCode,
                    provider: identity.provider
                };
            }
        });

    }

    selectSlaughterhouseByWalletAddress(walletAddress: string) {
        const slaughterhouse: SlaughterhouseInterface = this.slaughterhouses.find((slaughterhouseData) => {
            return slaughterhouseData.walletAddress === walletAddress;
        });
        this.document.staticData.data.slaughterhouse = {
            id: slaughterhouse.id,
            fiscalName: slaughterhouse.fiscalName,
            holderId: slaughterhouse.holderId,
            holderName: slaughterhouse.holderName,
            QS: slaughterhouse.QS,
            MOCode: slaughterhouse.MOCode,
            REGACode: slaughterhouse.REGACode,
            walletAddress: slaughterhouse.walletAddress,
            city: slaughterhouse.city,
            postalCode: slaughterhouse.postalCode,
        };
    }

    selectFarmByWalletAddress(walletAddress: string) {
        const farm: FarmInterface = this.farms.find((farmData) => {
            return farmData.walletAddress === walletAddress;
        });
        this.document.staticData.data.farm = new FarmModel({
            id: farm.id,
            fiscalName: farm.fiscalName,
            QS: farm.QS,
            MOCode: farm.MOCode,
            REGACode: farm.REGACode,
            walletAddress: farm.walletAddress,
            city: farm.city,
            postalCode: farm.postalCode,
            provider: {
                id: farm.provider.id,
                fiscalName: farm.provider.fiscalName,
                walletAddress: farm.provider.walletAddress,
            }
        });
    }

    selectVetByWalletAddress(walletAddress: string) {
        const vet: VetInterface = this.vets.find((vetData) => {
            return vetData.walletAddress === walletAddress;
        });

        this.document.vetData.data.vet = {
            id: vet.id,
            fiscalName: vet.fiscalName,
            collegeNumber: vet.collegeNumber,
            walletAddress: vet.walletAddress,
        };
    }

    selectLogisticByWalletAddress(walletAddress: string) {
        const logistic = this.logistics.find((logisticData) => {
            return logisticData.walletAddress === walletAddress;
        });
        if (logistic) {
            this.document.driverData.data.logistic = {
                id: logistic.id,
                fiscalName: logistic.fiscalName,
                walletAddress: logistic.walletAddress,
            };
        } else {
            this.document.driverData.data.logistic = {
                id: '',
                fiscalName: '',
                walletAddress: '',
            };
        }

        this.transports = logistic && typeof logistic.transports !== 'undefined' ? Object.keys(logistic.transports) : [];
    }

    selectDriverByWalletAddress(walletAddress: string) {
        const driver: DriverInterface = this.drivers.find((driverData) => {
            return driverData.walletAddress === walletAddress;
        });

        if (driver) {
            this.document.driverData.data.driver = {
                id: driver.id,
                fiscalName: driver.fiscalName,
                SIRCode: driver.SIRCode,
                ATESCode: driver.ATESCode,
                walletAddress: driver.walletAddress,
            };
        } else {
            this.document.driverData.data.driver = {
                id: '',
                fiscalName: '',
                SIRCode: '',
                ATESCode: '',
                walletAddress: '',
            };
        }
    }

    async sign(identity) {
        identity = identity.toLowerCase();

        if (identity === 'driver') {
            if (
                !this.document.driverData.data.driver.ATESCode ||
                !this.document.driverData.data.driver.id ||
                !this.document.driverData.data.transport
            ) {
                this.translate.get([
                    'generic.error',
                    'documents.errorDriverRequiredData',
                ]).subscribe((trans: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: trans['generic.error'],
                        html: trans['documents.errorDriverRequiredData']
                    });
                });
                return;
            }
        }

        if (identity === 'farm') {
            const dataToHash = JSON.stringify(this.document.staticData.data);
            this.document.staticData.hash = this.hashData(dataToHash);
            this.document.staticData.hashedData = dataToHash;
        }

        this.document[identity + 'Data'].data.signatureDt = Number(moment().format('x'));

        if (identity === 'slaughterhouse') {

            // let departureDt: number;
            // if (this.document.driverData.data.signatureDt) {
            //     departureDt = this.document.driverData.data.signatureDt;
            // } else {
            //     departureDt = this.document.staticData.data.departureDt;
            //     this.document.dynamicData.errorTime = true;
            // }

            if (this.document.driverData.data.signatureDt && this.document.slaughterhouseData.data.signatureDt && this.document.driverData.data.signatureDt < this.document.slaughterhouseData.data.signatureDt) {
                this.document.dynamicData.time = moment(this.document.slaughterhouseData.data.signatureDt)
                    .diff(moment(this.document.driverData.data.signatureDt), 'minutes');
            } else {
                this.document.dynamicData.time = 0;
                this.document.dynamicData.errorTime = true;
            }

            // this.confirmDocument();
        }

        const dataToSign = JSON.stringify({
            documentHash: this.document.staticData.hash,
            ...this.document[identity + 'Data'].data
        });

        this.document[identity + 'Data'].signature.signature = await this.authService.wallet.signMessage(dataToSign);
        this.document[identity + 'Data'].signature.hash = ethers.utils.keccak256(this.document[identity + 'Data'].signature.signature);
        this.document[identity + 'Data'].signature.signedData = dataToSign;
        this.saveDocument(this.selectedDocumentId, this.document);

        this.db.object(`pendingTransactions/${this.document[identity + 'Data'].signature.hash}`).set({
            document: this.document.staticData.data.documentId,
            identity: identity.toUpperCase(),
            status: 'pending',
            creationDt: Number(moment().format('x'))
        });

        this.translate.get([
            'generic.congratulations',
            'documents.documentSuccessfullySigned',
        ]).subscribe((trans: any) => {
            Swal.fire({
                icon: 'success',
                title: trans['generic.congratulations'],
                text: trans['documents.documentSuccessfullySigned'],
                timer: 2000
            });
        });
    }

    confirmDocument() {
        return new Promise((resolve) => {
            if (!this.useGeneralitatNumber) {
                this.document.dynamicData.confirmedDt = Number(moment().format('x'));
                this.document.dynamicData.confirmed = true;
                this.saveDocument(this.selectedDocumentId, this.document);
                this.translate.get([
                    'generic.congratulations',
                    'documents.documentSuccessfullyConfirmed',
                ], {
                    selectedDocumentId: this.selectedDocumentId
                }).subscribe((trans: any) => {
                    Swal.fire({
                        icon: 'success',
                        title: trans['generic.congratulations'],
                        text: trans['documents.documentSuccessfullyConfirmed'],
                        timer: 2000
                    });
                    resolve('');
                });
            } else {
                this.http.get(
                    `https://preproduccio.aplicacions.agricultura.gencat.cat/gtr/WSMobilitat/AppJava/WSCarregaGuiesMobilitat?nif=G25229543&password=jYNZCc2d&codiMo=7510CN&codiRega=ES082540026112&dataSortida=${moment(this.document.staticData.data.departureDt).format('YYYYMMDDHHmm')}`
                ).subscribe((res1: any) => {
                    console.log(res1, 'confirm Document');
                    this.http.put(
                        'https://preproduccio.aplicacions.agricultura.gencat.cat/gtr/WSMobilitat/AppJava/WSModificarGuiasMovilitat',
                        {
                            nif: 'G25229543',
                            password: 'jYNZCc2d',
                            remo: this.document.staticData.data.documentId,
                            categoria: this.document.staticData.data.categoryCode,
                            nombreAnimals: this.document.slaughterhouseData.data.animalsQty,
                            transportista: this.document.driverData.data.driver.ATESCode,
                            responsable: this.document.driverData.data.driver.id,
                            vehicle: this.document.driverData.data.transport,
                            // dataSortida: moment(this.document.driverData.data.signatureDt ? this.document.driverData.data.signatureDt : this.document.staticData.data.departureDt).format('YYYYMMDDHHmm'),
                            dataSortida: moment(this.document.staticData.data.departureDt).format('YYYYMMDDHHmm'),
                            // dataArribada: moment(this.document.slaughterhouseData.data.signatureDt < this.document.staticData.data.departureDt ? this.document.staticData.data.deliveryDt : this.document.slaughterhouseData.data.signatureDt).format('YYYYMMDDHHmm')
                            dataArribada: moment(this.document.staticData.data.deliveryDt).format('YYYYMMDDHHmm')
                        },
                        {
                            headers: new HttpHeaders({
                                'Content-Type': 'application/json'
                            })
                        }
                    ).subscribe((res: any) => {
                        if (res.codi === 'OK' || res.codi === '001' || res.codi === '002' || 1 === 1) {
                            // this.document.dynamicData.confirmed = true;
                            this.document.dynamicData.confirmedDt = Number(moment().format('x'));
                            this.document.dynamicData.confirmed = true;
                            this.saveDocument(this.selectedDocumentId, this.document);
                            this.translate.get([
                                'generic.congratulations',
                                'documents.documentSuccessfullyConfirmed',
                            ], {
                                selectedDocumentId: this.selectedDocumentId
                            }).subscribe((trans: any) => {
                                Swal.fire({
                                    icon: 'success',
                                    title: trans['generic.congratulations'],
                                    text: trans['documents.documentSuccessfullyConfirmed'],
                                    timer: 2000
                                });
                            });
                        } else {
                            this.translate.get('generic.error').subscribe((error: string) => {
                                Swal.fire({
                                    icon: 'error',
                                    title: error,
                                    html: Array.isArray(res) ? res.map(e => e.descripcio).join('<br>') : res.descripcio
                                });
                            });
                        }
                        resolve('');
                    }, (err: any) => {
                        this.translate.get([
                            'generic.error',
                            'documents.errorExternalServerRequest',
                        ]).subscribe((trans: any) => {
                            Swal.fire({
                                icon: 'error',
                                title: trans['generic.error'],
                                html: trans['documents.errorExternalServerRequest']
                            });
                        });
                        resolve('');
                    });
                }, (err: any) => {
                    this.translate.get([
                        'generic.error',
                        'documents.errorExternalServerRequest',
                    ]).subscribe((trans: any) => {
                        Swal.fire({
                            icon: 'error',
                            title: trans['generic.error'],
                            html: trans['documents.errorExternalServerRequest']
                        });
                    });
                    resolve('');
                });
            }
        });

    }

    hashData(dataToHash: string): string {
        const dataToUtf8Bytes = ethers.utils.toUtf8Bytes(dataToHash);
        return ethers.utils.keccak256(dataToUtf8Bytes);
    }

    async validateSign(identity: string) {
        identity = identity.toLowerCase();
        const signature = this.document[identity + 'Data'].signature.signature;
        const signedData = this.document[identity + 'Data'].signature.signedData;
        console.log(signature, 'signature');
        const hash = ethers.utils.keccak256(signature);
        console.log(hash, 'hash');
        const signingAddress = await ethers.utils.verifyMessage(signedData, signature);
        if (this.document[identity + 'Data'].signature.hash === hash) {
            this.translate.get([
                'documents.verified',
                'generic.info',
                'generic.copy',
                'documents.signature',
                'identity.generic.walletAddress',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    icon: 'success',
                    title: trans['documents.verified'],
                    width: 800,
                    focusConfirm: true,
                    // language=HTML
                    html: `
                    <div class="text-left bootstrap-style">
                        <h5>${trans['generic.info']} <button class="signature-validation-btn btn btn-secondary btn-sm float-right"
                        data="${encodeURI(signedData)}">${trans['generic.copy']}</button></h5>
                        <div class="card">
                            <div class="card-body signature-info-box">
                                <pre>${JSON.stringify(JSON.parse(signedData), null, 2)}</pre>
                            </div>
                        </div>
                        <h5 class="mt-3">${trans['documents.signature']}</h5>
                        <div class="card">
                            <div class="card-body">
                                ${signature}
                            </div>
                        </div>
                        <h5 class="mt-3">${trans['identity.generic.walletAddress']}</h5>
                        <div class="card">
                            <div class="card-body">
                                ${signingAddress}
                            </div>
                        </div>
                    </div>
                    `
                });
            });
        } else {
            this.translate.get([
                'generic.error',
                'documents.wrongSignature',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    icon: 'error',
                    title: trans['generic.error'],
                    html: trans['documents.wrongSignature']
                });
            });
        }

    }


    validateSignHash(identity: string) {
        identity = identity.toLowerCase();

        const signature = this.document[identity + 'Data'].signature.signature;
        const hash = ethers.utils.keccak256(signature);

        this.contractService.validate(hash).then((hashIsValid) => {
            if (hashIsValid && this.document[identity + 'Data'].signature.hash === hash) {
                this.translate.get([
                    'documents.verified',
                    'documents.signatureVerified',
                ]).subscribe((trans: any) => {
                    Swal.fire({
                        icon: 'success',
                        title: trans['documents.verified'],
                        html: trans['documents.signatureVerified']
                    });
                });
            } else {
                this.translate.get([
                    'generic.error',
                    'documents.wrongSignature',
                ]).subscribe((trans: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: trans['generic.error'],
                        html: trans['documents.wrongSignature']
                    });
                });
            }
        }).catch(() => {
            this.translate.get([
                'generic.error',
                'documents.wrongSignature',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    icon: 'error',
                    title: trans['generic.error'],
                    html: trans['documents.wrongSignature']
                });
            });
        });

    }
}
