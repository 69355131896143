import {Injectable} from '@angular/core';
import * as moment from '../identities/identities.service';
import {map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import {AngularFireDatabase} from '@angular/fire/database';
import Swal from 'sweetalert2';
import {DatatableLangService} from '../datatable-lang.service';
import {ModifyIdentityFormService} from './modify-identity-form.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class IdentitiesService {
    dataTable: any;
    public identities: any;
    public identityList: any;

    constructor(
        private db: AngularFireDatabase,
        private datatableLang: DatatableLangService,
        private modifyIdentityFormService: ModifyIdentityFormService,
        private translate: TranslateService
    ) {
        this.identities = db.list('identity')
            .valueChanges();
        this.identityList = this.identities.pipe(
            map(identities => {
                for (let i = 0; i < Object.keys(identities).length; i++) {
                    const identity = identities[i];


                    identities[i] = {
                        id: getVal(identity, 'id') || null,
                        walletAddress: getVal(identity, 'walletAddress') || null,
                        type: getVal(identity, 'type') || null,
                        fiscalName: getVal(identity, 'fiscalName') || null,
                        active: getVal(identity, 'active') || null,
                    };
                }
                return identities;
            })
        );
    }

    renderTable() {
        this.identityList.subscribe(identities => {
            const table: any = $('#identities-table');
            this.dataTable = table.DataTable({
                destroy: true,
                scrollX: true,
                language: this.datatableLang.lang,
                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                order: [[1, 'desc']],
                data: identities,
                columns: [
                    {data: 'walletAddress', width: '50px'},
                    {data: 'type', defaultContent: ''},
                    {data: 'id', defaultContent: ''},
                    {data: 'fiscalName', defaultContent: ''},
                    {data: 'active', defaultContent: ''}
                ],
                columnDefs: [
                    {
                        targets: 0,
                        data: 'walletAddress',
                        title: 'Actions',
                        orderable: false,
                        render: (walletAddress) => {
                            // language=HTML
                            return `
                            <button wallet-address="${walletAddress}" type="button" class="del-identity-btn btn btn-danger px-3 mr-2">
                                <i class="fas fa-times"></i>
                            </button>
                            <button wallet-address="${walletAddress}" type="button" class="modify-identity-btn btn btn-primary mr-2"
                                data-toggle="modal" data-target="#modify-identity-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            `;
                        },
                    },
                    {
                        targets: 4,
                        data: 'active',
                        render: (active) => {
                            if (active) {
                                // language=HTML
                                return `
                                    <span class="badge badge-pill badge-success px-2 pb-2">
                                Active
                                </span>
                                `;
                            } else {
                                return `
                                <span class="badge badge-pill badge-secondary px-2 pb-2">
                                Inactive
                                </span>
                            `;
                            }
                        },
                    },
                ],
                drawCallback: () => {
                    $('[data-toggle="tooltip"]').tooltip();
                }
            });
        });

        $(document).on('click', '.del-identity-btn', (e) => {
            const walletAddress = $(e.currentTarget).attr('wallet-address');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deleteIdentity.warning.title',
                'identity.deleteIdentity.warning.text',
                'identity.deleteIdentity.deleted.title',
                'identity.deleteIdentity.deleted.text',
            ]).subscribe((trans: any) => {
                Swal.fire({
                    title: trans['identity.deleteIdentity.warning.title'],
                    text: trans['identity.deleteIdentity.warning.text'],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: trans['generic.yes'],
                    cancelButtonText: trans['generic.no']
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire(
                            trans['identity.deleteIdentity.deleted.title'],
                            trans['identity.deleteIdentity.deleted.text'],
                            'success'
                        );
                    }
                });
            });
        });

        $(document).on('click', '.modify-identity-btn', (e) => {
            const walletAddress = $(e.currentTarget).attr('wallet-address');
            this.db.list('identity/' + walletAddress).snapshotChanges()
                .pipe(map(items => {
                    return items.reduce((total, current) => {
                        const value = current.payload.val();
                        const key = current.payload.key;
                        total[key] = value;
                        return total;
                    }, {});
                })).subscribe((identity) => {
                this.modifyIdentityFormService.identity = identity;
                this.modifyIdentityFormService.transportsCode = this.modifyIdentityFormService.identity.transports && Object.keys(this.modifyIdentityFormService.identity.transports) || [];
                this.modifyIdentityFormService.transportCode = '';
            });
        });

    }
}
