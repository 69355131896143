import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class DriversService {
  public drivers: any;
  constructor(
      private db: AngularFireDatabase
  ) {
    this.drivers = db.list('identity', ref =>
        ref.orderByChild('type').equalTo('DRIVER')
    ).snapshotChanges()
        .pipe(map(items => {
              return items.reduce((total, current, i) => {
                const value = current.payload.val();
                total[i] = value;
                return total;
              }, []);
            })
        );
  }
}
