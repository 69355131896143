import {getVal} from '../../utils/getNestedObject';
import {DriverInterface} from '../../interfaces/identity/driver.interface';

export class DriverModel {
    id: string;
    fiscalName: string;
    commercialName: string;
    SIRCode: string;
    ATESCode: string;
    logistic: {
        id: string;
        walletAddress: string;
        fiscalName: string;
    };
    email: string;
    mobile: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
    country: string;
    token: string;
    type: 'DRIVER';
    walletAddress: string;
    delegatedGas: boolean;
    active: boolean;

    constructor(
        driver?: DriverInterface
    ) {
        this.id = getVal(driver, 'id') || '';
        this.fiscalName = getVal(driver, 'fiscalName') || '';
        this.commercialName = getVal(driver, 'commercialName') || '';
        this.SIRCode = getVal(driver, 'SIRCode') || '';
        this.ATESCode = getVal(driver, 'ATESCode') || '';
        this.logistic = getVal(driver, 'logistic') || '';
        this.email = getVal(driver, 'email') || '';
        this.mobile = getVal(driver, 'mobile') || '';
        this.phone = getVal(driver, 'phone') || '';
        this.address = getVal(driver, 'address') || '';
        this.city = getVal(driver, 'city') || '';
        this.state = getVal(driver, 'state') || '';
        this.province = getVal(driver, 'province') || '';
        this.postalCode = getVal(driver, 'postalCode') || '';
        this.country = getVal(driver, 'country') || '';
        this.token = getVal(driver, 'token') || '';
        this.type = getVal(driver, 'type') || '';
        this.walletAddress = getVal(driver, 'walletAddress') || '';
        this.delegatedGas = getVal(driver, 'delegatedGas') || true;
        this.active = getVal(driver, 'active') || true;
    }
}
