import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {AngularFireDatabase} from '@angular/fire/database';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ModifyIdentityFormService {
    public identity: any;
    transportsCode: string[];
    transportCode: string;

    constructor(
        private db: AngularFireDatabase,
        private translate: TranslateService
    ) {
    }

    saveIdentity() {
        console.log(this.identity.walletAddress, 'this.identity.walletAddress');
        console.log(this.identity, 'this.identity');
        this.db.object(`identity/${this.identity.walletAddress}`).update(this.identity).then(() => {
            $('#modify-identity-modal').modal('hide');
            this.translate.get(['form.saveTitle', 'form.saveMessage']).subscribe((translate: any) => {
                Swal.fire({
                    icon: 'success',
                    title: translate['form.saveTitle'],
                    text: translate['form.saveMessage']
                });
            });
        });
    }

    delProvider() {
        this.translate.get([
            'generic.yes',
            'generic.no',
            'identity.deleteProvider.warning.title',
            'identity.deleteProvider.warning.text',
            'identity.deleteProvider.deleted.title',
            'identity.deleteProvider.deleted.text',
        ]).subscribe((trans: any) => {
            Swal.fire({
                title: trans['identity.deleteProvider.warning.title'],
                text: trans['identity.deleteProvider.warning.text'],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: trans['generic.yes'],
                cancelButtonText: trans['generic.no']
            }).then((result) => {
                if (result.value) {
                    this.db.object(`identity/${this.identity.provider.walletAddress}/farms/${this.identity.walletAddress}`).remove().then(() => {
                        this.db.object(`identity/${this.identity.walletAddress}/provider`).remove()
                            .then(() => {
                                Swal.fire(
                                    trans['identity.deleteProvider.deleted.title'],
                                    trans['identity.deleteProvider.deleted.text'],
                                    'success'
                                );
                            });
                    });
                }
            });
        });
    }

    delLogistic() {
        this.translate.get([
            'generic.yes',
            'generic.no',
            'identity.deleteLogistic.warning.title',
            'identity.deleteLogistic.warning.text',
            'identity.deleteLogistic.deleted.title',
            'identity.deleteLogistic.deleted.text',
        ]).subscribe((trans: any) => {
            Swal.fire({
                title: trans['identity.deleteLogistic.warning.title'],
                text: trans['identity.deleteLogistic.warning.text'],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: trans['generic.yes'],
                cancelButtonText: trans['generic.no']
            }).then((result) => {
                if (result.isConfirmed) {
                    this.db.object(`identity/${this.identity.logistic.walletAddress}/drivers/${this.identity.walletAddress}`).remove().then(() => {
                        this.db.object(`identity/${this.identity.walletAddress}/logistic`).remove()
                            .then(() => {
                                Swal.fire(
                                    trans['identity.deleteLogistic.deleted.title'],
                                    trans['identity.deleteLogistic.deleted.text'],
                                    'success'
                                );
                            });
                    });
                }
            });
        });
    }

    addTransport() {
        if (this.transportCode) {
            this.db.object(`identity/${this.identity.walletAddress}/transports/${this.transportCode}`).set('true');
            this.transportCode = '';
        } else {
            this.translate.get([
                'generic.error',
                'identity.logistic.error.emptyTransportCode',
                'generic.ok'
            ]).subscribe((translate: any) => {
                Swal.fire({
                    icon: 'error',
                    title: translate['generic.error'],
                    text: translate['identity.logistic.error.emptyTransportCode'],
                    confirmButtonText: translate['generic.ok'],
                });
            });
        }
    }

    delTransport(transportCode) {
        this.translate.get([
            'generic.yes',
            'generic.no',
            'identity.deleteTransport.warning.title',
            'identity.deleteTransport.warning.text',
            'identity.deleteTransport.deleted.title',
            'identity.deleteTransport.deleted.text',
        ]).subscribe((trans: any) => {
            Swal.fire({
                title: trans['identity.deleteTransport.warning.title'],
                text: trans['identity.deleteTransport.warning.text'],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: trans['generic.yes'],
                cancelButtonText: trans['generic.no']
            }).then((result) => {
                if (result.isConfirmed) {
                    this.db.object(`identity/${this.identity.walletAddress}/transports/${transportCode}`).remove().then(() => {
                        Swal.fire(
                            trans['identity.deleteTransport.deleted.title'],
                            trans['identity.deleteTransport.deleted.text'],
                            'success'
                        );
                    });
                }
            });
        });
    }

}
