import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {EncryptionService} from './encryption.service';
import {ethers} from 'ethers';

@Injectable({
    providedIn: 'root'
})
export class WalletService {
    public wallet: any;
    public provider: any;
    public mainnetProvider: any;
    constructor(
        public encryptionService: EncryptionService,
        public authService: AuthenticationService
    ) {
        this.provider = ethers.getDefaultProvider('ropsten');
        this.mainnetProvider = new ethers.providers.EtherscanProvider();

    }

    public newWallet(password: string): any {
        this.wallet = ethers.Wallet.createRandom();
        this.saveKey(password);
        return this.wallet;
    }

    async importKeystoreWallet(keystore: any, password: string) {
        try {
            this.wallet = await ethers.Wallet.fromEncryptedJson(keystore, password);
            this.saveKey(password);
            return this.wallet;
        } catch (error) {
            return false;
        }

    }

    importWalletFromPrivateKey(privateKey: string, password: string) {
        try {
            if (!privateKey.startsWith('0x')) {
                privateKey = '0x' + privateKey;
            }
            this.wallet = new ethers.Wallet(privateKey);
            this.saveKey(password);
            return this.wallet;
        } catch (error) {
            return false;
        }
    }

    importWalletFromMnemonic(mnemonic: string, password: string) {
        try {
            this.wallet = ethers.Wallet.fromMnemonic(mnemonic);
            this.saveKey(password);
            return this.wallet;
        } catch (error) {
            return false;
        }
    }

    async generateKeystore(password: string) {
        this.authService.decodePass = password;
        return await this.wallet.encrypt(password);
    }

    importWalletFromStorage(password: string) {
        const privateKey = this.encryptionService.decrypt(localStorage.getItem('encryptedKey'), password);
        this.wallet = new ethers.Wallet(privateKey);

    }


    saveKey(password: string) {
        this.authService.wallet = this.wallet;
        this.authService.decodePass = password;
        localStorage.setItem('encryptedKey', String(this.encryptionService.encrypt(this.wallet.privateKey, password)));
    }
}
