import {Component, OnInit} from '@angular/core';
import {LogisticPendingDriversService} from '../../../core/services/logistics/logistic-pending-drivers.service';
import {DriversService} from '../../../core/services/drivers/drivers.service';

@Component({
    selector: 'app-logistic-pending-drivers',
    templateUrl: './logistic-pending-drivers.component.html',
    styleUrls: ['./logistic-pending-drivers.component.css']
})
export class LogisticPendingDriversComponent implements OnInit {

    pendingDriverWalletAddress: string;
    drivers: any[];

    constructor(
        private logisticPendingDriversService: LogisticPendingDriversService,
        public driversService: DriversService,
    ) {
        this.getDrivers();
    }

    ngOnInit() {
        this.logisticPendingDriversService.renderTable();
    }

    getDrivers() {
        this.driversService.drivers.subscribe((drivers) => {
            this.drivers = drivers;
        });
    }

    savePendingDriver() {
        this.logisticPendingDriversService.savePendingDriver(this.pendingDriverWalletAddress);
        this.pendingDriverWalletAddress = '';
    }
}
