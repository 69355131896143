import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {WalletService} from '../../../../core/services/wallet.service';
declare var $: any;

@Component({
    selector: 'app-import-mnemonic',
    templateUrl: './import-mnemonic.component.html',
    styleUrls: ['./import-mnemonic.component.css']
})
export class ImportMnemonicComponent implements OnInit {
    mnemonic: string;
    mnemonicErr: boolean;

    password: string;
    repeatPassword: string;

    passwordErr: boolean;
    repeatPasswordErr: boolean;
    passwordErrText: string;

    constructor(
        private authService: AuthenticationService,
        private walletService: WalletService
    ) {
    }

    ngOnInit() {
    }

    reset(): void {
        this.mnemonic = '';
        this.mnemonicErr = false;

    }

    login() {
        this.mnemonicErr = false;
        const wallet = this.walletService.importWalletFromMnemonic(this.mnemonic, this.password);
        if (wallet) {
            $('#import-phrase-modal').modal('hide');
            this.authService.login();
        } else {
            this.mnemonicErr = true;
        }
    }
}
