import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {WalletService} from '../../../core/services/wallet.service';
import {utils} from 'ethers';
import {filter} from 'rxjs/operators';
import {TranslationService} from '../../../core/services/translation.service';
import {NavigationStart, Router} from '@angular/router';
import {FileModel} from '../../../core/models/file.model';
import {FileManagerModalComponent} from '../../partials/file-uploader-input/file-manager-modal/file-manager-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  balance: number;
  refreshBalance: boolean;
  language: LanguageFlag;
  languages: LanguageFlag[] = [
    {
      lang: 'cat',
      name: 'Català',
      flag: 'flag-icon-es-ca'
    },
    {
      lang: 'es',
      name: 'Español',
      flag: 'flag-icon-es'
    },
    {
      lang: 'en',
      name: 'English',
      flag: 'flag-icon-gb'
    }
  ];

  constructor(
    public authService: AuthenticationService,
    public walletService: WalletService,
    private translationService: TranslationService,
    private router: Router,
    public modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.setSelectedLanguage();
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.setSelectedLanguage();
      });
    this.getBalance();
    setInterval(() => this.getBalance(), 30000);

  }

  async getBalance(click?: boolean) {
    const newBalance = parseFloat(
      utils.formatEther(
        await this.walletService.provider.getBalance(this.walletService.wallet.address)
      )
    );
    if (this.balance !== newBalance || click) {
      this.refreshBalance = true;
      setTimeout(() => this.refreshBalance = false, 2000);
      this.balance = newBalance;
    }
  }

  setLanguage(lang) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
  }

  /**
   * Set selected language
   */
  setSelectedLanguage(): any {
    this.setLanguage(this.translationService.getSelectedLanguage());
  }

  logout() {
    this.authService.logout();
  }

  openFileManager() {
    const modalRef = this.modalService.open(FileManagerModalComponent, {scrollable: true});

  }
}
