export const locale = {
  lang: 'es',
  data: {
    menu: {
      home: 'Inicio',
      files: 'Ficheros',
      identity: 'Perfil',
      identities: 'Identidades',
      farms: 'Granjas',
      drivers: 'Transportistas',
      documents: {
        documents: 'Guias',
        pendingDocuments: 'Pendientes',
        documentsHistoric: 'Histórico',
      },
      lots: {
        lots: 'Lotes',
        productionLots: 'Lotes producción',
        rawMaterials: 'Lotes materia prima',
        rawMaterialProducts: 'Productos lotes materia prima',
        products: 'Productos producción',
        lotsTemplates: 'Plantillas lotes',
        processes: 'Procesos'
      },
      transactions: 'Transacciones',
      charts: 'Estadísticas',
      wallet: 'Cartera',
      config: 'Configuración',
      listFilter: {
        pending: 'Pendientes',
        success: 'Aceptadas'
      },
      chains: 'Chains',
      logout: 'Cerrar Sesión',
    },
    files:{
      files: 'Archivos',
      file: 'Archivo',
      addFiles: 'Añadir archivos',
      dropOrClickHere: 'Añadir archivos',
       deleteFileRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Estás seguro de que quiere eliminar este archivo?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Archivo borrado correctamente'
        }
      },
    },
    home: {
      hello: 'Hola',
      welcome: `
                <h4>Te has conectado con éxito a la <b>blockchain</b></h4>
                <h4>Tu clave pública es</h4>
             `,
      requestIdentity: {
        getNewIdentity: 'Obtener una identidad',
        getIdentityMsg: 'No tienes identidad.<br>Para poder acceder, debes solicitar una nueva identidad.',
        newIdentity: 'Nueva identidad',
        identity: 'Identidad'
      },
    },
    identity: {
      generic: {
        id: 'NIF/CIF',
        geolocation: 'Geolocalización',
        fiscalName: 'Nombre fiscal',
        commercialName: 'Nombre comercial',
        QS: 'Qualificación Sanitaria',
        delegatedGas: 'Gas delegado',
        address: 'Dirección',
        city: 'Ciudad',
        postalCode: 'Código postal',
        province: 'Provincia',
        country: 'País',
        email: 'Email',
        phone: 'Teléfono',
        mobile: 'Móvil',
        MOCode: 'Código MO',
        REGACode: 'Código REGA',
        type: 'Tipo',
        holderId: 'NIF/CIF del titular',
        holderName: 'Nombre del titular',
        token: 'Token',
        walletAddress: 'Clave pública',
        active: 'Activo'
      },
      farm: {
        mobilityPass: 'Contraseña movilidad'
      },
      vet: {
        collegeNumber: 'Número de colegiado',
      },
      logistic: {
        transports: 'Vehículos',
        transportCode: 'Matrícula del vehículo',
        error: {
          emptyTransportCode: 'No se puede agregar una matrícula vacía.'
        }
      },
      driver: {
        SIRCode: 'Código Sirentra',
        ATESCode: 'Código ATES',
      },
      deleteProviderRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Estás seguro de que quiere eliminar esta solicitud?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Solicitud borrada correctamente'
        }
      },
      deleteProvider: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este proveedor?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Proveedor borrado correctamente'
        }
      },
      deleteLogisticRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta solicitud?'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Solicitud borrada correctamente'
        }
      },
      deleteLogistic: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta empresa de logística?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Empresa de logística borrada correctamente'
        }
      },
      deleteTransport: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este vehículo?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Vehiculo borrado correctamente'
        }
      },
      deleteProviderFarm: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta granja?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Granja borrada correctamente'
        }
      },
      deleteProviderPendingFarm: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar esta granja pendiente de asignar?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Granja pendiente borrada correctamente'
        }
      },
      deleteLogisticDriver: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este transportista?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Transportista pendiente borrado correctamente'
        }
      },
      deleteLogisticPendingDriver: {
        warning: {
          title: 'Estás seguro?',
          text: 'Está seguro que quiere eliminar este transportista pendiente de asignar?'
        },
        deleted: {
          title: 'borrado!',
          text: 'Transportista pendiente borrado correctamente'
        }
      },
      deletePendingIdentity: {
        warning: {
          title: 'Estás seguro?',
          text: 'Este cambio no se podra revertir!'
        },
        deleted: {
          title: 'borrado!',
          text: 'Identidad pendiente borrada correctamente'
        }
      },
    },
    identities: {
      admin: 'Administrador',
      farms: 'Granjas',
      farm: 'Granja',
      provider: 'Proveedor',
      vet: 'Veterinario',
      slaughterhouse: 'Matadero',
      logistic: 'Logística',
      drivers: 'Transportistas',
      driver: 'Transportista',
    },
    pendingIdentities: {
      pendingIdentities: 'Identidades pendientes',
      newIdentity: 'Nueva identidad'
    },
    providerPendingFarms: {
      pendingFarms: 'Granjas pendientes',
      addFarm: 'Agregar granja'
    },
    logisticPendingDrivers: {
      pendingDrivers: 'Transportistas pendientes',
      addDriver: 'Agregar transportista'
    },
    transactions: {
      transaction: 'Transacción',
      signatureHash: 'Hash de la firma',
      info: 'Información'
    },
    charts: {
      ETHPrice: 'Precio ETH',
      serverBalance: 'ETHs Servidor',
      blockNumber: 'Número de bloque',
      lastTransactionDuration: 'Última transacción',
      documentsLeft: 'Guías disponibles',
      daily: 'Diario',
      monthly: 'Mensual',
      yearly: 'Anual',
      documentsQty: 'Número de guías',
      earnings: 'Ganancias',
      transactionsQty: 'Número de transacciones',
      transactionsDuration: 'Duración media transacciones',
      expenses: 'Gastos',
      profit: 'Ingresos',
      minutes: 'Minutos',
    },
    wallet: {
      publicKey: 'Llave publica',
      privateKey: 'Llave privada',
      mnemonicPhrase: 'Frase mnemotécnica',
      downloadKeystore: 'Descargar clave encriptada',
    },
    config: {
      blockChain: 'BlackChain',
      documentPrice: 'Precio por guía',
      gasPrice: 'Precio del gas',
    },
    form: {
      save: 'Guardar',
      saveTitle: 'Guardado',
      close: 'Cerrar',
      saveMessage: 'Guardado correctamente',
      link: 'Enlace',
      send: 'Enviar'
    },
    externalLink: {
      addExternalLink: 'Añadir enlace externo',
      externalLinks: 'Enlaces externos',
      externalLink: 'Enlace externo',
      reference: 'Referencia',
      url: 'Enlace',
    },
    lots: {
      lots: 'Lotes producción',
      lotsOrigin: 'Lotes origen',
      lotTemplate: 'Plantilla lote',
      custom: 'Campos personalizados',
      lot: 'Lote producción',
      newLot: 'Nuevo lote',
      rawMaterials: 'Lotes materia prima',
      addRawMaterial: 'Añadir lote materia prima',
      addLot: 'Añadir lote producción',
      documents: 'Guias de trazabilidad',
      addDocument: 'Añadir guia de trazabilidad',
      id: 'ID',
      externalId: 'ID externo',
      name: 'Nombre',
      provider: 'Proveedor',
      withoutProvider: 'Sin proveedor',
      start: 'Inicio',
      end: 'Final',
      from: 'Desde',
      to: 'Hasta',
      date: 'Fecha',
      units: 'Unidades',
      quantity: 'Total unidades lote',
      expiration: 'Caducidad',
      process: 'Proceso',
      product: 'Producto',
      products: 'Productos',
      weight: 'Peso total neto lote',
      grossWeight: 'Peso total bruto lote',
      type: 'Tipo',
      color: 'Color',
      origin: 'País de origen',
      drumNum: 'Drum Num',
      homogenized: 'Homogenized honey',
      notHomogenized: 'Not homogenized honey',
      mainData: 'Datos principales',
      uniqueIdSeries: 'Series de identificadores únicos',
      sensorTagIdSeries: 'Series de sensores',
      productIdAlreadyAddedAlert: {
        title: 'Error',
        text: 'Hay productos en esta serie que pertenecen a otro lote'
      },
      uploadToBlockchainAlert: {
        title: 'Estas seguro?',
        text: 'Va a subir el lote a la BlockChain. Una vez se lo haya subido, no se podrá realizar ninguna modificación en el lote.'
      }
    },
    rawMaterials: {
      rawMaterials: 'Lotes materia prima',
      newRawMaterial: 'Nuevo lote materia prima',
      id: 'ID',
      externalId: 'ID externo',
      name: 'Nombre',
      date: 'Fecha',
      quantity: 'Total unidades lote',
      expiration: 'Caducidad',
      provider: 'Proveedor',
      rawMaterialProduct: 'Producto materia prima',
      weight: 'Peso total lote',
      geolocation: 'Geolocalización',
    },
    rawMaterialProducts: {
      rawMaterialProducts: 'Productos materia prima',
      newRawMaterialProduct: 'Nuevo producto materia prima',
      ean13: 'Código EAN13',
      name: 'Nombre',
      description: 'Descripción',
      url: 'Página web',
    },
    products: {
      products: 'Productos',
      product: 'Producto',
      newProduct: 'Nuevo producto',
      ean13: 'Código EAN13',
      name: 'Nombre',
      description: 'Descripción',
      geolocation: 'Geolocalización',
      type: 'Tipo',
      color: 'Color',
      origin: 'País de origen',
      url: 'Página web',
      additionalFields: 'Campos adicionales',
      sections: 'Secciones',
      section: 'Sección',
      newField: 'Nuevo campo',
    },
    lotsTemplates: {
      lotsTemplates: 'Plantillas lotes',
      lotTemplate: 'Plantilla lote',
      newLotTemplate: 'Nueva plantilla lote',
      name: 'Nombre',
      additionalFields: 'Campos adicionales',
      sections: 'Secciones',
      section: 'Sección',
      newField: 'Nuevo campo',
    },
    field: {
      field: 'Campo',
      fieldName: 'Codigo del campo',
      label: 'Descripción del campo',
      type: 'Tipo',
      defaultValue: 'Valor por defecto',
      options: 'Opciones',
      option: 'Opcion',
      description: 'Descripció',
      value: 'Valor',
    },
    processes: {
      processes: 'Procesos',
      newProcess: 'Nuevo proceso',
      name: 'Nombre',
      description: 'Descripción',
    },
    documents: {
      documents: 'Guías',
      document: 'Guía',
      historical: 'Histórico',
      newDocument: 'Guia nueva',
      documentId: 'Número de guía',
      category: 'Categoría',
      categories: {
        fattening: 'Engorde',
        piglets: 'Cerditos',
        recreationTransition: 'Recría / Transición',
        breedingFemales: 'Hembras reproductoras',
        replenishment: 'Reposición',
        stallions: 'Sementales',
      },
      animalsQty: 'Núm. animales',
      sex: 'Género',
      sexes: {
        male: 'Macho',
        female: 'Hembra'
      },
      mobility: 'Movilidad',
      departure: 'Salida',
      departureDt: 'Fecha/Hora Salida',
      exploitationOrigin: 'Explotación Origen',
      REGACode: 'Código REGA',
      MOCode: 'Marca Oficial',
      delivery: 'Entrega',
      deliveryDt: 'Fecha/Hora entrega',
      exploitationDelivery: 'Explotación Destino',
      requestDocument: 'Solicitar Guía',
      modifyDocument: 'Modificar Guía',
      documentHash: 'Hash de la Guía',
      comment: 'Comentario',
      signature: 'Firma',
      showSignature: 'Ver firma',
      hideSignature: 'Esconder firma',
      signatureDt: 'Fecha/Hora firma',
      show: 'Ver',
      signatureHash: 'Hash Firma',
      verify: 'Verificar',
      transaction: 'Transacción',
      explore: 'Explorar',
      sign: 'Firmar',
      SIRCode: 'Código Sirentra',
      cleanNumber: 'Resguardo',
      cleanDt: 'Fecha/Hora Desinfecció',
      transport: 'Medio de transporte',
      logisticCompany: 'Empresa logística',
      transports: {
        truck: 'Camión',
        others: 'Otros'
      },
      time: 'Tiempo',
      waitingResponse: 'Esperando respuesta...',
      confirmDocument: 'Confirmar guía',
      documentQR: {
        importDocument: 'Importar guía'
      },
      documentIdRequired: 'Es necesario solicitar la guia para poder guardar',
      documentSuccessfullyCreated: 'Guía número {{selectedDocumentId}} creada correctamente.',
      documentSuccessfullySaved: 'Guía añadida correctamente.',
      documentSuccessfullySigned: 'Guía firmada y guardada correctamente.',
      errorExternalServerRequest: 'Ha habido un problema con la respuesta de la Generalidad, intentar de nuevo',
      errorDriverRequiredData: 'Es obligatorio seleccionar un transportista con el código ATES y seleccionar la matrícula del transporte',
      requiredFieldsMsg: 'Los campos con * son obligatorios',
      documentSuccessfullyConfirmed: 'Guía número {{selectedDocumentId}} confirmada correctamente. Esta guía se guardará en el histórico',
      wrongSignature: 'La firma no es válida',
      verified: 'Verificado',
      signatureVerified: 'La firma es válida',
      symbols: {
        A: 'Confirmado a la administración',
        B: 'Certificado en la BlockChain',
        S: 'Firmado por el agente'
      },
      deleteDocumentRequest: {
        warning: {
          title: 'Estás seguro?',
          text: 'Este cambio no se podrá revertir!'
        },
        deleted: {
          title: 'Borrado!',
          text: 'Guía borrada correctamente'
        }
      },
    },
    login: {
      login: 'Iniciar Sesión',
      enterPass: 'Contraseña',
      useOtherWallet: 'Utilizar otra cuenta',
      invalidPassword: 'Contraseña incorrecta',
    },
    signUp: {
      signUp: 'Crear una nueva cuenta',
      password: 'Contraseña',
      repeatPassword: 'Repetir contraseña',
      newAccount: 'Nueva Cuenta',
      generate: 'Crear',
      importKeystore: 'Importar clave encriptada',
      importPrivateKey: 'Importar clave privada',
      importMnemonicPhrase: 'Importar frase mnemotécnica',
      keystorePassword: 'Contraseña de la clave encriptada',
      errorMnemonicPhrase: 'Frase mnemotécnica incorrecta',
      errorPrivateKey: 'Llave privada incorrecta',
      errorKeystorePassword: '<b>Error:</b> Contraseña incorrecta. Por favor verifique su contraseña.',
      errorKeystoreUpload: '<b>Error:</b> Necesitas subir el fichero y poner la contraseña.',
      rememberPassword: `<b>Recuerde la contraseña!</b><br>
               esta contraseña cifrará su cartera en un fichero local.<br>
               Con esta contraseña podrá iniciar sesión desencriptando la cartera.`,
      // `<b>Remember your password!</b><br>
      // This password encrypts your address in a keystore file.<br>
      // You will have to use it to log in with your wallet.
      errorRepeatPassword: 'Repite la contraseña correctamente',
      errorEmptyPassword: 'Introduce una contraseña',
    },
    datatable: {
      emptyTable: 'No hay datos disponibles en la tabla',
      info: 'Mostrando _START_ de _END_ de _TOTAL_ entradas',
      infoEmpty: 'No hay entradas',
      infoFiltered: '(filtrado de _MAX_ entradas)',
      lengthMenu: 'Mostrar _MENU_ entradas',
      loadingRecords: 'Cargando...',
      processing: 'Procesando...',
      search: 'Buscar:',
      zeroRecords: 'No se ha encontrado ningún registro',
      paginate: {
        first: 'Primero',
        last: 'Último',
        next: 'Siguiente',
        previous: 'Anterior'
      },
      aria: {
        sortAscending: ': activar para ordenar la columna ascendente',
        sortDescending: ': activar para ordenar la columna descendiente'
      }
    },
    slogan: 'BlockChain para la trazabilidad',
    partials: {
      exportQR: 'Exportar código QR',
      importQR: 'Importar código QR',
      cameraError: 'No se puede acceder a la transmisión de vídeo (asegurar que se tiene una cámara web habilitada)'
      // Unable to access video stream (please make sure you have a webcam enabled)
    },
    auth: {
      identityAlreadyRegistered: 'Este NIF ya tiene una identidad asignada',
      unauthorizedIdentity: 'Este NIF no tiene permiso para crear esta identidad',
      identityCreatedSuccessfully: 'Su identidad ha sido creada'
    },
    chains: {
      chains: 'Chains',
      fields: {
        chain: 'Chain',
        contract: 'Contrato',
        txMaxMinutes: 'Tx. Max min.',
        checkInterval: 'Intervalo subida',
        explorer: 'Explorador',
        server: 'Servidor',
        active: 'Activo',
      },
      newChain: 'Chain nueva',
      alerts: {
        deleteChain: {
          warning: {
            title: 'Estás seguro?',
            text: 'Este cambio no se podrá revertir!'
          },
          deleted: {
            title: 'Borrado!',
            text: 'Chain borrada correctamente'
          }
        },
      }
    },
    generic: {
      actions: 'Acciones',
      creationDt: 'Fecha Creación',
      yes: 'Sí',
      no: 'No',
      status: 'Estado',
      pending: 'Pendiente',
      process: 'Proceso',
      historical: 'Histórico',
      congratulations: 'Enhorabuena',
      successfullySaved: 'Se a guardado correctamente',
      deleteAlert: {
        title: 'Estás seguro?',
        text: 'Este cambio no se podrá revertir!'
      },
      deletedAlert: {
        title: 'Borrado!',
        text: 'Se ha borrada correctamente'
      },
      error: 'Error',
      ok: 'De acuerdo',
      copied: 'Copiado',
      copy: 'Copiar',
      info: 'Información',
      create: 'Crear',
      deleteAll: 'Borrar todo',
      form: 'Formulario'
    }
  }
};
