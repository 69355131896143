import {Component, OnInit} from '@angular/core';
import {DocumentQRService} from '../../../../core/services/documents/document-qr.service';

@Component({
    selector: 'app-document-qr',
    templateUrl: './document-qr.component.html',
    styleUrls: ['./document-qr.component.css']
})
export class DocumentQrComponent implements OnInit {
    documentId: string;
    domain: string;

    constructor(
        public documentQRService: DocumentQRService
    ) {
    }

    ngOnInit() {
        this.documentQRService.documentId$
            .subscribe((documentId) => {
                this.documentId = documentId;
            });
        this.domain = location.origin;
    }

}
