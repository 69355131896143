import {ProcessInterface} from '../interfaces/lot/process.interface';

export class ProcessModel {
  id: string;
  name: string;
  description: string;


  constructor(process: ProcessInterface | any) {
    this.id = process.id || '';
    this.name = process.name || '';
    this.description = process.description || '';
  }

}



