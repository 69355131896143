import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../core/services/wallet.service';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-wallet-info',
  templateUrl: './wallet-info.component.html',
  styleUrls: ['./wallet-info.component.css']
})
export class WalletInfoComponent implements OnInit {

  wallet: any;
  decodePassword: string;

  constructor(
      private authService: AuthenticationService,
      private walletService: WalletService
  ) {
  }

  ngOnInit() {
    this.wallet = this.walletService.wallet;
    this.decodePassword = this.authService.decodePass;
  }
}
