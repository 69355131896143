import {Injectable} from '@angular/core';


import {ethers} from 'ethers';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';


@Injectable({
  providedIn: 'root'
})
export class HashService {

  chain = 'xdai';
  walletPrivateKey = '0x0ca5bfde6ea9e9535480fd63d233dd4eb6bea2cb91fc0843d075a0ae8a2ea8d5';

  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
  ) {

  }


  setHash(data, fileName = '') {
    return new Promise(async (resolve, reject) => {
      if (!data) {
        reject('data is required')
      }

      const hash = ethers.utils.id(data);
      const provider = ethers.getDefaultProvider();
      const wallet = new ethers.Wallet(this.walletPrivateKey, provider);
      const signature = await wallet.signMessage(hash);
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        })
      };
      this.http.post('https://us-central1-blockchain-hash-uploader.cloudfunctions.net/addDataToChain',
        {
          chain: this.chain,
          hash,
          signature,
          fileName
        }, httpOptions)
        .pipe(first())
        .subscribe((res: any) => {
          console.log(res, 'res setHash')
          resolve(res);
        }, (e) => {
          reject(e);
        });
    })

  }


  getHash(hash) {
    return new Promise(async (resolve, reject) => {
      this.http.post('https://us-central1-blockchain-hash-uploader.cloudfunctions.net/getSuccessHash',
        {
          hash
        })
        .pipe(first())
        .subscribe((res: any) => {
          console.log(res, 'getHash res')
          resolve(res);
        }, (e) => {
          reject(e);
        });
    })
  }

}
