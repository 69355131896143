import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NewWalletComponent} from './new-wallet/new-wallet.component';
import {ImportWalletComponent} from './import-wallet/import-wallet.component';
import {ImportPrivateKeyComponent} from './import-private-key/import-private-key.component';
import {ImportMnemonicComponent} from './import-mnemonic/import-mnemonic.component';
declare var $: any;
declare var particlesJS: any;
declare var pJSDom: any;
declare var cancelRequestAnimFrame: any;
@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit, OnDestroy {
    loadPartice: boolean;

    constructor() {
    }

    @ViewChild(NewWalletComponent)
    public newWallet: NewWalletComponent;

    @ViewChild(ImportWalletComponent)
    public importWallet: ImportWalletComponent;

    @ViewChild(ImportPrivateKeyComponent)
    public importPrivateKey: ImportPrivateKeyComponent;

    @ViewChild(ImportMnemonicComponent)
    public importMnemonic: ImportMnemonicComponent;

    ngOnInit() {
        $('.particles-js').each(function(e) {
            $(this).attr('id', 'particle-' + e);
            particlesJS.load('particle-' + e, 'assets/vendors/particle/particles.json');
        });
    }

    ngOnDestroy(): void {
        if (pJSDom.length > 0) {
            for (const particles of pJSDom.length) {
                particles.pJS.fn.vendors.destroypJS();
            }
            pJSDom = [];
        }
    }


}
