import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import { Label} from 'ng2-charts';
import {ChartsService} from '../../../../core/services/charts.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-transactions-chart',
    templateUrl: './transactions-chart.component.html',
    styleUrls: ['./transactions-chart.component.css']
})
export class TransactionsChartComponent implements OnInit {
    public barChartOptions: ChartOptions;
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: ChartDataSets[];

    constructor(
        public chartsService: ChartsService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.loadChart();
        this.chartsService.interval$.subscribe(() => {
            this.loadChart();
        });
    }

    loadChart() {

        this.translate.get('menu.transactions').subscribe((transactions: any) => {
            this.barChartData = [
                {data: [], label: transactions}
            ];
        });

        this.chartsService.transactionsQty.subscribe((transactionQtys) => {
            const data = [];
            const labels = [];

            for (const transactionQty of transactionQtys) {
                labels.push(transactionQty.date);
                data.push(transactionQty.value);
            }

            this.barChartOptions = {
                responsive: true,
                scales: {
                    xAxes: [{}], yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            };

            this.barChartData[0].data = data;
            this.barChartLabels = labels;
        });
    }

}
