import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductInterface} from '../../../../core/interfaces/lot/product.interface';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.css']
})
export class ProductModalComponent implements OnInit {
  @Input() product: ProductInterface;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
    console.log(this.product, 'product')

  }

  ngOnInit(): void {

    console.log(this.product, 'this product')
    console.log(this.product.additionalInformation, 'this.product.additionalInformation')
    console.log(typeof this.product.additionalInformation, 'typeof this.product.additionalInformation')
    console.log(!this.product?.additionalInformation.length, '!this.product?.additionalInformation')
    if (!this.product?.additionalInformation.length) {
      console.log(this.product?.additionalFields?.sections, 'this.product?.additionalFields?.sections')
      for (let i = 0; i < this.product?.additionalFields?.sections.length; i++) {
        const section = this.product?.additionalFields?.sections[i];
        this.product.additionalInformation[i] = {};
        console.log(section.fields, 'section.fields');
        for (const field of section.fields) {
          console.log(section.fields, 'section.fields');
          this.product.additionalInformation[i][field.fieldName] = field.defaultValue;
        }
      }
    }
  }

  save() {
    console.log('this.product', this.product)
    this.activeModal.close(this.product);
  }

}
