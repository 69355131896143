import {Component, OnInit, ApplicationRef, ChangeDetectorRef} from '@angular/core';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {WalletService} from '../../../../core/services/wallet.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-import-private-key',
    templateUrl: './import-private-key.component.html',
    styleUrls: ['./import-private-key.component.css']
})
export class ImportPrivateKeyComponent implements OnInit {
    privateKey: string;
    privateKeyErr: boolean;
    password: string;
    repeatPassword: string;

    passwordErr: boolean;
    repeatPasswordErr: boolean;
    passwordErrText: string;

    constructor(
        private authService: AuthenticationService,
        private walletService: WalletService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        // setInterval(() => {
        //     this.ref.detectChanges();
        // }, 1000);
    }

    reset(): void {
        this.privateKey = '';
        this.privateKeyErr = false;

    }

    login() {
        if (this.verifyPassword()) {
            this.privateKeyErr = false;
            const wallet = this.walletService.importWalletFromPrivateKey(this.privateKey, this.password);
            if (wallet) {
                $('#import-private-key-modal').modal('hide');
                this.authService.login();
            } else {
                this.privateKeyErr = true;
            }
        }
    }

    verifyPassword(): boolean {
        this.passwordErr = false;
        this.repeatPasswordErr = false;

        if (this.password !== this.repeatPassword) {
            this.translate.get('signUp.errorRepeatPassword').subscribe((errorRepeatPassword: any) => {
                this.passwordErrText = errorRepeatPassword;
                this.passwordErr = true;
                this.repeatPasswordErr = true;
            });

            return false;
        }
        if (!this.password) {
            this.translate.get('signUp.errorEmptyPassword').subscribe((errorEmptyPassword: any) => {
                this.passwordErrText = errorEmptyPassword;
                this.passwordErr = true;
            });
            return false;
        }
        this.passwordErrText = '';
        return true;
    }


}
