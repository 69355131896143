import {Component, OnInit} from '@angular/core';
import {DocumentFormService} from '../../../../../core/services/documents/document-form.service';

@Component({
    selector: 'app-farm-section',
    templateUrl: './farm-section.component.html',
    styleUrls: ['./farm-section.component.css']
})
export class FarmSectionComponent implements OnInit {

    constructor(
        public formService: DocumentFormService
    ) {
    }

    ngOnInit() {
    }

    sign() {
        this.formService.sign('FARM');
    }
}
