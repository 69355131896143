import {getVal} from '../../core/utils/getNestedObject';
import {LotInterface} from '../interfaces/lot/lot.interface';
import {ProcessInterface} from '../interfaces/lot/process.interface';
import {DocumentInterface} from '../interfaces/document/document';
import {ProductInterface} from '../interfaces/lot/product.interface';
import {RawMaterialInterface} from '../interfaces/lot/raw-material.interface';
import {LotIntervalInterface} from '../interfaces/lot/lot-interval.interface';
import {ExternalLinkInterface} from '../interfaces/external-link.interface';
import {CustomerInterface} from '../interfaces/identity/customer.interface';
import {CustomerModel} from './identity/customer.model';
import {LotTemplateInterface} from '../interfaces/lot/lot-template.interface';


export class LotModel {
  id: string;
  externalId: string;
  process: ProcessInterface;
  date: number;
  lots: LotInterface[];
  intervals: LotIntervalInterface[];
  rawMaterials: RawMaterialInterface[];
  documents: DocumentInterface[];
  externalLinks: ExternalLinkInterface[];
  product: ProductInterface;
  customerIdentity: CustomerInterface;
  quantity: number;
  weight: number;
  weightUnit: string;
  grossWeight: number;
  grossWeightUnit: string;
  expiration: number;
  additionalInformation: any;
  blockchain: any;
  lotTemplate: LotTemplateInterface;

  constructor(lot: LotInterface | any) {
    this.id = lot.id || '';
    this.externalId = lot.externalId || '';
    this.process = lot.process;
    this.date = lot.date || +new Date();
    this.rawMaterials = lot.rawMaterials || [];
    this.lots = lot.lots || [];
    this.documents = lot.documents || [];
    this.intervals = lot.intervals || [];
    this.externalLinks = lot.externalLinks || [];
    this.product = lot.product;
    this.lotTemplate = lot.lotTemplate;
    this.customerIdentity = new CustomerModel(lot.customerIdentity);
    this.quantity = lot.quantity || 0;
    this.weight = lot.weight || 0;
    this.weightUnit = lot.weightUnit || 'kg';
    this.grossWeight = lot.grossWeight || 0;
    this.grossWeightUnit = lot.grossWeightUnit || 'kg';
    this.expiration = lot.expiration;
    this.additionalInformation = lot.additionalInformation || {};
    this.blockchain = lot.blockchain || {};
  }

}



