import {Component, OnInit} from '@angular/core';
import {IdentitiesService} from '../../../core/services/identities/identities.service';
import {IdentityFormService} from '../../../core/services/identities/identity-form.service';


@Component({
    selector: 'app-identities',
    templateUrl: './identities.component.html',
    styleUrls: ['./identities.component.css']
})
export class IdentitiesComponent implements OnInit {

    constructor(
        private identitiesService: IdentitiesService,
        public formService: IdentityFormService
    ) {
    }

    ngOnInit() {
        this.identitiesService.renderTable();
    }


}
