import {Component} from '@angular/core';
import {AclService} from './core/services/acl.service';
import {TranslationService} from './core/services/translation.service';
import {locale as enLang} from './core/lang/en';
import {locale as esLang} from './core/lang/es';
import {locale as catLang} from './core/lang/cat';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Porkchain';

    constructor(
        public translationService: TranslationService,
        public aclService: AclService
    ) {
        this.translationService.loadTranslations(enLang, catLang, esLang);
        this.translationService.setLanguage(this.translationService.getSelectedLanguage());
    }
}
