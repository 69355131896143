import {Component, OnInit} from '@angular/core';
import {WalletService} from '../../../core/services/wallet.service';
import {TransactionsService} from '../../../core/services/transactions.service';
import {utils} from 'ethers';
import {BaseChartDirective} from 'ng2-charts';
import {ChartsService} from '../../../core/services/charts.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-charts',
    templateUrl: './charts.component.html',
    styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {
    ethPrice: number;
    gasPrice: number;
    blockNumber: number;
    documentsLeft: number;
    serverBalance: number;

    constructor(
        public walletService: WalletService,
        public transactionsService: TransactionsService,
        public chartsService: ChartsService,
        private http: HttpClient
    ) {
    }

    ngOnInit() {
        this.chartsService.interval$.next('daily');
        this.walletService.mainnetProvider.getEtherPrice().then((price: any) => {
            this.ethPrice = price;
        });
        this.walletService.provider.getGasPrice().then((gasPrice: any) => {
            this.gasPrice = gasPrice.toNumber() / 1000000000;
        });
        this.walletService.provider.getBlock().then((block: any) => {
            this.blockNumber = block.number;
        });
        this.http.get('https://us-central1-porkchain-3cb1e.cloudfunctions.net/getWallet', {responseType: 'text'}).subscribe((walletAddress: string) => {
            this.transactionsService.transactionInfo
                .subscribe(transactionInfo => {
                    this.walletService.provider.getBalance(walletAddress).then(balance => {
                        this.serverBalance = Number(utils.formatEther(balance));
                        const documentSignaturesPrice = ((transactionInfo.transactionGas * transactionInfo.gasPriceGwei) / 1000000000) * 4;
                        this.documentsLeft = Math.trunc(this.serverBalance / documentSignaturesPrice);
                    });
                });
        });

        BaseChartDirective.registerPlugin({
            beforeUpdate: (chart: any) => {
                const yAxes = chart.options.scales.yAxes;
                for (const axis of yAxes) {
                    const datasets = chart.data.datasets;
                    const datasetsOnAxis = [];
                    let max = Number.MIN_VALUE;

                    for (let j = 0; j < datasets.length; j++) { // add datasets for this axes to datasetsOnAxis
                        const dataset = datasets[j];
                        const meta = chart.getDatasetMeta(j);
                        if (meta.yAxisID === axis.id) {
                            datasetsOnAxis.push(dataset);
                        }
                    }

                    for (const dataset of datasetsOnAxis) {
                        const newMax = Math.max.apply(null, dataset.data);
                        max = newMax > max ? newMax : max;
                    }
                    axis.ticks.max = max + max * 0.1;
                }
            }
        });
    }


    changeInterval(interval: string) {
        this.chartsService.interval$.next(interval);
    }

}
