import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {NgxPermissionsService} from 'ngx-permissions';
import {IdentityService} from '../../../core/services/identity.service';
import {TranslationService} from '../../../core/services/translation.service';
import {QrImporterService} from '../../../core/services/qr-importer.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    identity: any;
    wallet: any;

    constructor(
        private walletService: AuthenticationService,
        private identityService: IdentityService,
        public permissionsService: NgxPermissionsService,
        private translationService: TranslationService,
        db: AngularFireDatabase
    ) {
    }

    ngOnInit() {
        this.wallet = this.walletService.wallet;
        this.identityService.identity.subscribe((identity) => {
            this.identity = identity;
        });
    }

}
