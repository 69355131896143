import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    public config: Observable<any>;

    constructor(
        private db: AngularFireDatabase,
        private translate: TranslateService
    ) {
        this.config = db.list('parameters')
            .snapshotChanges().pipe(
                map((parameters: any) => {
                    return parameters.reduce((total, current) => {
                        const value = current.payload.val();
                        const key = current.payload.key;
                        const alowedParams = ['blockchain', 'documentPrice', 'gasPriceGwei'];
                        if (alowedParams.includes(key)) {
                            total[key] = value;
                        }
                        return total;
                    }, {});
                })
            );
    }


    saveConfig(config: object) {
        this.db.object('parameters').update(config);
        this.translate.get([
            'form.saveTitle',
            'form.saveMessage'
        ]).subscribe((trans: any) => {
            Swal.fire({
                icon: 'success',
                title: trans['form.saveTitle'],
                text: trans['form.saveMessage']
            });
        });
    }
}
