import {getVal} from '../../utils/getNestedObject';
import {CustomerInterface} from '../../interfaces/identity/customer.interface';

export class CustomerModel {
    id: string;
    fiscalName: string;
    commercialName: string;
    email: string;
    mobile: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
    country: string;
    token: string;
    type: 'CUSTOMER';
    walletAddress: string;
    privateKey: string;
    delegatedGas: boolean;
    active: boolean;

    constructor(
        customer?: CustomerInterface
    ) {
        this.id = getVal(customer, 'id') || '';
        this.fiscalName = getVal(customer, 'fiscalName') || '';
        this.fiscalName = getVal(customer, 'fiscalName') || '';
        this.commercialName = getVal(customer, 'commercialName') || '';
        this.email = getVal(customer, 'email') || '';
        this.mobile = getVal(customer, 'mobile') || '';
        this.phone = getVal(customer, 'phone') || '';
        this.address = getVal(customer, 'address') || '';
        this.city = getVal(customer, 'city') || '';
        this.state = getVal(customer, 'state') || '';
        this.province = getVal(customer, 'province') || '';
        this.postalCode = getVal(customer, 'postalCode') || '';
        this.country = getVal(customer, 'country') || '';
        this.token = getVal(customer, 'token') || '';
        this.type = getVal(customer, 'type') || '';
        this.walletAddress = getVal(customer, 'walletAddress') || '';
        this.privateKey = getVal(customer, 'privateKey') || '';
        this.delegatedGas = getVal(customer, 'delegatedGas') || true;
        this.active = getVal(customer, 'active') || true;
    }
}
