import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SignupComponent} from './views/pages/signup/signup.component';
import {HomeComponent} from './views/pages/home/home.component';
import {NewWalletComponent} from './views/pages/signup/new-wallet/new-wallet.component';
import {ImportWalletComponent} from './views/pages/signup/import-wallet/import-wallet.component';
import {FormsModule} from '@angular/forms';
import {QRCodeModule} from 'angularx-qrcode';
import {ShowHideInputTextComponent} from './views/partials/show-hide-input-text/show-hide-input-text.component';
import {DownloadKeystoreComponent} from './views/partials/download-keystore/download-keystore.component';
import {SpinnerButtonComponent} from './views/partials/spinner-button/spinner-button.component';
import {ImportPrivateKeyComponent} from './views/pages/signup/import-private-key/import-private-key.component';
import {ImportMnemonicComponent} from './views/pages/signup/import-mnemonic/import-mnemonic.component';
import {AclService} from './core/services/acl.service';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SelectAllDirective} from './core/directives/select-all.directive';
import {LoginComponent} from './views/pages/login/login.component';
import {PagesComponent} from './views/pages/pages.component';
import {DocumentsComponent} from './views/pages/documents/documents.component';
import {WalletComponent} from './views/pages/wallet/wallet.component';
import {IdentityComponent} from './views/pages/identity/identity.component';
import {HeaderComponent} from './views/layout/header/header.component';
import {FooterComponent} from './views/layout/footer/footer.component';
import {WalletInfoComponent} from './views/partials/wallet-info/wallet-info.component';
import {RequestComponent} from './views/pages/request/request.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {MinutesToTimePipe} from './core/pipes/minutes-to-time.pipe';
import {PrintDocumentComponent} from './views/pages/print-document/print-document.component';
import {TransactionsComponent} from './views/pages/transactions/transactions.component';
import {MinifyTextPipe} from './core/pipes/minify-text.pipe';
import {PendingTransactionsComponent} from './views/pages/transactions/pending-transactions/pending-transactions.component';
import {SuccessTransactionsComponent} from './views/pages/transactions/success-transactions/success-transactions.component';
import {DocumentFormComponent} from './views/pages/documents/document-form/document-form.component';
import {VetSectionComponent} from './views/pages/documents/document-form/vet-section/vet-section.component';
import {FarmSectionComponent} from './views/pages/documents/document-form/farm-section/farm-section.component';
import {SlaughterhouseSectionComponent} from './views/pages/documents/document-form/slaughterhouse-section/slaughterhouse-section.component';
import {LogisticSectionComponent} from './views/pages/documents/document-form/logistic-section/logistic-section.component';
import {NgTempusdominusBootstrapModule} from 'ngx-tempusdominus-bootstrap';
import {ClipboardModule} from 'ngx-clipboard';
import {IdentitiesComponent} from './views/pages/identities/identities.component';
import {IdentityFormComponent} from './views/pages/identities/identity-form/identity-form.component';
import {PendingIdentitiesComponent} from './views/pages/pending-identities/pending-identities.component';
import {PendingIdentityFormComponent} from './views/pages/pending-identities/pending-identity-form/pending-identity-form.component';
import {ProviderPendingFarmsComponent} from './views/pages/provider-pending-farms/provider-pending-farms.component';
import {ProviderFarmsComponent} from './views/pages/provider-farms/provider-farms.component';
import {ProvidersRequestsComponent} from './views/pages/identity/providers-requests/providers-requests.component';
import {LogisticDriversComponent} from './views/pages/logistic-drivers/logistic-drivers.component';
import {LogisticPendingDriversComponent} from './views/pages/logistic-pending-drivers/logistic-pending-drivers.component';
import {LogisticsRequestsComponent} from './views/pages/identity/logistics-requests/logistics-requests.component';
import {HttpClientModule} from '@angular/common/http';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {ChartsComponent} from './views/pages/charts/charts.component';
import {ChartsModule} from 'ng2-charts';
import {DocumentsChartComponent} from './views/pages/charts/documents-chart/documents-chart.component';
import {TransactionsChartComponent} from './views/pages/charts/transactions-chart/transactions-chart.component';
import {EarningsChartComponent} from './views/pages/charts/earnings-chart/earnings-chart.component';
import {TransactionsDurationChartComponent} from './views/pages/charts/transactions-duration-chart/transactions-duration-chart.component';
import {ConfigComponent} from './views/pages/config/config.component';
import {TranslateModule} from '@ngx-translate/core';
import {FarmFormComponent} from './views/pages/identity/farm-form/farm-form.component';
import {LogisticFormComponent} from './views/pages/identity/logistic-form/logistic-form.component';
import {SlaughterhouseFormComponent} from './views/pages/identity/slaughterhouse-form/slaughterhouse-form.component';
import {VetFormComponent} from './views/pages/identity/vet-form/vet-form.component';
import {DriverFormComponent} from './views/pages/identity/driver-form/driver-form.component';
import {ProviderFormComponent} from './views/pages/identity/provider-form/provider-form.component';
import {DocumentQrComponent} from './views/pages/documents/document-qr/document-qr.component';
import {DocumentComponent} from './views/pages/document/document.component';
import {VerifySignatureComponent} from './views/pages/verify-signature/verify-signature.component';
import {QrImporterComponent} from './views/partials/qr-importer/qr-importer.component';
import {QrExporterComponent} from './views/partials/qr-exporter/qr-exporter.component';
import {AdminFormComponent} from './views/pages/identity/admin-form/admin-form.component';
import {SignatureBoxComponent} from './views/pages/documents/document-form/signature-box/signature-box.component';
import {DocumentsHistoricComponent} from './views/pages/documents-historic/documents-historic.component';
import {ModifyAdminFormComponent} from './views/pages/identities/identity-form/modify-admin-form/modify-admin-form.component';
import {ModifyFarmFormComponent} from './views/pages/identities/identity-form/modify-farm-form/modify-farm-form.component';
import {ModifyVetFormComponent} from './views/pages/identities/identity-form/modify-vet-form/modify-vet-form.component';
import {ModifySlaughterhouseFormComponent} from './views/pages/identities/identity-form/modify-slaughterhouse-form/modify-slaughterhouse-form.component';
import {ModifyDriverFormComponent} from './views/pages/identities/identity-form/modify-driver-form/modify-driver-form.component';
import {ModifyLogisticFormComponent} from './views/pages/identities/identity-form/modify-logistic-form/modify-logistic-form.component';
import {ModifyProviderFormComponent} from './views/pages/identities/identity-form/modify-provider-form/modify-provider-form.component';
import {ChainsComponent} from './views/pages/chains/chains.component';
import {ChainFormComponent} from './views/pages/chains/chain-form/chain-form.component';
import {LotsComponent} from './views/pages/lots/lots.component';
import {LotFormComponent} from './views/pages/lots/lot-form/lot-form.component';
import {ProcessesComponent} from './views/pages/processes/processes.component';
import {ProcessFormComponent} from './views/pages/processes/process-form/process-form.component';
import {ProductsComponent} from './views/pages/products/products.component';
import {ProductFormComponent} from './views/pages/products/product-form/product-form.component';
import {RawMaterialProductsComponent} from './views/pages/raw-material-products/raw-material-products.component';
import {RawMaterialProductFormComponent} from './views/pages/raw-material-products/raw-material-product-form/raw-material-product-form.component';
import {RawMaterialsComponent} from './views/pages/raw-materials/raw-materials.component';
import {RawMaterialFormComponent} from './views/pages/raw-materials/raw-material-form/raw-material-form.component';
import {DocumentModalComponent} from './views/pages/lots/document-modal/document-modal.component';
import {LotModalComponent} from './views/pages/lots/lot-modal/lot-modal.component';
import {RawMaterialModalComponent} from './views/pages/lots/raw-material-modal/raw-material-modal.component';
import {NgbAccordionModule, NgbNavModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {PrintLabelsComponent} from './views/pages/print-labels/print-labels.component';
import {ExternalLinkModalComponent} from './views/pages/external-link-modal/external-link-modal.component';
import {FieldModalComponent} from './views/partials/field-modal/field-modal.component';
import {ProductModalComponent} from './views/pages/lots/product-modal/product-modal.component';
import {LotsTemplatesComponent} from './views/pages/lots-templates/lots-templates.component';
import {LotTemplateFormComponent} from './views/pages/lots-templates/lot-template-form/lot-template-form.component';
import {FileUploaderInputComponent} from './views/partials/file-uploader-input/file-uploader-input.component';
import {FileManagerModalComponent} from './views/partials/file-uploader-input/file-manager-modal/file-manager-modal.component';
import {DragDropUploaderModalComponent} from './views/partials/drag-drop-uploader-modal/drag-drop-uploader-modal.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {FileSelectInputComponent} from './views/partials/file-select-input/file-select-input.component';


const config = {
  apiKey: 'AIzaSyDuD7jTH9cLrKzpumt-DjZwHatUA-xEFR4',
  authDomain: 'porkchain-3cb1e.firebaseapp.com',
  databaseURL: 'https://porkchain-3cb1e.firebaseio.com',
  projectId: 'porkchain-3cb1e',
  storageBucket: 'porkchain-3cb1e.appspot.com',
  messagingSenderId: '1023905114547',
  appId: '1:1023905114547:web:65393703ff653642'
};

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    HomeComponent,
    NewWalletComponent,
    ImportWalletComponent,
    ShowHideInputTextComponent,
    FileSelectInputComponent,
    FileUploaderInputComponent,
    DownloadKeystoreComponent,
    SpinnerButtonComponent,
    ImportPrivateKeyComponent,
    ImportMnemonicComponent,
    SelectAllDirective,
    LoginComponent,
    PagesComponent,
    DocumentsComponent,
    WalletComponent,
    IdentityComponent,
    HeaderComponent,
    FooterComponent,
    WalletInfoComponent,
    RequestComponent,
    MinutesToTimePipe,
    PrintDocumentComponent,
    TransactionsComponent,
    MinifyTextPipe,
    PendingTransactionsComponent,
    SuccessTransactionsComponent,
    DocumentFormComponent,
    VetSectionComponent,
    FarmSectionComponent,
    SlaughterhouseSectionComponent,
    LogisticSectionComponent,
    IdentitiesComponent,
    IdentityFormComponent,
    PendingIdentitiesComponent,
    PendingIdentityFormComponent,
    ProviderPendingFarmsComponent,
    ProviderFarmsComponent,
    ProvidersRequestsComponent,
    LogisticDriversComponent,
    LogisticPendingDriversComponent,
    LogisticsRequestsComponent,
    ChartsComponent,
    DocumentsChartComponent,
    TransactionsChartComponent,
    EarningsChartComponent,
    TransactionsDurationChartComponent,
    ConfigComponent,
    FarmFormComponent,
    LogisticFormComponent,
    SlaughterhouseFormComponent,
    VetFormComponent,
    DriverFormComponent,
    ProviderFormComponent,
    DocumentQrComponent,
    DocumentComponent,
    VerifySignatureComponent,
    QrImporterComponent,
    QrExporterComponent,
    AdminFormComponent,
    SignatureBoxComponent,
    DocumentsHistoricComponent,
    ModifyAdminFormComponent,
    ModifyFarmFormComponent,
    ModifyVetFormComponent,
    ModifySlaughterhouseFormComponent,
    ModifyDriverFormComponent,
    ModifyLogisticFormComponent,
    ModifyProviderFormComponent,
    ChainsComponent,
    ChainFormComponent,
    LotsComponent,
    LotFormComponent,
    RawMaterialsComponent,
    RawMaterialFormComponent,
    ProcessesComponent,
    ProcessFormComponent,
    ProductsComponent,
    LotsTemplatesComponent,
    LotTemplateFormComponent,
    ProductFormComponent,
    RawMaterialProductsComponent,
    RawMaterialProductFormComponent,
    DragDropUploaderModalComponent,
    DocumentModalComponent,
    FileManagerModalComponent,
    LotModalComponent,
    RawMaterialModalComponent,
    PrintLabelsComponent,
    ExternalLinkModalComponent,
    FieldModalComponent,
    ProductModalComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    QRCodeModule,
    ClipboardModule,
    TranslateModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    NgTempusdominusBootstrapModule,
    HttpClientModule,
    ChartsModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgxFileDropModule
  ],
  providers: [
    AclService,
    MinutesToTimePipe,
    MinifyTextPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
