import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SignupComponent} from './views/pages/signup/signup.component';
import {HomeComponent} from './views/pages/home/home.component';
import {LoginComponent} from './views/pages/login/login.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {PagesComponent} from './views/pages/pages.component';
import {DocumentsComponent} from './views/pages/documents/documents.component';
import {WalletComponent} from './views/pages/wallet/wallet.component';
import {IdentityComponent} from './views/pages/identity/identity.component';
import {RequestComponent} from './views/pages/request/request.component';
import {PrintDocumentComponent} from "./views/pages/print-document/print-document.component";
import {TransactionsComponent} from './views/pages/transactions/transactions.component';
import {IdentitiesComponent} from './views/pages/identities/identities.component';
import {PendingIdentitiesComponent} from './views/pages/pending-identities/pending-identities.component';
import {ProviderPendingFarmsComponent} from './views/pages/provider-pending-farms/provider-pending-farms.component';
import {ProviderFarmsComponent} from './views/pages/provider-farms/provider-farms.component';
import {LogisticDriversComponent} from './views/pages/logistic-drivers/logistic-drivers.component';
import {LogisticPendingDriversComponent} from './views/pages/logistic-pending-drivers/logistic-pending-drivers.component';
import {ChartsComponent} from './views/pages/charts/charts.component';
import {ConfigComponent} from './views/pages/config/config.component';
import {DocumentComponent} from './views/pages/document/document.component';
import {VerifySignatureComponent} from './views/pages/verify-signature/verify-signature.component';
import {DocumentsHistoricComponent} from './views/pages/documents-historic/documents-historic.component';
import {ChainsComponent} from './views/pages/chains/chains.component';
import {LotsComponent} from './views/pages/lots/lots.component';
import {ProcessesComponent} from './views/pages/processes/processes.component';
import {ProductsComponent} from './views/pages/products/products.component';
import {RawMaterialProductsComponent} from './views/pages/raw-material-products/raw-material-products.component';
import {RawMaterialsComponent} from './views/pages/raw-materials/raw-materials.component';
import {PrintLabelsComponent} from './views/pages/print-labels/print-labels.component';
import {LotsTemplatesComponent} from './views/pages/lots-templates/lots-templates.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'FARM', 'DRIVER', 'PROVIDER', 'VET', 'LOGISTIC', 'SLAUGHTERHOUSE', 'USER'],
        except: ['GUEST'],
        redirectTo: '/signup'
      }
    },
    component: PagesComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'identity',
        component: IdentityComponent
      },
      {
        path: 'identities',
        component: IdentitiesComponent
      },
      {
        path: 'pending-identities',
        component: PendingIdentitiesComponent
      },
      {
        path: 'provider-farms',
        component: ProviderFarmsComponent
      },
      {
        path: 'provider-pending-farms',
        component: ProviderPendingFarmsComponent
      },
      {
        path: 'logistic-drivers',
        component: LogisticDriversComponent
      },
      {
        path: 'logistic-pending-drivers',
        component: LogisticPendingDriversComponent
      },
      {
        path: 'documents',
        component: DocumentsComponent
      },
      {
        path: 'lots',
        component: LotsComponent
      },
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'lots-templates',
        component: LotsTemplatesComponent
      },
      {
        path: 'raw-materials',
        component: RawMaterialsComponent
      },
      {
        path: 'raw-material-products',
        component: RawMaterialProductsComponent
      },
      {
        path: 'processes',
        component: ProcessesComponent
      },
      {
        path: 'documents-historic',
        component: DocumentsHistoricComponent
      },
      {
        path: 'transactions',
        component: TransactionsComponent
      },
      {
        path: 'wallet',
        component: WalletComponent
      },
      {
        path: 'charts',
        component: ChartsComponent
      },
      {
        path: 'config',
        component: ConfigComponent
      },
      {
        path: 'chains',
        component: ChainsComponent
      }
    ]
  },
  {
    path: 'print-labels/:start/:end',
    component: PrintLabelsComponent
  },
  {
    path: 'document/:documentId',
    component: DocumentComponent
  },
  {
    path: 'verify-signature/:documentId/:type',
    component: VerifySignatureComponent
  },
  {
    path: 'signup',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        except: ['GUEST'],
        redirectTo: '/login'
      }
    },
    component: SignupComponent
  },
  {
    path: 'login',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        except: ['GUEST'],
        redirectTo: '/signup'
      }
    },
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
