import { Component, OnInit } from '@angular/core';
import {AdminInterface} from '../../../../../core/interfaces/identity/admin.interface';
import {AdminModel} from '../../../../../core/models/identity/admin.model';
import {IdentityService} from '../../../../../core/services/identity.service';
import {ModifyIdentityFormService} from '../../../../../core/services/identities/modify-identity-form.service';

@Component({
  selector: 'app-modify-admin-form',
  templateUrl: './modify-admin-form.component.html',
  styleUrls: ['./modify-admin-form.component.css']
})
export class ModifyAdminFormComponent implements OnInit {

  constructor(
      public modifyIdentityFormService: ModifyIdentityFormService
  ) {
  }

  ngOnInit() {
    this.modifyIdentityFormService.identity = new AdminModel(this.modifyIdentityFormService.identity);
  }

}
