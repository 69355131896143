import {Component, OnInit} from '@angular/core';
import {LotsService} from '../../../core/services/lots/lots.service';
import {LotFormService} from '../../../core/services/lots/lot-form.service';
import {HashService} from '../../../core/services/hash.service';

@Component({
  selector: 'app-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.css']
})
export class LotsComponent implements OnInit {

  constructor(
    public lotsService: LotsService,
    public lotFormService: LotFormService,
    public hashService: HashService,
  ) {
  }

  ngOnInit() {
    this.lotsService.renderTable();
  }

  newLot() {
    this.lotFormService.newLot();
  }


}
