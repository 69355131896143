import {Component, OnInit, Input} from '@angular/core';
import {DocumentFormService} from '../../../../../core/services/documents/document-form.service';
import {SignatureInterface} from '../../../../../core/interfaces/document/signature.interface';

declare var $: any;

@Component({
    selector: 'app-signature-box',
    templateUrl: './signature-box.component.html',
    styleUrls: ['./signature-box.component.css']
})
export class SignatureBoxComponent implements OnInit {
    @Input() identity: string;
    @Input() signature: SignatureInterface;
    @Input() signatureDt: number;
    showSignature: boolean;

    constructor(
        public formService: DocumentFormService
    ) {
    }

    ngOnInit() {

    }

    showHideSignature() {
        if (this.showSignature) {
            this.showSignature = false;
            $('#' + this.signature.signature).collapse('hide');
        } else {
            this.showSignature = true;
            $('#' + this.signature.signature).collapse('show');
        }

    }

    sign() {
        this.formService.sign(this.identity);
    }

    validateSign() {
        this.formService.validateSign(this.identity);
    }

    validateSignHash() {
        this.formService.validateSignHash(this.identity);
    }
}
