import {getVal} from '../../utils/getNestedObject';
import {FarmInterface} from '../../interfaces/identity/farm.interface';

export class FarmModel {
    id: string;
    fiscalName: string;
    commercialName: string;
    holderId: string;
    holderName: string;
    mobilityPass: string;
    QS: string;
    email: string;
    mobile: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
    country: string;
    MOCode: string;
    REGACode: string;
    provider: {
        id: string;
        walletAddress: string;
        fiscalName: string;
    };
    token: string;
    type: 'FARM';
    walletAddress: string;
    delegatedGas: boolean;
    active: boolean;

    constructor(
        farm?: FarmInterface
    ) {
        this.id = getVal(farm, 'id') || '';
        this.fiscalName = getVal(farm, 'fiscalName') || '';
        this.commercialName = getVal(farm, 'commercialName') || '';
        this.holderId = getVal(farm, 'holderId') || '';
        this.holderName = getVal(farm, 'holderName') || '';
        this.mobilityPass = getVal(farm, 'mobilityPass') || '';
        this.QS = getVal(farm, 'QS') || '';
        this.email = getVal(farm, 'email') || '';
        this.mobile = getVal(farm, 'mobile') || '';
        this.phone = getVal(farm, 'phone') || '';
        this.address = getVal(farm, 'address') || '';
        this.city = getVal(farm, 'city') || '';
        this.state = getVal(farm, 'state') || '';
        this.province = getVal(farm, 'province') || '';
        this.postalCode = getVal(farm, 'postalCode') || '';
        this.country = getVal(farm, 'country') || '';
        this.MOCode = getVal(farm, 'MOCode') || '';
        this.REGACode = getVal(farm, 'REGACode') || '';
        this.provider = {
            id: getVal(farm, 'provider.id') || '',
            fiscalName: getVal(farm, 'provider.fiscalName') || '',
            walletAddress: getVal(farm, 'provider.walletAddress') || ''
        };
        this.token = getVal(farm, 'token') || '';
        this.type = getVal(farm, 'type') || '';
        this.walletAddress = getVal(farm, 'walletAddress') || '';
        this.delegatedGas = getVal(farm, 'delegatedGas') || true;
        this.active = getVal(farm, 'active') || true;
    }
}
