import { Component, OnInit } from '@angular/core';
import {DriverInterface} from '../../../../core/interfaces/identity/driver.interface';
import {IdentityService} from '../../../../core/services/identity.service';
import {DriverModel} from '../../../../core/models/identity/driver.model';

@Component({
  selector: 'app-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.css']
})
export class DriverFormComponent implements OnInit {
  driver: DriverInterface = new DriverModel();

  constructor(
      private identityService: IdentityService
  ) {
  }

  ngOnInit() {
    this.identityService.identity.subscribe((driver: DriverInterface) => {
      this.driver = new DriverModel(driver);
    });
  }

  saveIdentity() {
    this.identityService.saveIdentity(this.driver);
  }

  delLogistic() {
    this.identityService.delLogistic(this.driver.logistic.walletAddress);
  }

}
