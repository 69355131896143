import { Component, OnInit } from '@angular/core';
import {ProviderFarmsService} from '../../../core/services/providers/provider-farms.service';

@Component({
  selector: 'app-provider-farms',
  templateUrl: './provider-farms.component.html',
  styleUrls: ['./provider-farms.component.css']
})
export class ProviderFarmsComponent implements OnInit {

  constructor(
      public providerFarmsService: ProviderFarmsService
  ) { }

  ngOnInit() {
    this.providerFarmsService.renderTable();
  }

}
