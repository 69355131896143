import { Component, OnInit } from '@angular/core';
import {PendingIdentityFormService} from '../../../../core/services/identities/pending-identity-form.service';
import {ChainsService} from '../../../../core/services/chains/chains.service';
import {ChainModel} from '../../../../core/models/chain.model';

@Component({
  selector: 'app-chain-form',
  templateUrl: './chain-form.component.html',
  styleUrls: ['./chain-form.component.css']
})
export class ChainFormComponent implements OnInit {

  constructor(
      public chainsServices: ChainsService
  ) {

  }

  ngOnInit() {
  }

  save() {
    this.chainsServices.saveChain();
  }

}
