import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {map} from 'rxjs/operators';
import {getVal} from '../../utils/getNestedObject';
import Swal from 'sweetalert2';
import {PendingIdentityFormService} from './pending-identity-form.service';
import {DatatableLangService} from '../datatable-lang.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;


@Injectable({
    providedIn: 'root'
})
export class PendingIdentitiesService {
    dataTable: any;
    public pendingIdentities: any;
    public pendingIdentityList: any;

    constructor(
        private db: AngularFireDatabase,
        public pendingIdentityFormService: PendingIdentityFormService,
        private datatableLang: DatatableLangService,
        private translate: TranslateService
    ) {

        this.pendingIdentityList = db.list('pendingIdentities')
            .snapshotChanges()
            .pipe(
                map(pendingTransaction => {
                    return pendingTransaction.reduce((total, current, i) => {
                        total[i] = current.payload.val();
                        total[i].key = current.key;
                        return total;
                    }, []);
                })
            );
    }

    renderTable() {
        this.pendingIdentityList.subscribe(pendingIdentities => {
            const table: any = $('#pending-identities-table');
            this.dataTable = table.DataTable({
                destroy: true,
                scrollX: true,
                language: this.datatableLang.lang,
                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                order: [[1, 'desc']],
                data: pendingIdentities,
                columns: [
                    {data: 'key', width: '50px'},
                    {data: 'type', defaultContent: ''},
                    {data: 'id', defaultContent: ''},
                    {data: 'fiscalName', defaultContent: ''}
                ],
                columnDefs: [
                    {
                        targets: 0,
                        data: 'key',
                        title: 'Actions',
                        orderable: false,
                        render: (key) => {
                            // language=HTML
                            return `
                            <button pending-identity-id="${key}" type="button" class="del-pending-identity-btn btn btn-danger px-3 mr-2">
                                <i class="fas fa-times"></i>
                            </button>
                            <button pending-identity-id="${key}" type="button" class="edit-pending-identity-btn btn btn-primary mr-2"
                                data-toggle="modal" data-target="#new-pending-identity-modal">
                                <i class="fas fa-edit"></i>
                            </button>
                            `;
                        },
                    }
                ],
                drawCallback: () => {
                    $('[data-toggle="tooltip"]').tooltip();
                }
            });
        });

        $(document).on('click', '.edit-pending-identity-btn', (e) => {
            const key = $(e.currentTarget).attr('pending-identity-id');
            this.pendingIdentityFormService.loadPendingIdentity(key);
        });

        $(document).on('click', '.del-pending-identity-btn', (e) => {
            const key = $(e.currentTarget).attr('pending-identity-id');
            this.translate.get([
                'generic.yes',
                'generic.no',
                'identity.deletePendingIdentity.warning.title',
                'identity.deletePendingIdentity.warning.text',
                'identity..deleted.title',
                'identity.deletePendingIdentity.deleted.text',
            ]).subscribe((trans: any) => {
            Swal.fire({
                title: trans['identity.deletePendingIdentity.warning.title'],
                text: trans['identity.deletePendingIdentity.warning.text'],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: trans['generic.yes'],
                cancelButtonText: trans['generic.no']
            }).then((result) => {
                if (result.value) {
                    this.db.object('pendingIdentities/' + key).remove()
                        .then(() => {
                            Swal.fire(
                                trans['identity.deletePendingIdentity.deleted.title'],
                                trans['identity.deletePendingIdentity.deleted.text'],
                                'success'
                            );
                        });
                }
            });
            });
        });

    }
}
